import React from 'react';

export const Button = (props) => {
	const {children, onClick} = props;
	return (
		<button
			className={`btn fill blue round`}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
