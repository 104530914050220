import getCompanyImageUrl from './getCompanyImageUrl'

export default async (avatarId) => {
	const url = getCompanyImageUrl(avatarId)
	const response = await fetch(url, { cache: 'no-store' });
	const buffer = await response.arrayBuffer();

	let binary = '';
	const bytes = [].slice.call(new Uint8Array(buffer));
	bytes.forEach(b => binary += String.fromCharCode(b));
	const base64 = window.btoa(binary);

	return `data:image/jpeg;base64,${base64}`;
}
