import request from "../shared/request";
import axios from "axios";
const mime = require('mime-types')
export default class AttachmentsService {
	static downloadResource(resource_path, resource_id) {
		let encodedPath = encodeURIComponent(resource_path);
		return request({
			url: `/attachments/download/${encodedPath}`,
			method: 'GET',
			params: {
				resource_id,
			}
		})
	}
}
