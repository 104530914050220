import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom'
import Modal from "react-modal";
import EditProfileModal from "../Modals/EditProfileModal";
import {connect} from "react-redux";

class SubNavigation extends Component {


	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head">
							<h1 className="page-title">My Profile</h1>
							<div className="btn-wrap page-controls">
								<button className="btn fill blue round" onClick={() => this.props.toggleEditProfileModal(true)}>Edit Profile</button>
							</div>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.props.editProfileModalIsOpen}
					onRequestClose={()=>{this.props.toggleEditProfileModal(false);}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<EditProfileModal user={this.props.user} closeModal={(data) => this.props.toggleEditProfileModal(false, data)}/>
				</Modal>

			</Fragment>
		);
	}
}

export default withRouter(SubNavigation);
