import React, { Component, Fragment } from 'react';
import NewPeopleForm from "../../forms/NewPeopleForm";
import CompaniesService from "../../services/api/companies";

export default class NewPeopleModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: []
		}
	}
	componentDidMount() {
		CompaniesService.listCompanies()
			.then(res => {
				this.setState({
					companies: res.data.map(company => {return {value: company.id, label: company.name}})
				});
			});
	}

	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container">
						<button onClick={()=>{this.props.closeModal()}} className="e-modal__close"/>
						<h2 className="page-section-title">Invite new person</h2>
						<NewPeopleForm onSuccess={() => {this.props.closeModal()}} companies={this.state.companies}/>
					</div>
				</div>
			</Fragment>
		)
	}
}
