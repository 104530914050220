import React, {useState} from 'react';
import useForm from 'react-hook-form'
import {Input} from '../inputs/Input';
import {NavLink} from 'react-router-dom';
import {ToggleField} from "../inputs/ToggleField";
import Cookies from 'universal-cookie';
import AuthService from "../services/api/auth";
import {required, isEmailAddress} from "../inputs/validations";

export default function LoginForm(props) {
	const [disabled, setDisabled] = useState(false);
	const {register, handleSubmit,errors, setError, formState} = useForm({
		mode: "onSubmit"
	});


	const onSubmit = data => {
		setDisabled(!disabled)
		AuthService.login(data)
			.then(response => {
				setDisabled(false)
				if (response && response.token) {
					const cookies = new Cookies();
					cookies.set('token', response.token, {
						path: '/',
						expires: data.user_remember_me ? null : new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 7)
					})

					props.dispatch({
						type: "@@huskytracker/USER_MEMBERSHIPS",
						payload: response.memberships
					});

					props.dispatch({
						type: "@@huskytracker/USER_AUTHENTICATED",
						payload: !!cookies.get("token")
					});
				}
			})
			.catch(err => {
				console.log(err);
				setDisabled(false)
				setError("email", true, "")
				setError("password", true, err.data && err.data.message)
			})
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="wrapper credentials">
				<div className="f-section">

					<h2 className="f-section__title no-border">Hi there,</h2>

					<p className="f-info">
						Welcome to HuskyTracker. Please enter your details below and let's begin:
					</p>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Email"} disabled={disabled} type="text"
								   register={
									   register({
										   validate: {isEmailAddress, required},
									   })
								   }
								   error={errors.email}
								   name="email"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Password"} disabled={disabled} type="password"
								   error={errors.password}
								   register={
									   register({
										   validate: required
									   })
								   }
								   name="password"
							/>
						</div>
					</div>

					<div className="f-row is-regular">
						<div className="f-element is-half">
							<ToggleField
								register={register}
								name="user_remember_me"
								id="user_remember_me"
							/>
							<label htmlFor="user_remember_me">Remember me</label>
						</div>
						<div className="f-element is-half">
							<NavLink className="f-link" to={"/auth/recovery"}>Forgot your password?</NavLink>
						</div>
					</div>

					<div className="f-row btn-container">
						<button
							// disabled={!formState.isValid || disabled} 
							type="submit" 
							className="btn fill blue"
						>
							Sign in
						</button>
					</div>

				</div>
			</div>
		</form>

	)
}
