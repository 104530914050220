import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/stylesheets/main.css';
import {register} from './serviceWorker';
import {initSentry} from './config';

initSentry();

ReactDOM.render(
	<App/>,
	document.getElementById('root')
);
register();
