import React, {useEffect, useState} from 'react'
import useForm from 'react-hook-form'
import {Input} from '../inputs/Input';
import {HtmlInput} from '../inputs/HtmlInput';
import {required,requiredArray} from "../inputs/validations";
import {DueDate} from "../inputs/DueDate";
import moment from "moment";
import {CustomStatusSelectInput} from "../inputs/CustomStatusSelectInput";
import SelectUsers from "../components/SelectUsers";
import TicketsService from "../services/api/tickets";
import UsersService from "../services/api/users";
import _ from 'lodash';
import UploadFile from "../components/UploadFile";
import Select from "react-select";
import {Prompt} from "react-router";


export default function NewTicketForm(props) {
	const { register, handleSubmit, errors, setValue, formState } =  useForm({
		mode: "onChange"
	});

	const [inputs, setInputs] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [data, setData] = useState({users: [], isFetching: true});
	const [project, setProject] = useState({});

	const onSubmit = values => {
		setIsSubmitting(true);
		// string list to array
		values.followers = values.followers.length > 0 ? values.followers.split(",") : [];
		values.project_id = props.project.id || project.value;
		values.attachments = attachments;
		if (values.due_date) {
			values.due_date = moment(values.due_date).utc().format()
		}

		TicketsService.createTicket(values)
			.then(res => {
				setIsSubmitting(false);
				props.closeModal(`./tickets/${res.id}`);
			})
			.catch(err => {
				console.log(err);
				setIsSubmitting(false);
			})

	};

	const handleInputChange = (event, name) => {
		setInputs(inputs => ({...inputs, [name]: event}));
		setValue(name, event, true);
	};


	const removeFromAttachments = (resource) => {
		setAttachments(attachments.filter(r => (r.file_file_name !== resource.file_file_name)));
	};

	useEffect(() => {
		UsersService.getAllUsers({limit: -1, filters:{is_archived: false}})
		.then(res => {
			setData({
				users: res.data.map(user=>{
					return {
						...user,
						...{
							label: user.first_name + ' ' + user.last_name,
							value: user.id,
						}
					}}), isFetching: false});
		})
		.catch (err => {
			console.log(err);
			setData({isFetching: false});
		});
	}, [setData]);

	//usePreventOnchangeLeave(formState)
	return (

		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={'f-row'}>
				<div className={'f-element'}>
					<Input type="text"
						   register={register({validate: required})}
						   placeholder={'Ticket name'}
						   error={errors.title}
						   name="title"/>
				</div>
			</div>

		{props.showSelectProject && <div className={'f-row'}>
				<div className={'f-element'}>
					<Select
						options={props.selectProjects}
						value={project}
						onChange={(event) => {
							setProject(event);
							props.getProjectFollowers(event.slug)
						}}
					/>
				</div>
			</div>
			}

			<div className={'f-row'}>
				<div className={'f-element'}>
					<HtmlInput register={register({validate: required})}
							   name={"description"}
							   value={inputs.description}
							   error={errors.description}
							   initialValue={null}
							   onChange={(event)=> handleInputChange(event, 'description')}/>
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element'}>
					<UploadFile
						onFileAdded={(resource) => setAttachments(attachments.concat([resource]))}
						onFileRemoved={(resource) =>  removeFromAttachments(resource)}
					/>
				</div>
			</div>

			<div className="f-row">
				{props.statuses && <div className="f-element is-full">
					<div className={`ticket-meta ${errors.status_id ? 'is-error' : 'is-valid'}`}>
						<div className="ticket-meta-cell ticket-meta-name">Status</div>
						<div className="ticket-meta-cell ticket-meta-element">
							 <CustomStatusSelectInput register={register({validate: required})}
													  name={'status_id'}
													  value={inputs.status}
													  options={props.statuses}
													  onChange={(event)=> handleInputChange(event, 'status')}/>
						</div>
						{errors.status_id && <p className={'is-full is-error'}>{errors.status_id.message}</p>}
					</div>
				</div>}
				<div className="f-element is-full">
					<div className={`ticket-meta`}>
						<div className="ticket-meta-cell ticket-meta-name">Assignee</div>
						<div className="ticket-meta-cell ticket-meta-element">
							<input name={"assignee_id"} type={'hidden'} ref={register}  />
							{!data.isFetching && <SelectUsers users={data.users}
															  isMultiSelect={false}
															  showLabel={true}
															  selected={inputs.assignee_id}
															  onChange={(data) => {handleInputChange(data, 'assignee_id')}}/>}
						</div>
					</div>
				</div>
				<div className="f-element is-full">
					<div className="ticket-meta">
						<div className="ticket-meta-cell ticket-meta-name">Due Date</div>
						<div className="ticket-meta-cell ticket-meta-element">
							<DueDate minDate={new Date()}
									 name={'due_date'}
									 value={inputs.due_date}
									 onChange={(event)=> handleInputChange(event, 'due_date')} register={register}/>
						</div>
					</div>
				</div>
				<div className="f-element is-full">
					<div className={`ticket-meta`}>
						<div className="ticket-meta-cell ticket-meta-name">Followers</div>
						<div className="ticket-meta-cell ticket-meta-element">
							<input name={"followers"} type={'hidden'} ref={register} />
							{!data.isFetching && <SelectUsers users={data.users}
															  isMultiSelect={true}
															  selected={inputs.followers}
															  onChange={(data) => { handleInputChange(data, 'followers')}}/>}
						</div>
					</div>
				</div>
			</div>

			{errors.submitForm && <div class="f-errors"><span>{errors.submitForm.message}</span></div>}

			<div className="f-row btn-container">
				<button className="btn outline gainsboro" type={'button'} onClick={() => {props.closeModal()}}>Close</button>
				<button className={`btn fill blue is-animated ${ isSubmitting ? 'active' : '' }`} type={'submit'}>
					{isSubmitting ?
						<span className="animate-loading"><span className="circle" /></span>
						:
						<span class="btn-label">Create Ticket</span>
					}
				</button>
			</div>

		</form>
	)
}
