import React, { Component, useState } from 'react'
import {NavLink} from 'react-router-dom'
import _ from 'lodash'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import Taxonomies from '../../../services/api/taxonomies'

function AddPosition({ onSave }) {
	const [name, setName] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = () => {
		if (_.isEmpty(name)) {
			setError('Type name for new position.')
		} else {
			setName('')
			onSave({ name })
		}
	}
	return (
		<div className='settings-list__container' style={{ marginBottom: '40px' }}>
			<div className='settings-list__item status-item form'>
				<div className='create-status__cell alpha'>
					<div className='f-element'>
						<input
							type='text'
							className={`input ${error ? 'is-error' : ''}`}
							placeholder='Create position...'
							onChange={(e) => setName(e.target.value) }
							value={name}
						/>
					</div>
				</div>
				<div className='settings-list__cell gamma'>
					<button className='btn round fill blue' onClick={handleSubmit}>
						Save
					</button>
				</div>
			</div>
		</div>
	)
}


const DragHandle = SortableHandle(() =>
	<div className='far-icon sort-handle cursor-row-resize'>
		<i class="far  fa-bars"></i>
	</div>
)

const Position = SortableElement(({ details, onDelete }) => {

	return (
		<div className='settings-list__item position-item'>
			<div className='settings-list__cell delta'>
				<DragHandle />
			</div>
			<div className='settings-list__cell alpha'>
				<div className='status-style' style={{ '--color': details.color }}>
					{ details.name }
				</div>
			</div>
			<div className='settings-list__cell beta'></div>
			<div className='settings-list__cell gamma'>
				{['primary', 'secondary'].indexOf(details.scope) === -1 &&
					<a
						href='#delete'
						className='btn outline gray-blue'
						onClick={(e) => onDelete(e, details) }
					>
						Delete
					</a>
				}
			</div>
		</div>
	)
})

const PositionList = SortableContainer(({ items, onDelete }) => {
	return (
		<div className='settings-list__container'>
			{items.map((item, index) => (
				<Position key={item.id} index={index} details={item} onDelete={onDelete} />
			))}
		</div>
	)
})

export default class Positions extends Component {

	state = {
		positions: [],
		confirmBoxOpen: false,
		forDelete: {},
		affectedPeople: [],
		newStatusId: null
	};

	componentDidMount() {
		this.getPositions();
	}

	toggleConfirm = () => {
		this.setState({
			confirmBoxOpen: !this.state.confirmBoxOpen
		})
	}

	getPositions = () => {
		this.props.handleRoute();
		Taxonomies.get('positions').then(response => {
			this.setState({
				positions: response
			})
		}).catch((error) => {
			// @TODO: Show error notification
		})
	}

	persistPosition = (data) => {

		Taxonomies.create('positions', data).then((response) => {
			this.getPositions()
		})
	}

	persistOrder = () => {
		const { positions } = this.state
		const ordered = positions.map((item, index) => ({ id: item.id, order: index }))

		Taxonomies.updateOrder('positions', ordered).then(response => {
			// @TODO: show success notification
		}).catch(error => {
			// @TODO: show error notification
		})
	}

	onSorted = ({oldIndex, newIndex}) => {
		this.setState(({positions}) => ({
			positions: arrayMove(positions, oldIndex, newIndex),
		}), () => {
			this.persistOrder()
		})
	}

	deletePosition = (e, details) => {

		Taxonomies.delete('positions', details.id).then(response => {
			// No affected tickets, no box with details
			if(response.affected_people.length === 0) {
				if(window.confirm(`Remove '${details.name}' position?`))
					return this.confirmDelete(details);
				return ;
			}

			window.scroll({
				top: 0,
				behavior: 'smooth'
			})

			this.setState({
				forDelete: details,
				confirmBoxOpen: true,
				affectedPeople: response.affected_people
			})
		})
	}

	confirmDelete = (details) => {
		return Taxonomies.confirmDelete('positions', details.id).then(() => {
			this.getPositions()
			this.setState({
				forDelete: {},
				confirmBoxOpen: false,
				affectedPeople: []
			})
		})
	}


	render() {
		const { positions, confirmBoxOpen, forDelete, affectedPeople } = this.state

		return (
			<section className='page'>
				<div className='wrapper'>
					{confirmBoxOpen &&
						<div className='box'>
							<h4 className='text-center'>
								Tickets which have&nbsp;
								<span className='status-style' style={{ '--color': forDelete.color }}>
									{ forDelete.name }
								</span>
								&nbsp;as status:
								<span className='text-right'>
									<button href='#close' onClick={this.toggleConfirm} className='btn circle outline icon close' />
								</span>
							</h4>

							<ul>
								{affectedPeople.map(item =>
									<li>
										<NavLink to={`/people/${item.user_id}`} target='_blank'>
											{item.user.first_name} ({item.user.last_name})
										</NavLink>
									</li>
								)}
							</ul>
							<br/>

							<button className='btn fill blue round' onClick={() => this.confirmDelete(forDelete)}>
								Confirm
							</button>
						</div>
					}

					<h3 className='page-section-title'>
						Positions ({positions.length})
					</h3>

					{this.props.addPosition && <AddPosition onSave={this.persistPosition} />}

					<PositionList
						items={positions}
						onSortEnd={this.onSorted}
						onDelete={this.deletePosition}
						useDragHandle
						lockAxis='y'
					/>
				</div>
			</section>
		)
	}

}
