import React, {Component, useState} from 'react';
import useForm from "react-hook-form";
import {Input} from "../../inputs/Input";
import {isEmailAddress, required} from "../../inputs/validations";
import {ToggleField} from "../../inputs/ToggleField";
import {NavLink} from "react-router-dom";
import AuthService from "../../services/api/auth";
import Cookies from "universal-cookie";
import {connect} from "react-redux";


function InvalidTokenForm() {
	return (
		<div className="wrapper credentials">
			<div className="f-section">

				<h2 className="f-section__title no-border">Hi there,</h2>

				<p className="f-info">
					It looks like your invite token is not valid.
				</p>
			</div>
		</div>
	)
}

function CreateProfileForm(props) {
	const [disabled, setDisabled] = useState(false);
	const {register, handleSubmit, errors, formState} = useForm({
		mode: "onChange"
	});

	const onSubmit = data => {
		delete data['confirm_password']
		AuthService.acceptInvite({...data, invitation_token: props.token}, props.membership)
			.then(response => {
				if (response && response.token) {
					const cookies = new Cookies();
					cookies.set('token', response.token, {
						path: '/',
						expires: new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 7)
					})

					props.dispatch({
						type: "@@huskytracker/USER_MEMBERSHIPS",
						payload: response.memberships
					});

					props.dispatch({
						type: "@@huskytracker/USER_AUTHENTICATED",
						payload: !!cookies.get("token")
					});
				}
			})
			.catch(err => {

			})
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="wrapper credentials">
				<div className="f-section">

					<h2 className="f-section__title no-border">Hi there,</h2>

					<p className="f-info">
						Welcome to HuskyTracker. Please enter your details below and let's begin:
					</p>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"First Name"} disabled={disabled} type="text"
								   register={
									   register({
										   validate: {required},
									   })
								   }
								   error={errors.first_name}
								   name="first_name"/>
						</div>
					</div>
					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Last Name"} disabled={disabled} type="text"
								   register={
									   register({
										   validate: {required},
									   })
								   }
								   error={errors.last_name}
								   name="last_name"/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Username"} disabled={disabled} type="text"
								   register={
									   register({
										   validate: {required},
									   })
								   }
								   error={errors.user_name}
								   name="user_name"/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Password"} disabled={disabled} type="password"
								   error={errors.password}
								   register={
									   register({
										   validate: required
									   })
								   }
								   name="password"/>
						</div>
					</div>
					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Confirm Password"} disabled={disabled} type="password"
								   error={errors.password_confirmation}
								   register={
									   register({
										   validate: required
									   })
								   }
								   name="password_confirmation"/>
						</div>
					</div>

					<div className="f-row btn-container">
						<button disabled={!formState.isValid || disabled} type="submit" className="btn fill blue">Continue</button>
					</div>

				</div>
			</div>
		</form>

	)

}
class CreateProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isConfirmed: false
		}
	}

	componentDidMount() {
		let {token, membership} = this.props.match.params;
		AuthService.confirmInvite({invitation_token: token, membership})
			.then(response => {
				this.setState({
					isConfirmed: true,
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					isConfirmed: false,
					loading: false
				})
			})
	}

	render() {
		let {isConfirmed, loading} = this.state;
		let {token, membership} = this.props.match.params;
		if (isConfirmed && !loading) return <CreateProfileForm dispatch={this.props.dispatch} token={token} membership={membership}/>
		if (!isConfirmed && !loading) return <InvalidTokenForm />
		return <div></div>
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	}
}


export default connect(null, mapDispatchToProps)(CreateProfile);
