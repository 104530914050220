import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import Modal from 'react-modal';
import NewProjectModal from '../Modals/NewProjectModal';
import {PermissibleRender} from '@brainhubeu/react-permissible';
import {connect} from 'react-redux';
import {Button, PageTitle} from '../../new-components';

class SubNavigation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showInfo: false,
			newProjectModalIsOpen: false
		};
	}

	componentDidMount() {
		Modal.setAppElement('body');
	}

	handleAddProject = () => this.setState({newProjectModalIsOpen: true});

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head">
							<PageTitle>
								My Projects
							</PageTitle>
							<div className="btn-wrap page-controls">
								<PermissibleRender
									userPermissions={this.props.userPermissions}
									requiredPermissions={['can_create_project']}
									oneperm
								>
									<Button onClick={this.handleAddProject}>
										Add Project
									</Button>
								</PermissibleRender>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={this.state.newProjectModalIsOpen}
					onRequestClose={() => {
						this.setState({newProjectModalIsOpen: false});
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<NewProjectModal closeModal={() => {
						this.setState({newProjectModalIsOpen: false});
					}}/>
				</Modal>

			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
