import React, { Fragment} from 'react';
export function Input ({ register, type, name, defaultValue, placeholder, error, disabled }) {

	return (
		<Fragment>
			<input className={`input ${(!disabled && error) ? 'is-error' : ''}`}
				   type={type}
				   ref={register}
				   disabled={disabled}
				   placeholder={placeholder}
				   name={name}
				   defaultValue={defaultValue}
				   id={name}/>
			{error && <p className={'is-error'}>{error.message}</p>}
		</Fragment>
	);
}
