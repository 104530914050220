import React, {useEffect, useState} from 'react';
import ProjectsService from '../../services/api/projects';
import {NavLink} from 'react-router-dom';
import Avatar from '../../components/Avatar';

export default function SearchProjects({query}) {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		ProjectsService.searchTickets({search: query}).then(res => {
			setProjects(res.projects);
		});
	}, [query]);

	return (
		<section className="page">
			<div className="wrapper">
				<div className="list-container">
					<div className="inner-container">
						<div className="morph-grid__container ctrl list-view">
							{projects.length ?
								<>
									{projects.map(project =>
										<div key={project.id}
											 className="morph-grid__item project-item">
											<div
												className="project-item__group alpha-beta-gamma">
												<div
													className="project-item__cell alpha">
													<NavLink
														to="/"
														className="project-item__company">
														{project.company.name}
													</NavLink>
												</div>
												<div
													className="project-item__cell beta">
													<NavLink
														to={`/projects/${project.readable_id}`}
														className="project-item__title">{project.title}</NavLink>
												</div>
												<div
													className="project-item__cell gamma">
													<div
														className="project-item__brief">
														{project.brief}
													</div>
												</div>
											</div>
											<div
												className="project-item__group epsilon-zeta">
												<div
													className="project-item__cell epsilon">
													<div
														className="project-item__owner">
														<NavLink to={`/people/${project.owner.id}/info`}>
															<Avatar
																width={32}
																height={32}
																altText={`${project.owner.first_name} ${project.owner.last_name}`}
																userId={project.owner.id}
																hasAvatar={project.owner.has_avatar}
															/>
														</NavLink>
													</div>
												</div>
												<div
													className="project-item__cell zeta">
													<ul className="project-item__ticket-list">
														{project.statuses.map(status =>
															<li
																key={status.id}
																className="ticket-list__ticket-item"
																style={{color: `${status.color}`}}
															>
																{status.count}
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
									)}
								</>
								:
								<div>
									There are no projects in this search
									criteria.
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
