import React, {Component, Fragment} from 'react';
import SubNavigation from "./SubNavigation";
import TicketsList from "../shared/TicketsList";

export default class Tickets extends Component {

	constructor(props) {
		super(props);
		this.state = {
			shouldRefresh: false
		}
	}

	refreshTicketsList() {
		this.setState({
			shouldRefresh: true
		}, () => {
			this.setState({
				shouldRefresh: false
			})
		})
	}
	render() {
		return (
			<Fragment>
				<SubNavigation onRefresh={() => this.refreshTicketsList()} title="My Tickets"/>
				<TicketsList isTicketsPage={true} isProjectList={false} refreshTickets={this.state.shouldRefresh} tickets={[]}/>
			</Fragment>
		);
	}
}




