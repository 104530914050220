import React, {Fragment} from 'react';
import CustomStatusSelect from "../components/CustomStatusSelect";

export function CustomStatusSelectInput ({ register,value, options, name, onChange, error}) {
	return (
		<Fragment>
			<input className={'input'}
				   type="hidden"
				   ref={register}
				   name={name}
				   value={value}
				   id={name} />
			<CustomStatusSelect options={options} error={error} value={value && options.filter(option => option.value === value)[0]} onChange={(event) => { onChange(event.value)}}/>
			{error && <p className={'is-error'}>{error.message}</p>}
		</Fragment>
	);
}
