import request from '../shared/request';

export default class AuthService {
	static login(data) {
		return request({
			url: '/auth/login',
			method: 'POST',
			data
		})
	}

	static issueResetPassword(data) {
		return request({
			url: '/auth/issue-reset-password',
			method: 'POST',
			data,
			headers: {
				'account-domain': data.membership
			}
		})
	}

	static resetPassword(data) {
		return request({
			url: '/auth/reset-password',
			method: 'POST',
			data,
			headers: {
				'account-domain': data.membership
			}
		})
	}
	static confirmInvite(data) {
		return request({
			url: '/auth/confirm-invite',
			method: 'POST',
			data,
			headers: {
				'account-domain': data.membership
			}
		})
	}

	static acceptInvite(data, membership) {
		return request({
			url: '/auth/accept-invite',
			method: 'POST',
			data,
			headers: {
				'account-domain': membership
			}
		})
	}
}
