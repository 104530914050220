import React from 'react';

export const ProjectViewButton = (props) => {
	const {onClick, active, icon} = props;
	return (
		<button
			className={`far-icon square link ${active ? 'is-active' : ''}`}
			onClick={onClick}
		>
			<i className={`far ${icon}`}/>
		</button>
	);
};
