import React, {Component, Fragment} from 'react';
import CustomSelect from '../../../components/CustomSelect';
import {NavLink} from "react-router-dom";
import SubNavigation from "./SubNavigation";
import ProjectItemPlaceholder from "../../../placeholders/ProjectItem";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {changeProjectsViewType} from "../../shared/actions";
import companies from "../../../services/api/companies";
import {ViewMenu} from '../../../new-components';

class ListProjects extends Component {

	constructor() {
		super();
		this.state = {
			viewType: "list-view",
			filters: {
				status: {label: "Open", value: 'active'},
				order_by: {label: "Order by title", value: null},
				order_type: {label: "Direction", value: 'asc'},
				group_by: {label: "Grouped", value: null}
			},
			projects: null,
			projectsGroup: {},
		}
	}

	componentDidMount() {
		this.getProjects(this.props.match.params.slug, {filters:this.state.filters});
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters
		}, () => this.getProjects(this.props.match.params.slug, {filters:this.state.filters}))
	}

	getProjects(id, params) {
		companies.getProjectCompanies(id, params).then((res)=> {
			if(this.state.filters.group_by.value){
				let object = this.groupProjects(res);
				this.setState({projectsGroup: object})
			}
			this.setState({
				projects: res,
			});
		})
	}

	groupProjects(data) {
		let projectsCategories = {};
		let projects = [...data];
		projects.forEach((project) => {
			let category;
			if(this.state.filters.group_by.value === 'owner') {
				category = project.owner.first_name + ' ' + project.owner.last_name || 'undefined';
			}
			else
				return; //to do others category
			if(!projectsCategories[category]) {
				projectsCategories[category] = [];
			}
			projectsCategories[category].push(project)
		});
		return projectsCategories
	}

	renderProject(project) {
		return (
			<div key={project.id} className="morph-grid__item project-item">
				<div className="project-item__group alpha-beta-gamma">
					<div className="project-item__cell alpha">
					</div>
					<div className="project-item__cell beta">
						<NavLink to={`/projects/${project.readable_id}`} className="project-item__title">{project.title}</NavLink>
					</div>
					<div className="project-item__cell gamma">
						<div className="project-item__brief">
							{project.brief}
						</div>
					</div>
				</div>
				<div className="project-item__group epsilon-zeta">
					<div className="project-item__cell epsilon">
						<div className="project-item__owner">
							<NavLink to="/">
								<img alt="owner name" src={'https://media.mnn.com/assets/images/2018/10/husky.jpg.653x0_q80_crop-smart.jpg'}/>
							</NavLink>
						</div>
					</div>
					<div className="project-item__cell zeta">
						<ul className="project-item__ticket-list">
							{project.statuses.map(status => {
								return (
									<li className="ticket-list__ticket-item" style={{color: `${status.color}`}}>{status.count}</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>
		);
	}
	handleChangeProjectsViewType = (type) =>
		() => {
			this.props.changeProjectsViewType(type);
		};
	render() {
		if (this.state.filters.group_by.value) {
			return (
				<Fragment>
					<div className="secondary-bottom">
						<div className="wrapper">
							<ul className="filter-container">
								<CustomSelect name="status"
											  className="filter"
											  value={this.state.filters.status}
											  onChange={this.onFilter.bind(this, 'status')}
											  options={[
												  {
													  label: "Open",
													  value: 'active'
												  },
												  {
													  label: "Archived",
													  value: 'archived'
												  },
											  ]}
								/>

								<CustomSelect name="order_by"
											  className="filter"
											  value={this.state.filters.order_by}
											  onChange={this.onFilter.bind(this, 'order_by')}
											  options={[
												  {
													  label: "Order by title",
													  value: null
												  },
												  {
													  label: "By date added",
													  value: 'created_at'
												  },
												  {
													  label: "By activity",
													  value: 'activity'
												  },
											  ]}
								/>

								<CustomSelect name="order_type"
											  className="filter"
											  value={this.state.filters.order_type}
											  onChange={this.onFilter.bind(this, 'order_type')}
											  options={[
												  {
													  label: "Direction",
													  value: null
												  },
												  {
													  label: "First to last",
													  value: 'asc'
												  },
												  {
													  label: "Last to first",
													  value: 'desc'
												  },
											  ]}
								/>

								<CustomSelect name="group_by"
											  className="filter"
											  value={this.state.filters.group_by}
											  onChange={this.onFilter.bind(this, 'group_by')}
											  options={[
												  {
													  label: "Grouped",
													  value: null
												  },
												  {
													  label: "By activity",
													  value: 'activity'
												  },
												  {
													  label: "By owner",
													  value: 'owner'
												  },
											  ]}
								/>

							</ul>
						</div>
					</div>
					<section className="page">
						<div className="wrapper">
							<ViewMenu
								view={this.props.preferences.projectsView}
								onChangeProjectViewType={this.handleChangeProjectsViewType}
							/>
							{
								Object
									.keys(this.state.projectsGroup)
									.map((category) => {
										return (
											<div className="list-container">
												<h3>{category}</h3>
												<div className="inner-container">
													<div
														className={`morph-grid__container ctrl ${this.props.preferences.projectsView}`}>
														{!this.state.projectsGroup[category] &&
														<ProjectItemPlaceholder
															items={this.props.preferences.projectsView === 'list-view' ? 5 : 6}/>}
														{this.state.projectsGroup[category] && this.state.projectsGroup[category].map(project => this.renderProject(project))}
													</div>
												</div>
											</div>

										)
									})
							}
						</div>
					</section>
				</Fragment>
			)

		} else {
			return (
				<Fragment>
					<div className="secondary-bottom">
						<div className="wrapper">
							<ul className="filter-container">
								<CustomSelect name="status"
											  className="filter"
											  value={this.state.filters.status}
											  onChange={this.onFilter.bind(this, 'status')}
											  options={[
												  {
													  label: "Open",
													  value: 'active'
												  },
												  {
													  label: "Archived",
													  value: 'archived'
												  },
											  ]}
								/>

								<CustomSelect name="order_by"
											  className="filter"
											  value={this.state.filters.order_by}
											  onChange={this.onFilter.bind(this, 'order_by')}
											  options={[
												  {
													  label: "Order by title",
													  value: null
												  },
												  {
													  label: "By date added",
													  value: 'created_at'
												  },
												  {
													  label: "By activity",
													  value: 'activity'
												  },
											  ]}
								/>

								<CustomSelect name="order_type"
											  className="filter"
											  value={this.state.filters.order_type}
											  onChange={this.onFilter.bind(this, 'order_type')}
											  options={[
												  {
													  label: "Direction",
													  value: null
												  },
												  {
													  label: "First to last",
													  value: 'asc'
												  },
												  {
													  label: "Last to first",
													  value: 'desc'
												  },
											  ]}
								/>

								<CustomSelect name="group_by"
											  className="filter"
											  value={this.state.filters.group_by}
											  onChange={this.onFilter.bind(this, 'group_by')}
											  options={[
												  {
													  label: "Grouped",
													  value: null
												  },
												  {
													  label: "By activity",
													  value: 'activity'
												  },
												  {
													  label: "By owner",
													  value: 'owner'
												  },
											  ]}
								/>

							</ul>
						</div>
					</div>
					<section className="page">
						<div className="wrapper">
							<ViewMenu
								view={this.props.preferences.projectsView}
								onChangeProjectViewType={this.handleChangeProjectsViewType}
							/>
							<div className="list-container">
								<div className="inner-container">
									<div
										className={`morph-grid__container ctrl ${this.props.preferences.projectsView}`}>
										{!this.state.projects &&
										<ProjectItemPlaceholder
											items={this.props.preferences.projectsView === 'list-view' ? 5 : 6}/>}
										{this.state.projects && this.state.projects.map(project => this.renderProject(project))}
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fragment>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		preferences: state.preferences
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({changeProjectsViewType}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProjects)
