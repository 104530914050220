import React,{useState,useEffect} from 'react';
import CustomStatusSelect from '../CustomStatusSelect';
import {NavLink,withRouter} from 'react-router-dom';
import DueDate from '../../components/DueDate';
import TicketsService from '../../services/api/tickets';
import TicketItemExpanded from './TicketItemExpanded';
import SelectUsers from '../SelectUsers';
import { useHistory } from 'react-router';
import Avatar from '../Avatar';
import moment from 'moment';



const TicketItemList2 = (props) => {
    const history = useHistory();

    // isProjectList={this.props.isProjectList}
	// key={this.state.filters.group_by.value === 'people' category : ticket.id}
	// statuses={this.state.statuses}
	// project={project}
	// onUpdate={() => this.refreshTickets()}
	// ticket={ticket}
	// screen={'Tickets'}
	// people={this.state.users}
    // key={ticket.id}
    // statuses={this.state.statuses}
    // project={ticket.project}
    // onUpdate={() => this.refreshTickets()}
    // ticket={ticket}

    const { isProjectList , statuses , project , onUpdate , ticket , screen , people } = props;

    const [ticketModalIsOpen,setTicketModalIsOpen] = useState(false);
    const [followers,setFollowers] = useState([]);

    useEffect(()=>{
        if(project){
            setFollowers(project.followers.map(follower => {
				return {
					value: follower.user.id,
					id: follower.user.id,
					label: follower.user.first_name + ' ' + follower.user.last_name,
					avatarUrl: '/avatar_0000.jpeg',
					has_avatar: follower.user.has_avatar
				};
			}));
        }
    },[project]);

    const updateTicket = (label, value) => {
		TicketsService.updateTicket(ticket.id, {[label]: value}, null)
		    .then(res => {
			    onUpdate();
	    	})
		    .catch(err => {
			    console.log(err);
		    });
	}

    const onModalClose = () => {
		if (isProjectList) {
			window.history.replaceState(null, null, `/projects/${project.readable_id}/tickets/`);
		} else if (screen === 'Tickets'){
			window.history.replaceState(null, null, `/tickets/`);
		} else {
			history.push(props.location.pathname);
		}
	}

    const handleDateChange = (date) => {
		this.setState({
			ticket: {...this.state.ticket, ...{due_date: date}}
		});
		TicketsService.updateTicket(this.state.ticket.id, {'due_date': date});
	}


    return (
        // screen === 'Tickets' ?
        <>
        <div key={ticket.id} className="flex flex-row items-center justify-between py-3 border-b" style={{borderColor:'#C3C7D980' , fontFamily:'SFUITextBold,sans-serif' , marginLeft:'30px' , marginRight:'30px'}}>
            <div className="flex flex-row justify-between md:justify-start items-center w-full md:w-1/2">
				<div className="flex w-1/2 md:w-32">
                    <CustomStatusSelect
						options={statuses}
						value={{
							label: ticket.status.name,
							value: ticket.status.id,
							bgColor: ticket.status.color
						}}
						onChange={(value) => {
							updateTicket('status_id', value.value);
					    }}
                    />
                </div>
                <div className="flex flex-row w-1/2 flex-1 ">
                    <div className="hidden md:block w-auto ml-6 mr-5">
                        <Avatar
						    className="logged-in__avatar"
						    width={36}
						    height={36}
						    altText={`${ticket?.assigneed?.first_name} ${ticket?.assigneed?.last_name}`}
						    userId={ticket?.assigneed?.id}
						    hasAvatar={ticket?.assigneed?.has_avatar}
						/>
                    </div>
                    <NavLink
						to={`/projects/${ticket?.project?.readable_id ?? project.readable_id}/tickets/${ticket.id}`}
						className="ticket-tiem__title text-center flex items-center flex-1 w-full md:w-2/4 "
						>
						<p className="truncate text-sm leading-6 text-right md:text-left w-full text-deep_koamaru" style={{letterSpacing:'-0.154px', fontFamily:'SFUITextMedium'}}>{ticket.title}</p>
					</NavLink>
                </div>
            </div>
            <div className="hidden md:flex flex-row items-center w-54">
                <div className="flex w-2/3 justify-center ">
                    <h2 className="truncate text-deep_koamaru text-sm leading-6" style={{letterSpacing:'-0.154px', fontFamily:'SFUITextMedium'}}>{ticket?.project?.title}</h2>
                </div>
                <div className=" w-36 justify-end flex ">
                    <div className="dropdown">
						<DueDate
                            styleBorder={true}
							value={ticket.due_date && new Date(ticket.due_date)}
							minDate={new Date()}
							onChange={(value) => {
								updateTicket('due_date', value);
							}}/>
					</div>
                </div>
                {/* } */}
            </div>
            {/* {ticketModalIsOpen &&
					<TicketItemExpanded
						isModal={true}
						ticket={ticket} {...this.props}
						project={this.props.project}
						closeModal={() => {
							onModalClose();
							setTicketModalIsOpen(false);
			}}/>} */}
        </div>
        </>
        // :
        // <>
        // <div key={ticket.id} style={{backgroundColor:'red'}}>
        //     <h1>is ticket</h1>
        // </div>
        // </>
    )
}

export default TicketItemList2
