export function userReducer(state = {}, action) {
	switch(action.type) {
		case '@@huskytracker/POPULATE_USER':
			return {
				...state,
				...action.payload
			};
		case '@@huskytracker/REMOVE_USER':
			return {

			}
		default:
			return state;
	}
}

export function preferencesReducer(state = {}, action) {
	switch (action.type) {
		case '@@huskytracker/SET_PROJECTS_VIEW':
			return {
				...state,
				projectsView: action.payload
			};
		case '@@huskytracker/SET_COMPANIES_VIEW':
			return {
				...state,
				companiesView: action.payload
			};
		case '@@huskytracker/SET_PEOPLES_VIEW':
			return {
				...state,
				peoplesView: action.payload
			};
		default:
			return state;
	}
}
