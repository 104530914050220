import React, {Component, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import List from "./List";
import Profile from "./PeopleProfile";
export default class People extends Component {
	render() {
		return (
			<Fragment>
				<Switch>
					<Route exact path={this.props.match.url} component={List}/>
					<Route path={`${this.props.match.url}/:id`} component={Profile}/>
				</Switch>
			</Fragment>
		);
	}
}




