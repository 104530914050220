import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {PermissibleRender} from '@brainhubeu/react-permissible';

class DropdownEditTicket extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownState: false

		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (e) => {
		if (!this.node.contains(e.target)) {
			this.setState({
				dropdownState: false
			});
		}
	};

	editTicket() {
		this.props.showEditTicket();
		this.setState({
			dropdownState: !this.state.dropdownState
		});
	}

	closeTicket() {
		this.setState({dropdownState: !this.state.dropdownState});
		this.props.closeTicket();
	}

	render() {
		const canEditOrClose = this.props.userPermissions.some((permission) => permission === 'can_edit_ticket' || permission === 'can_close_ticket');
		return (
			<Fragment>
				<div ref={node => this.node = node}
					 className="ticket-head__cell ticket__settings select-holder">
					{canEditOrClose && <div className="dropdown">
						<div role="button"
							 className="dropdown__trigger">
							<span className="pointer">
								<span
									className="far-icon circle outline gray hover"
									onClick={() => {
										this.setState({dropdownState: !this.state.dropdownState});
									}}><i className="far  fa-ellipsis-h"/>
								</span>
							</span>

						</div>
						<div
							className="dropdown__content small active top-center"
							style={{left: '-111px'}}>
							{!this.state.dropdownState ? null :
								<div className="dropdown-content">
									<div className="settings-dropdown">
										<div
											className="selectbox selectbox-active is-menu top-center-arrow top-right-arrow">
											<div
												className="selectbox__elements-container">
												<PermissibleRender
													userPermissions={this.props.userPermissions}
													requiredPermissions={['can_edit_ticket']}
													oneperm
												>
													<div
														className="selectbox__element dropdown-item">
														<div
															className="select__element-icon">
															<i className="zmdi zmdi-edit"/>
														</div>
														<div
															className="selectbox__element-designation"
															onClick={() => this.editTicket()}>
															<div
																className="select__element-designation-content dropdown-ticket-custom">
																<div
																	className="select__element-name">
																	Edit ticket
																</div>
															</div>
														</div>
													</div>
												</PermissibleRender>
												<PermissibleRender
													userPermissions={this.props.userPermissions}
													requiredPermissions={['can_close_ticket']}
													oneperm
												>
													<div
														className="selectbox__element dropdown-item">
														<div
															className="select__element-icon">
															<i className="zmdi zmdi-archive"/>
														</div>
														<div
															className="selectbox__element-designation">
															<div
																className="select__element-designation-content dropdown-ticket-custom">
																<div
																	className="select__element-name"
																	onClick={() => this.closeTicket()}>
																	Close ticket
																</div>
															</div>
														</div>
													</div>
												</PermissibleRender>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>}
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps, null)(DropdownEditTicket);
