import React, {Component, Fragment} from 'react';

export default class UnarchiveProjectModal extends Component {

	render() {
		return (
			<span wrapper-name="default">
				<div
					className="message-box dialog-mask">
					<div
						className="dialog-content">
						<div className="dialog-body">
							<p>Project will be unarchived but tickets will stay closed.</p>
						</div>
						<footer>
							<button onClick={()=>this.props.confirm()}
								className="btn outline blue">Confirm</button>
							<button onClick={()=>this.props.close()}
								className="btn outline gainsboro">Cancel</button>
						</footer>
					</div>
				</div>
			</span>
		)
	}

}
