import React, {Component, Fragment} from 'react';
import LoginForm from '../../forms/LoginForm';
import {connect} from "react-redux";

class Login extends Component {

	render() {
		return (
			<Fragment>
				<LoginForm dispatch={this.props.dispatch}/>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);
