import React, {Component, Fragment} from 'react';
import {NavLink} from "react-router-dom";

export default class AddNewProjectItem extends Component {

	render() {
		return (
			<div onClick={this.props.onClick} className="morph-grid__item project-item" style={{cursor: 'pointer'}}>
				<span style={{fontSize: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#8f95b3', height: '100%'}} className="material-icons">add</span>
			</div>
		);
	}
}
