import React, { Component, Fragment } from 'react'
import svgCompany from '../../assets/images/defaultcompany.svg'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import companies from '../../services/api/companies'
import UploadService from '../../services/api/upload'
import { v4 as uuidv4 } from 'uuid'
import getCompanyImage from '../../components/helpers/getCompanyImage'

export default class NewCompanyModal extends Component {
	constructor(props) {
		super(props);
		this.options = countryList().getData();

		this.state = {
			options: this.options,
			name: null,
			description: null,
			phone_number: "",
			location: {
				city: "",
				state: "",
				address: "",
				country: "",
			},
			legal_info: {
				name: "",
				vat_number: "",
				registration_number: "",
			},
			payment_info: {
				currency: "",
				bank_name: "",
				swift_code: "",
				account_number: "",
			},
			contact_info: {
				email: "",
				skype: "",
				website: "",
				last_name: "",
				first_name: "",
				phone_number: "",
			},
			avatar_id: null,
			avatarSrc: svgCompany,
			default_company: false,
			archived_at: null,
		}
	}

	onChangeLocation(name, event) {
		this.setState({
			location: {
				...this.state.location,
				[name]: event.target.value
			}
		})
	}

	onChangePaymentInfo(name, event) {
		this.setState({
			payment_info: {
				...this.state.payment_info,
				[name]: event.target.value
			}
		})
	}

	onChangeContactInfo(name, event) {
		this.setState({
			contact_info: {
				...this.state.contact_info,
				[name]: event.target.value
			}

		})
	}

	onChangeLegalInfo(name, event) {
		this.setState({
			legal_info: {
				...this.state.legal_info,
				[name]: event.target.value
			}

		})
	}

	onChangeInputDefault(name, event) {
		this.setState({
			[name]: event.target.value
		})
	}
	async handleFileInput(files) {
		const avatar_id = this.state.avatar_id || uuidv4()
		if(!files[0]) return
		try {
			await UploadService.signResourceAndUpload(
				'Company',
				avatar_id,
				files[0]
			)
			const avatarSrc = await getCompanyImage(avatar_id)
			this.setState({
				avatar_id,
				avatarSrc
			})
		} catch (e) {
			console.error(e)
		}
	}

	onSubmit() {
		const {
			name,
			description,
			contact_info,
			location,
			legal_info,
			payment_info,
			avatar_id
		} = this.state

		const data = {
			name,
			description,
			location,
			legal_info,
			payment_info,
			contact_info,
			avatar_id,
			phone_number: contact_info.phone_number
		}
		companies.postCompany(data).then((res) => this.props.closeModal(res)).catch(e => console.log(e))
	}

	render() {
		const {avatarSrc} = this.state
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container">
						<span onClick={() => this.props.closeModal()}
							  className="e-modal__close">
						</span>
						<div name="new-company">
							<div className="e-modal__head">
								<span
									className="deco size36 round icon edit">
								</span>
								<h2 className="e-modal__title">Add Company</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<div className="f-section">
										<div className="re-avatar-upload">
											<div
												className="f-row is-h-centered">
												<img
													src={avatarSrc}
													className="re-avatar-preview"
													onError={(ev) => ev.target.src = svgCompany}
												/>
											</div>

											<div
												className="f-row btn-container">
												<label
													htmlFor="companyAvatar"
													className="btn outline blue">Change
													photo

													<input type="file"
														   id="companyAvatar"
														   onChange={(event) => this.handleFileInput(event.target.files)}/>

												</label>
											</div>
										</div>
									</div>
									<div className="f-section">
										<h3 className="f-section__title">
											Company Information</h3>
										<div className="f-row">
											<div className="f-element">
												<label>Company name</label>
												<input type="text"
													   placeholder="Name"
													   name="name"
													   className=""
													   aria-required="true"
													   aria-invalid="false"
													   onChange={(event) => this.onChangeInputDefault('name', event)}

												/>
											</div>
										</div>
										<div className="f-row">
											<div className="f-element">
												<label>Description</label>
												<textarea name="description"
														  className=""
														  aria-required="true"
														  aria-invalid="false"
														  onChange={(event) => this.onChangeInputDefault('description', event)}/>

											</div>
										</div>
										<div className="f-row">
											<div className="f-element is-full">
												<label>Address</label>
												<textarea
													onChange={(event) => this.onChangeLocation('address', event)}
												>
												</textarea>
											</div>
											<div className="f-element is-third">
												<input placeholder="City"
													   type="text"
													   onChange={(event) => this.onChangeLocation('city', event)}
												/>
											</div>
											<div className="f-element is-third">
												<input placeholder="State"
													   type="text"
													   onChange={(event) => this.onChangeLocation('state', event)}
												/>
											</div>
											<div className="f-element is-third">
												<Select
													options={this.state.options}
													value={this.state.value}
													onChange={(event) =>
														this.setState({
															location: {
																...this.state.location,
																country: event.label
															},
														})
													}
												/>
											</div>
										</div>
										<div className="f-section">
											<h3 className="f-section__title">Contact
												Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>First name</label>
													<input
														placeholder="First Name"
														type="text"
														onChange={(event) => this.onChangeContactInfo('first_name', event)}/>

												</div>
												<div className="f-element">
													<label>Last name</label>
													<input
														placeholder="Last Name"
														type="text"
														onChange={(event) => this.onChangeContactInfo('last_name', event)}/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Email</label>
													<input
														placeholder="Email"
														type="text"
														onChange={(event) => this.onChangeContactInfo('email', event)}
													/>
												</div>
												<div className="f-element">
													<label>Website</label>
													<input
														placeholder="Website"
														type="text"
														onChange={(event) => this.onChangeContactInfo('website', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Phone Number</label>
													<input
														placeholder="Phone Number"
														type="text"
														onChange={(event) => this.onChangeContactInfo('phone_number', event)}
													/>
												</div>
												<div className="f-element">
													<label>Skype</label>
													<input
														placeholder="Skype"
														type="text"
														onChange={(event) => this.onChangeContactInfo('skype', event)}
													/>
												</div>
											</div>
										</div>
										<div className="f-section"><h3
											className="f-section__title">Legal
											Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>Company legal
														name</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangeLegalInfo('name', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>EIN/J/Registration
														Number</label>
													<input
														placeholder=""
														type="text/"
														onChange={(event) => this.onChangeLegalInfo('registration_number', event)}

													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>VAT number</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangeLegalInfo('vat_number', event)}

													/>
												</div>
											</div>
										</div>
										<div className="f-section"><h3
											className="f-section__title">Payment
											Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>IBAN/ABA Account
														Number</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangePaymentInfo('account_number', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Bank name</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangePaymentInfo('bank_name', event)}

													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>SWIFT Code</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangePaymentInfo('swift_code', event)}
													/>
												</div>
												<div className="f-element">
													<label>Currency</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangePaymentInfo('currency', event)}
													/>
												</div>
											</div>
										</div>
										<div className="f-section">
											<div
												className="f-row btn-container">
												<button
													className="btn fill blue"
													onClick={() => this.onSubmit()}
												>
													Create company
												</button>
												<button
													onClick={() => this.props.closeModal()}
													className="btn outline gainsboro">Close
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
