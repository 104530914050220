import React, {Component, Fragment} from 'react';
import HtmlEditor from '../HtmlEditor';
import UploadFile from '../UploadFile';
import TicketsService from '../../services/api/tickets';
import UsersService from '../../services/api/users';
import {connect} from 'react-redux';
import Comment from './Comment';
import moment from 'moment';
import Avatar from '../Avatar';
import {PermissibleRender} from '@brainhubeu/react-permissible';

const getCategoryFromDate = (date) => {
	return moment(date).format('MMMM D, YYYY');
};

class Comments extends Component {

	constructor(props) {
		super(props);
		this.uploadFile = React.createRef();
		this.meow = React.createRef();
		this.state = {
			comments: {},
			attachments: [],
			content: null,
			user: {}
		};
	}

	componentDidMount() {
		this.getComments();
		if (this.props.isAuthenticated) {
			this.getCurrentUser();
		}
	}

	getCurrentUser() {
		UsersService.getCurrentUser()
			.then(user => {
				this.setState({
					user: {
						id: user.id,
						first_name: user.first_name,
						last_name: user.last_name
					}
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	removeCommentAfterDelete = (comment) => {
		const category = getCategoryFromDate(comment.created_at);
		let array = [...this.state.comments[category]];
		let index = array.includes(comment);
		if (index) {
			array.splice(index, 1);
			this.setState({
				comments: {
					...this.state.comments,
					[category]: array
				}
			});
		}
	};

	getComments = () => {
		let commentsCategories = {};
		TicketsService.getComments({id: this.props.ticket_id})
			.then(res => {

				res.forEach((comment) => {
					const category = getCategoryFromDate(comment.created_at);
					if (!commentsCategories[category]) {
						commentsCategories[category] = [];
					}
					commentsCategories[category].push(comment);
				});
				this.setState({comments: commentsCategories});
			})
			.catch(err => {
				console.log(err);
			});
	};

	editComment(origComment, content, attachments) {
		const category = getCategoryFromDate(origComment.created_at);
		let array = [...this.state.comments[category]];
		const mappedState = array.map(obj => {
			let comment = {
				...obj
			};
			if (comment.id === origComment.id) {
				comment.meta = content || comment.meta;
			}
			return comment;
		});
		this.setState({
			comments: {
				...this.state.comments,
				[category]: mappedState
			}
		});
		TicketsService.editComment(origComment.id, {content: content})
			.then(res => {
				this.getComments();
			})
			.catch(err => {
				console.log(err);
			});
	}

	deleteComment(comment) {
		this.removeCommentAfterDelete(comment);
		TicketsService.deleteComment(comment.id);
	}

	postComment = () => {
		const category = getCategoryFromDate(moment());
		this.setState({
			comments: {
				...this.state.comments,
				[category]: [
					...(this.state.comments[category] || []),
					{
						meta: this.state.content,
						owner: {
							id: this.state.user.id,
							first_name: this.state.user.first_name,
							last_name: this.state.user.last_name
						},
						created_at: new Date().toString(),
						attachments: [...this.state.attachments]
					}
				]
			}
		});
		let mentions = this.meow.current.getMentions();

		if (this.meow.current) {
			this.meow.current.clearEditor();
		}
		if (this.uploadFile.current) {
			this.uploadFile.current.clearAttachments();
		}

		TicketsService.postComment(this.props.ticket_id, {
			content: this.state.content,
			attachments: this.state.attachments,
			mentions: mentions
		})
			.then(res => {
				this.setState({attachments: []});
				this.getComments();
			})
			.catch(err => {
				console.log(err);
			});
	};

	attachmentsList(attachments) {
		this.setState({
			attachments: this.state.attachments.concat(attachments)
		});
	}

	removeFromAttachments(id) {
		this.setState({
			attachments: this.state.attachments.filter(e => e.id !== id)
		});
	}

	removeCommentAfterDelete = (comment) => {
		let category = getCategoryFromDate(comment.created_at);
		let array = [...this.state.comments[category]];
		let index = array.indexOf(comment);
		if (index !== -1) {
			array.splice(index, 1);
			if (array.length > 0) {
				this.setState({
					comments: {
						...this.state.comments,
						[category]: array
					}
				});
			} else {
				delete this.state.comments[category];
				this.setState({
					...this.state.comments
				});

			}
		}
	};

	isEmpty = (obj) => {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	};

	render() {
		let DateNow = getCategoryFromDate(new Date());
		let empty = this.isEmpty(this.state.comments);
		return (
			<Fragment>
				<div className="custom-tab-container">
					<section id="comments" role="tabpanel"
							 className="custom-tab-content is-active">
						<div className="act__container">
							{empty ?
								<div>
									<p>There are no comments yet.</p>
								</div> : <div className="act__group">{
									Object
										.keys(this.state.comments)
										.map((category, index) => {
											return <div key={index}
														className="act__timeframe">
												<div className="act__date">
													{category === DateNow ? 'Today' : `${category}`}
												</div>
												{
													this.state.comments[category].map((comment, index) => {
														return (
															<Comment
																key={index}
																user_id={this.state.user.id}
																comment={comment}
																removeCommentAfterDelete={this.removeCommentAfterDelete.bind(this)}
																editComment={this.editComment.bind(this)}
																deleteComment={this.deleteComment.bind(this)}
															/>
														);
													})
												}
											</div>;
										})
								}</div>}

							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_add_comments']}
								oneperm
							>
								<div className="comment-form"
									 style={{width: '100%'}}>
									<div
										className="comment-form__author">
										<div className="profile-label">
											<div className="row-avatar">
												<Avatar
													className={'custom-single-user-select__avatar'}
													width={30}
													height={30}
													altText={`${this.props.user.first_name} ${this.props.user.first_name}`}
													userId={this.props.user.id}
													hasAvatar={this.props.user.has_avatar}
												/>
											</div>
										</div>
									</div>
									<div className="comment-form__body">
										<HtmlEditor
											ref={this.meow}
											onChange={(e) => this.setState({content: e})}/>

										<UploadFile
											ref={this.uploadFile}
											isComment={true}
											resource={'TicketComments'}
											resource_id={this.props.ticket_id}
											onFileAdded={(resource) => {
												this.setState({
													attachments: this.state.attachments.concat([resource])
												});
											}}
											onFileRemoved={(resource) => {
												this.setState({
													attachments: this.state.attachments.filter((entry) => {
														return entry.file_file_name !== resource.file_file_name;
													})
												});
											}}
										/>

										<div
											className="f-row is-h-centered f-cta-container">
											<button className="btn fill blue"
													onClick={this.postComment}>
												Post Comment
											</button>
										</div>

									</div>

								</div>
							</PermissibleRender>
						</div>
					</section>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		isAuthenticated: state.auth.isAuthenticated,
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps)(Comments);
