import Cookies from "universal-cookie";

export function logoutUser() {
	let cookies = new Cookies();
	cookies.remove('token', {path: '/'});
	return {
		type: "@@huskytracker/USER_AUTHENTICATED",
		payload: false
	}
}
