import React, {Component} from 'react';
import moment from 'moment';
import {history} from '../../../../store';
import Avatar from '../../../../components/Avatar';

export default class ActivityProject extends Component {

	render() {
		let {activity, project, user} = this.props;
		if (!activity) {
			return null;
		}
		console.log(activity.owner);
		if (activity.type === 'create_project') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div
							className="act__type far-icon circle fill gray">
							<i className="far  fa-layer-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}/info`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> created the project
							<a
								onClick={() => {
									history.push(`/projects/${project.readable_id}`);
								}}> {this.props.project.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'change_description') {
			return (
				<div className="act__item report change-description">
					<div className="item__head">
						<div
							className="act__type far-icon circle fill gray">
							<i class="far  fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}/info`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed the description of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm')}
						</div>
					</div>
					<div className="item__body">
						<span
							dangerouslySetInnerHTML={{__html: activity.meta.new}}/>
					</div>
				</div>
			);
		} else if (activity.type === 'change_brief') {
			return (
				<div className="act__item report change-description">
					<div className="item__head">
						<div
							className="act__type far-icon circle fill gray">
							<i class="far  fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"
							   onClick={() => {
								   history.push(`/people/${activity.owner.id}/info`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed brief of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm')}
						</div>
					</div>
					<div className="item__body">
						from <span> {activity.meta.old}</span>
						to <span>{activity.meta.new}</span>
					</div>
				</div>
			);
		} else {
			if (activity.type === 'add_followers' || activity.type === 'remove_followers') {
				return (
					<div className="act__item report change-description">
						<div className="item__head">
							<div
								className="act__type far-icon circle fill gray">
								<i className="far  fa-pen"></i>
							</div>
							<div className="act__owner">
								<a className="profile__link"
								   onClick={() => {
									   history.push(`/people/${activity.owner.id}/info`);
								   }}>
									<Avatar
										width={36}
										height={36}
										altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
										userId={activity.owner.id}
										hasAvatar={activity.owner.has_avatar}
									/>
									{`${activity.owner.first_name} ${activity.owner.last_name}`}
								</a>
							</div>
							<div
								className="act__action"> {activity.type === 'add_followers' ? 'added ' : 'removed '}
								{activity.meta.map((person, index) => {
									let count = activity.meta.length;
									return (
										<a className="profile__link"
										   onClick={() => {
											   history.push(`/people/${person.id}/info`);
										   }}>
											{/*<Avatar width={36} height={36} altText={`${person.first_name} ${person.last_name}`} userId={person.id}/>*/}
											{`${person.first_name} ${person.last_name}`}{count > 1 ? (index + 1 < count ? ', ' : '') : ''}
										</a>
									);
								})}
								{activity.type === 'add_followers' ? ' to' : ' from'}
								<a
									onClick={() => {
										history.push(`/projects/${this.props.project.readable_id}`);
									}}> {this.props.project.title} </a>
							</div>
							<div className="act__timestamp">
								{moment(activity.created_at).format('h:mm')}
							</div>
						</div>
					</div>
				);
			} else {
				return null;
			}
			return <div>{activity.type}</div>; //make here if condition for added_followers & remove_followers
		}
	}
}
