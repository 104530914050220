import UsersService from "../../../services/api/users";

export function populateUser(data) {
	if (!data) {
		return (dispatch) => {
			UsersService.getCurrentUser()
				.then(data => {
					dispatch({
						type: "@@huskytracker/POPULATE_USER",
						payload: data
					});
				})
		}
	} else {
		return {
			type: "@@huskytracker/POPULATE_USER",
			payload: data
		}
	}

}

export function changeProjectsViewType(data) {
	return {
		type: '@@huskytracker/SET_PROJECTS_VIEW',
		payload: data
	}
}

export function changeCompaniesViewType(data) {
	return {
		type: '@@huskytracker/SET_COMPANIES_VIEW',
		payload: data
	}
}

export function changePeoplesViewType(data) {
	return {
		type: '@@huskytracker/SET_PEOPLES_VIEW',
		payload: data
	}
}
