import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import UsersService from '../../services/api/users';
import { withRouter } from 'react-router';
import ChangeAvatar from './ChangeAvatar';
import CustomSelect from "../../components/CustomSelect";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {populateUser} from "../shared/actions";
import TaxonomiesService from "../../services/api/taxonomies";
import EditSkillsModal from "../Modals/EditSkillsModal";
import {setFilters} from "../../helpers/filters";
import Modal from "react-modal";
const _ = require('lodash');

class ProfileInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: props.user,
			skills: [],
			default_ticket_filtering_options: [
				{
					label: "All Tickets",
					value: 'active,archived'
				},
				{
					label: "Active Tickets",
					value: 'active'
				},
			],
			date_format_options: [
				{
					label: "due 21/05/2020",
					value: 'standard'
				},
				{
					label: "due 1 Month Ago",
					value: 'custom'
				},
			],
			receive_emails_options: [
				{
					label: "Yes",
					value: true
				},
				{
					label: "No",
					value: false
				},
			],
			receive_emails: {
				label: "Yes",
				value: true
			},
			date_format: {
				label: "due 1 Month Ago",
				value: 'custom'
			},
			default_ticket_filtering: {
				label: "All Tickets",
				value: 'active,archived'
			},
			editSkillsModal: false
		};
	}



	componentDidMount() {
		this.getPreferences(this.props.user);
		this.getSkills();
	}

	toggleSkillsModal (boolean) {
		this.setState({editSkillsModal: boolean})
	}

	getPreferences(user){
		let receive_emails = _.get(user, 'meta.preferences.receive_emails');
		let date_format = _.get(user, 'meta.preferences.date_format', 'custom')
		let default_ticket_filtering = _.get(user, 'meta.preferences.default_ticket_filtering', 'active,archived')
		this.setState({
			receive_emails: receive_emails ? {label: 'Yes', value: true} : {label: 'No', value: false},
			date_format: this.state.date_format_options.filter(opt => opt.value === date_format)[0],
			default_ticket_filtering: this.state.default_ticket_filtering_options.filter(opt => opt.value === default_ticket_filtering)[0]
		})
	}

	getSkills() {
		TaxonomiesService.get('skills')
			.then(res => {
				this.setState({
					skills: res,
					skillGroups: this.getSkillGroups(res)
				})
			})
			.catch(err => {
				console.error(err);
			});
	}

	getSkillGroups(list) {
		const groups = list.filter(item => _.isNil(item.parent_id));
		return _.sortBy(
			_.uniqBy(groups, 'id'), 'order'
		)
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.props.getUserProfile();
		}
	}

	onPreferenceChange(name, value) {
		UsersService.saveUserPreferences(this.state.user.id, {...this.state.user.preferences ?? {}, [name]: value.value})
			.then(response => {
				this.props.getUserProfile();
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {
		if (!this.state.user) return null;
		let location = this.state?.user?.meta?.location || {};
		let social_handles = this.state?.user?.social_handles || {};

		let userTicketsCount = (this.state.user.active_tickets + this.state.user.owner_tickets + this.state.user.following_tickets) || 0
		let userProjectsCount = this.state.user.active_projects || 0
		return (
			<section className="profile-page__container">
				<div className="user-profile_container custom-modal__overflow">
					<div className="user-profile">
						<div className="user_avatar">
							<ChangeAvatar
								altText={
									(this.state.user?.first_name ?? '') +
									' ' +
									(this.state.user?.last_name ?? '')
								}
								hasAvatar={this.state.user.has_avatar}
								currentUserID={this.state.user?.id ?? null}
							/>
						</div>
						<h2 className="user_name">
							{this.state.user?.first_name ?? null}{' '}
							{this.state.user?.last_name ?? null}
						</h2>
						<ul className="user_position">
							<li></li>
							<li>
								{this.state.user?.user_profile?.company?.name ?? null}
							</li>
						</ul>
						<div className="scrollable-tabs__container is-hidden-on-tablet">
							<ul className="scrollable-tabs">
								<li>
									<NavLink to="" className="active">
										Info
									</NavLink>
								</li>
								<li>
									<NavLink to="">Activity</NavLink>
								</li>
								<li>
									<NavLink to="">Tickets</NavLink>
								</li>
								<li>
									<NavLink to="">Projects</NavLink>
								</li>
							</ul>
						</div>
						<ul className="user_statistics">
							<li>
								<h3>{userTicketsCount}</h3>
								<span>TICKETS</span>
							</li>
							<li>
								<h3>{userProjectsCount}</h3>
								<span>PROJECTS</span>
							</li>
							<li>
								<h3>
									{this.state.user?.userAttachmentsCount ?? 0}
								</h3>
								<span>FILES</span>
							</li>
						</ul>
						<ul className="user_information">
							<li className="t-order-1">
								<i className="user-information-icon far  fa-envelope"></i>
								<a
									href={`mailto:${this.state.user?.email ?? null}`}
								>
									{this.state.user?.email ?? '-'}
								</a>
							</li>
							<li className="t-order-3">
								<i className="user-information-icon fab fa-skype" />
								<a
									className={`${
										social_handles.skype
											? ''
											: 'is_empty'
									}`}
									href={`skype:${
										social_handles.skype || ''
									}?call`}
								>
									{social_handles.skype ||
										'+ add skype'}
								</a>
							</li>
							<li className="t-order-5">
								<i className=" user-information-icon far  fa-phone-alt"></i>
								<a
									className={`${
										this.state.user.phone_number
											? ''
											: 'is_empty'
									}`}
									href={`tel:${
										this.state.user
											.phone_number || ''
									}?call`}
								>
									{this.state.user.phone_number ||
										'+ add phone number'}
								</a>
							</li>
							<li className="t-order-2">
								<i className=" user-information-icon far  fa-external-link-alt" />
								<a
									href={`${
										this.state.user.website || ''
									}`}
								>
									{this.state.user.website || '-'}
								</a>
							</li>
							<li className="t-order-4">
								<i className="user-information-icon far  fa-map-marker-alt" />
								{isEmpty(location)
									? '-'
									: `${location['address']}, ${location['city']}, ${location['country']}`}
							</li>
							<li className="t-order-6">
								<i className="user-information-icon far  fa-birthday-cake" />
								{this.state.user.birth
									? moment(
											this.state.user.birth,
									  ).format('MM/DD/YYYY')
									: '-'}
							</li>
						</ul>
						<div className="user-section">
							<h3 className="user-section__title">
								Preferences
							</h3>
							<ul className={'preferences '}>
								<li>
									<span>
										Receive Email Notifications
									</span>
									<CustomSelect
										name="receive_emails"
										noQueryParams={true}
										className="filter"
										disabled={true}
										value={this.state.receive_emails}
										onChange={(v) =>
											this.onPreferenceChange('receive_emails', v,)
										}
										options={this.state.receive_emails_options}
									/>
								</li>
								<li>
									<span>
										Preferred Ticket Date Format
									</span>
									<CustomSelect
										name="date_format"
										className="filter"
										disabled={true}
										noQueryParams={true}
										value={this.state.date_format}
										onChange={(v) =>
											this.onPreferenceChange('date_format', v,)
										}
										options={this.state.date_format_options}
									/>
								</li>
								<li>
									<span>
										Default Ticket Filtering
									</span>
									<CustomSelect
										name="default_ticket_filtering"
										noQueryParams={true}
										disabled={true}
										className="filter"
										value={this.state.default_ticket_filtering}
										onChange={(v) =>
											this.onPreferenceChange('default_ticket_filtering', v,)
										}
										options={
											this.state.default_ticket_filtering_options
										}
									/>
								</li>
							</ul>
						</div>
						<div className="user-section">
							<h3 className="user-section__title">Skills</h3>
							{(this.state.user.user_profile?.skill_ids?.length === 0 || !this.state.user.user_profile.skill_ids) && (
								<div className={'is_empty'}>
									<span>No skills added yet</span>
									<img alt="no skills"
										 src={require('./../../assets/images/no_skills_image.png')}/>
									<button
										className={'btn link'}
										onClick={(e) => {this.toggleSkillsModal(true);}}>
										+ add skills
									</button>
								</div>
							)}
							{this.state.user?.user_profile?.skill_ids?.length > 0 && this.state.skills?.length > 0 && (
								<Fragment>
									<ul className="user_skills">
										{this.state.skills.map((skill,index) => {
											if(this.state.user?.user_profile?.skill_ids?.find(s => s === skill.id)) {
												return (
													<li key={index}>
														<span>
															{skill.name}
														</span>
													</li>
												);
											}
										})}
									</ul>
									<div className={'is_empty'}>
										<button
											className={'btn link'}
											onClick={(e) => {this.toggleSkillsModal(true);}}>
											+ add skills
										</button>
									</div>
								</Fragment>
							)}

						</div>
						<div className={'user-section'}>
							<h3 className={'user-section__title'}>
								Payment Info
							</h3>
							<ul className={'user_payment'}>
								<li>
									<span>Account</span>
									<strong>
										{this.state.user?.user_profile?.company?.payment_info?.account_number}
									</strong>
								</li>
								<li>
									<span>Bank Name</span>
									<strong>
                    {this.state.user?.user_profile?.company?.payment_info?.bank_name}
									</strong>
								</li>
								<li>
									<span>Currency</span>
									<strong>
										{this.state.user?.user_profile?.company?.payment_info?.currency}
									</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.state.editSkillsModal}
					onRequestClose={()=>{this.setState({editSkillsModal: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}>
					<EditSkillsModal closeModal={(data)=>{ if(data) {this.props.getUserProfile(); } else {this.setState({editSkillsModal: false})} }}
									 user={this.props.user}
									   onFilterChange={(data)=>{setFilters("skill_ids",data.value); this.onFilter('skill_ids',data)}}
									   value={this.props.user?.user_profile?.skill_ids || []}
									   skillGroups={this.state.skillGroups}
									   skills={this.state.skills}/>
				</Modal>
			</section>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({populateUser}, dispatch);
}

export default connect(null, mapDispatchToProps)(withRouter(ProfileInfo));
