import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import Modal from "react-modal";
import EditCompanyModal from "../../Modals/EditCompanyModal";
class SubNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editCompanyModalIsOpen: false
		}
	}
	componentDidMount() {
		Modal.setAppElement('body');

	}

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head"><h1 className="page-title">{this.props.title}</h1>

							<div className="btn-wrap page-controls">
								<div className="btn-wrap"><button
									onClick={() => {
										this.setState({
											editCompanyModalIsOpen: true
										})
									}}
									className="btn fill blue edit-company-modal__open">
									Edit company
								</button>
									<button
									onClick={() => this.setState({
										archive: 'companie arhivata'
									})}
										className="btn fill blue">Archive
									Company</button></div>
							</div>
						</div>
					</div>
					<div className="wrapper wide">
						<ul className="navigation-tab-links is-inline is-scrollable">
							<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/company`}>Company</NavLink></li>
							<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/projects`}>Projects</NavLink></li>
						</ul>
					</div>
				</div>
				<Modal
					isOpen={this.state.editCompanyModalIsOpen}
					onRequestClose={()=>{this.setState({editCompanyModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<EditCompanyModal
						company={this.props.company}
						closeModal={() => {
							this.setState({editCompanyModalIsOpen: false})
						}}
						onUpdate={this.props.onUpdate}
					/>
				</Modal>
			</Fragment>
		);
	}
}

export default withRouter(SubNavigation);
