import React, {useEffect, useMemo, useState} from 'react';
import useForm from 'react-hook-form';
import {Input} from '../inputs/Input';
import ChangeAvatar from '../screens/Profile/ChangeAvatar';
import PillsSelector from '../components/PillsSelector';
import TaxonomiesService from '../services/api/taxonomies';
import UsersService from '../services/api/users';
import DatePicker from 'react-datepicker';
import CustomSelect from '../components/CustomSelect';
import PermissionsService from '../services/api/permissions';
import {ChangeRoleSelect} from '../inputs/ChangeRoleSelect';
import {ChangeCompanySelect} from '../inputs/ChangeCompanySelect';
import CompaniesService from '../services/api/companies';

export default function EditProfileForm(props) {
	const {register, handleSubmit, setValue, getValues, errors} = useForm({
		mode: 'onChange'
	});
	const [positions, setPositions] = useState({isFetching: true});
	const [position, setPosition] = useState(props.user.user_profile.position_id);
	const default_ticket_filtering_options = [
			{
				label: 'All Tickets',
				value: 'active,archived'
			},
			{
				label: 'Active Tickets',
				value: 'active'
			}
		],
		date_format_options = [
			{
				label: 'due 21/05/2020',
				value: 'standard'
			},
			{
				label: 'due 1 Month Ago',
				value: 'custom'
			}
		],
		receive_emails_options = [
			{
				label: 'Yes',
				value: true
			},
			{
				label: 'No',
				value: false
			}
		],
		archived_profile_options = [
			{
				label:'Yes',
				value: true
			},
			{
				label:'No',
				value: false
			}
		];

	useEffect(() => {
		TaxonomiesService.get('positions')
			.then(res => {
				setPositions({
					positions: res,
					isFetching: false
				});
			})
			.catch(err => {
				setPositions({positions: [], isFetching: false});
			});
	}, [setPositions]);

	const [inputs, setInputs] = useState(props.user);

	const onSubmit = data => {
		
		if (data) {

			if(data.birth === ""){
				data.birth = null;
			}

			let pref = {};
			Object.keys(preferences).map((label) => {
				// if (label === 'archived_profile') {
				// 	return;
				// }
				pref[label] = preferences[label].value;
			});
			data.meta.preferences = pref;
			data.user_profile = {
				is_archived: preferences.archived_profile.value,
				skill_ids: props.user?.user_profile?.skill_ids || [],
				position_id: position
			};
			if (role) {
				data.user_profile.role_id = role;
			}
			if (company) {
				data.user_profile.company_id = company;
			}

			UsersService.editUser(props.user.id, data)
				.then(res => {
					// props.closeModal(res);
					console.log(res);
				})
				.catch(err => {
					console.log(err);
				});
		}

	};

	const getDefaultPreferences = (preferences) => {
		if (preferences) {
			let {archived_profile, receive_emails, date_format, default_ticket_filtering} = preferences;
			archived_profile = archived_profile_options.filter(el => el.value === archived_profile)[0];
			receive_emails = receive_emails_options.filter(el => el.value === receive_emails)[0];
			date_format = date_format_options.filter(el => el.value === date_format)[0];
			default_ticket_filtering = default_ticket_filtering_options.filter(el => el.value === default_ticket_filtering)[0];
			return {
				archived_profile,
				receive_emails,
				date_format,
				default_ticket_filtering
			};
		} else {
			return {
				receive_emails: {
					label: 'Yes',
					value: true
				},
				date_format: {
					label: 'due 1 Month Ago',
					value: 'custom'
				},
				default_ticket_filtering: {
					label: 'All Tickets',
					value: 'active,archived'
				},
				archived_profile: {
					label:'No',
					value: false
				}
			};
		}
	};

	const [preferences, setPreferences] = useState(getDefaultPreferences(props.user?.meta?.preferences ?? null) || {
		archived_profile: {
			label:'No',
			value: false
		},
		receive_emails: {
			label: 'Yes',
			value: true
		},
		date_format: {
			label: 'due 1 Month Ago',
			value: 'custom'
		},
		default_ticket_filtering: {
			label: 'All Tickets',
			value: 'active,archived'
		}
	});

	const editPreferences = (event, name) => {
		let newPreferences = JSON.parse(JSON.stringify(preferences));
		newPreferences[name] = event;
		setPreferences(newPreferences);
	};
	const handleInputChange = (event, name) => {
		setInputs(inputs => ({...inputs, [name]: event}));
		setValue(name, event, true);
	};

	const [role, setRole] = useState(props.user.user_profile.role_id);
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		const getRoles = async () => {
			const res = await PermissionsService.getRoles();
			setRoles(res.data);
		};
		getRoles();
	}, []);

	const handleChangeRole = (e) => {
		setRole(e.value);
	};
	const roleName = useMemo(() => roles.find(r => r.id === role)?.name, [role, roles]);

	const [company, setCompany] = useState(props.user.user_profile.company_id);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		const getCompanies = async () => {
			const res = await CompaniesService.listCompanies();
			setCompanies(res.data);
		};
		getCompanies();
	}, []);
	const handleChangeCompany = (e) => {
		setCompany(e.value);
	};

	console.log(props.user);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="f-section">
				<div className="re-avatar-upload">
					<div className="user_avatar">
						<ChangeAvatar
							altText={
								props.user.first_name +
								' ' +
								props.user.last_name
							}
							currentUserID={props.user.id}
							hasAvatar={props.user.has_avatar}
						/>
					</div>
				</div>
			</div>
			<div className="f-section">
				<h3 className="f-section__title">Personal Information</h3>
				<div className="f-row">
					<div className="f-element">
						<label>First name</label>
						<Input register={register}
									 defaultValue={inputs.first_name}
									 onChange={(event) => handleInputChange(event, 'first_name')}
									 name="first_name"/>
					</div>
					<div className="f-element">
						<label>Last name</label>
						<Input register={register}
									 defaultValue={inputs.last_name}
									 onChange={(event) => handleInputChange(event, 'last_name')}
									 name="last_name"
						/>
					</div>
				</div>

				<div className="f-row">
					<div className="f-element">
						<label>Email</label>
						<Input register={register}
									 defaultValue={inputs.email}
									 onChange={(event) => handleInputChange(event, 'email')}
									 name="email"/>
					</div>
					<div className="f-element">
						<label>Website</label>
						<Input register={register}
									 defaultValue={inputs.website}
									 onChange={(event) => handleInputChange(event, 'website')}
									 name="website"
						/>
					</div>
				</div>

				<div className="f-row">
					<div className="f-element">
						<label>Phone Number</label>
						<Input register={register}
									 defaultValue={inputs.phone_number}
									 onChange={(event) => handleInputChange(event, 'phone_number')}
									 name="phone_number"/>
					</div>
					<div className="f-element">
						<label>Skype</label>
						<Input register={register}
									 defaultValue={inputs.social_handles?.skype ?? null}
									 onChange={(event) => handleInputChange(event, 'social_handles.skype')}
									 name="social_handles.skype"
						/>
					</div>
				</div>

				<div className="f-row">
					<div className="f-element is-third">
						<label>Date of birth</label>
						<DatePicker
							className={`dateInput`}
							dropdownMode="select"
							dateFormatCalendar={'MM/dd/yyyy'}
							dateFormat={'MM/dd/yyyy'}
							selected={new Date(inputs.birth)}
							showMonthDropdown={true}
							showYearDropdown={true}
							onChange={(event) => {
								handleInputChange(event, 'birth');
							}}
						/>
						<Input register={register}
									 type={'hidden'}
									 defaultValue={inputs.birth}
									 onChange={(event) => handleInputChange(event, 'birth')}
									 name="birth"/>
						{/*	calendar! */}
					</div>
					<div className="f-element">
						<label>Address</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.location?.address ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.location.address')}
									 name="meta.location.address"
						/>
					</div>
				</div>

				<div className="f-row">
					<div className="f-element">
						<label>City</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.location?.city ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.location.city')}
									 name="meta.location.city"/>
					</div>
					<div className="f-element">
						<label>State</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.location?.state ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.location.state')}
									 name="meta.location.state"
						/>
					</div>
					<div className="f-element">
						<label>Country</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.location?.country ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.location.country')}
									 name="meta.location.country"
						/>
					</div>
				</div>

			</div>

			{/*{skills && !skills.isFetching && <div className="f-section">*/}
			{/*	<h3 className="f-section__title">Skills</h3>*/}
			{/*	<PillsSelector*/}
			{/*		multi={true}*/}
			{/*		onChange={(event)=> {setUserSkill(event)}}*/}
			{/*		options={skills.skills}*/}
			{/*		value={userSkills}/>*/}
			{/*</div>}*/}

			{positions && !positions.isFetching && <div className="f-section">
				<h3 className="f-section__title">Position</h3>
				<PillsSelector
					multi={false}
					onChange={(event) => {
						setPosition(event);
					}}
					options={positions.positions}
					value={position}/>
			</div>}
			<div className="f-section">
				<h3 className="f-section__title">Role</h3>
				<div className="f-row">
					<div className="f-element">
						<ChangeRoleSelect
							value={role}
							roles={roles}
							onChange={handleChangeRole}
						/>
					</div>
				</div>
			</div>
			{
				roleName && roleName === 'client' &&
				<div className="f-section">
					<h3 className="f-section__title">Company</h3>
					<div className="f-row">
						<div className="f-element">
							<ChangeCompanySelect
								value={company}
								companies={companies}
								onChange={handleChangeCompany}
							/>
						</div>
					</div>
				</div>
			}


			<div className="f-section">
				<h3 className="f-section__title">Preferences</h3>
				<ul className={'preferences'}>
					<li>
						<span>Receive Email Notifications</span>
						<CustomSelect
							name="receive_emails"
							noQueryParams={true}
							className="filter"
							value={preferences.receive_emails}
							onChange={(event) => editPreferences(event, 'receive_emails')}
							options={receive_emails_options}
						/>
					</li>
					<li>
						<span>Preferred Ticket Date Format</span>
						<CustomSelect
							name="date_format"
							className="filter"
							noQueryParams={true}
							value={preferences.date_format}
							onChange={(event) => editPreferences(event, 'date_format')}
							options={date_format_options}
						/>
					</li>
					<li>
						<span>Default Ticket Filtering</span>
						<CustomSelect
							name="default_ticket_filtering"
							noQueryParams={true}
							className="filter"
							value={preferences.default_ticket_filtering}
							onChange={(event) => editPreferences(event, 'default_ticket_filtering')}
							options={default_ticket_filtering_options}
						/>
					</li>
					<li>
						<span>Set is archived</span>
						<CustomSelect
							name="archived_profile"
							noQueryParams={true}
							className="filter"
							value={preferences.archived_profile}
							onChange={(event) => editPreferences(event, 'archived_profile')}
							options={archived_profile_options}
						/>
					</li>
				</ul>
			</div>

			{/* <div className="f-section">
				<h3 className="f-section__title">Payment Information</h3>
				<div className="f-row">
					<div className="f-element">
						<label>IBAN/ABA account number</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.payment_info?.account_number ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.payment_info.account_number')}
									 name="meta.payment_info.account_number"/>
					</div>
				</div>
				<div className="f-row">
					<div className="f-element">
						<label>Bank name</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.payment_info?.bank_name ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.payment_info.bank_name')}
									 name="meta.payment_info.bank_name"/>
					</div>
				</div>
				<div className="f-row">
					<div className="f-element">
						<label>Swift code</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.payment_info?.swift_code ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.payment_info.swift_code')}
									 name="meta.payment_info.swift_code"/>
					</div>
					<div className="f-element">
						<label>Currency</label>
						<Input register={register}
									 defaultValue={inputs?.meta?.payment_info?.currency ?? null}
									 onChange={(event) => handleInputChange(event, 'meta.payment_info.currency')}
									 name="meta.payment_info.currency"/>
					</div>
				</div>
			</div> */}

			<div className="f-section">
				<div className="f-row btn-container">
					<button className="btn fill blue"
									onClick={() => onSubmit()}>Save
					</button>
					<button onClick={() => props.closeModal()}
									className="btn outline gainsboro">Close
					</button>
				</div>
			</div>

		</form>
	);
}
