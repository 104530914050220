import React, {Component, Fragment} from 'react';
import SubNavigation from "../shared/SubNavigation/SubNavigation";
import ActivityDashboard from "./ActivityDashboard";

export default class Dashboard extends Component {

	render() {
		return (
			<Fragment>
				<SubNavigation title="Activity" />
				<ActivityDashboard/>
			</Fragment>
		);
	}
}



