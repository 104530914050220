import React, {Component, Fragment} from 'react';
import Avatar from "../../components/Avatar";
import {NavLink} from "react-router-dom";

class ConfirmDeleteSkillModal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {forDelete, affectedPeople} = this.props;
		return(
			<div className="e-modal">
				<div className="e-modal__container">
					<button onClick={() => {this.props.toggleConfirm()}} className="e-modal__close"/>
					<div>
						<div className="e-modal__head"><h2
							className="e-modal__title">Remove "{forDelete.name}"
							"Skill</h2></div>
						<div className="e-modal__body">
							<div className="e-modal__body-overflow">
								<div className={'f-row is-full'}>
									<div className={'f-element is-full'}>
										<p>People which will be affected if you remove the '{forDelete.name}' skill:</p>
									</div>
								</div>

									<div className="f-row is-full">
										<div className={'custom-single-user-select__container'}>
									<div className="f-element is-full" >
										{affectedPeople.map(item =>
												<NavLink
													to={`/people/${item.user_id}`}
													className={'custom-single-user-select__trigger has--avatar row'}
													key={item.user_id}
													target='_blank'>
														<Avatar
															width={30}
															height={30}
															className={'custom-single-user-select__avatar'}
															altText={item.user.first_name + ' ' + item.user.last_name}
															userId={item.user_id}
															hasAvatar={item.user.has_avatar}
														/>
														<span className="custom-single-user-select__label right">
															{item.user.first_name + ' ' + item.user.last_name}
														</span>

												</NavLink>
										)}
									</div>
									</div>
								</div>

								<div className="f-row btn-container">
									<button className='btn fill blue round'
											onClick={() => this.props.confirmDelete(forDelete)}>
										Confirm
									</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ConfirmDeleteSkillModal;
