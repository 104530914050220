import React, {Fragment, useEffect, useState} from "react";

export default function TeamMembers(props) {
	const [followers, setFollowers] = useState([]);
	const [queryFollowers, setQueryFollowers] = useState([]);
	const [showPersons, setShowPersons] = useState(false);

	useEffect(() => {
		setFollowers(props.followers)
	}, [props.followers]);

	useEffect(() => {
		setQueryFollowers(props.searchedFollowers)
	}, [props.searchedFollowers]);


	const onChange = (event) => {
		event.preventDefault();
		if(event.target.value.length >= 2) {
			props.search(event.target.value, 'clients');
			setShowPersons(true);
		} else {
			setQueryFollowers([]);
			setShowPersons(false);
		}
	};


	return (
		<Fragment>
			<label htmlFor="entity-select__member-search"
				   className="entity-select__label">Add clients</label>
			<div className="entity-select__search-container">
				<input
					type="search"
					id="entity-select__member-search"
					placeholder="Search"
					className="entity-select__search"
					onChange={(e) =>onChange(e)}
				/>{ showPersons ?
				<span>
						{queryFollowers.length ? queryFollowers.map(item => {
							return (
								<ul onClick={() => props.editFollowers(item, 'add', 'clients')}
									className="entity-select__recommendations">
									<li className="entity-select__recommendation">
										<div className="entity">
											<span
												className="entity__avatar"></span>
											<span
												className="entity__name">{item.label}</span>
											<span className="entity__meta">
											<span
												className="entity__type"></span>
											<span
												className="entity-result__addt"></span>
										</span>
										</div>
									</li>
								</ul>
							)}
						) : <ul className="entity-select__recommendations">
							<li className="entity-select__recommendation">
								<div className="entity">
									<span className="entity__avatar"></span>
									<span className="entity__name">
                                        No person found.
								</span>
								</div>
							</li>
						</ul>}
					</span> : null}
			</div>
			{followers.length ?
				<div>
					<span className="entity-select__label">Clients on the project</span>
					<ul className="entity-select__results">
						{followers.map(follower => {
							return (
								<li onClick={() => props.editFollowers(follower, 'delete', 'clients')}
									className="entity-select__result">
									<div className="entity">
							<span className="entity__avatar">
								<img src={follower.avatarUrl} width="30"/>
							</span>
										<span className="entity__name">
										{follower.label}
							</span>
										<span className="entity__meta">
								<span className="entity__type"></span>
								<span className="entity__status">
									<span className="entity__checked"></span>
								</span>
							</span>
									</div>
								</li>
							)
						})}

					</ul>
				</div> : null
			}
		</Fragment>
	)
}
