import React, {Component, Fragment} from 'react';
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import CompaniesService from "../../services/api/companies";
import EditProjectForm from "../../forms/EditProjectForm";

class EditProjectModal extends Component {


	constructor(props) {
		super(props);
		this.state = {
			companies: []
		};
	}

	getCompanies() {
		CompaniesService.listCompanies()
			.then(response => {
				let companies = response.data;
				companies = companies.map(c => {return {label: c.name, value: c.id}});
				this.setState({
					companies
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	componentDidMount() {
		this.getCompanies();

	}

	render() {

		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container">
						<button onClick={()=>{this.props.closeModal()}} className="e-modal__close"/>
						<div name="edit-project">
							<div className="e-modal__head"><h2 className="e-modal__title">Edit "{this.props.project.title}"</h2></div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<EditProjectForm companies={this.state.companies} project={this.props.project} closeModal={(data) => this.props.closeModal(data)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}


EditProjectModal = reduxForm({
	form: 'editProjectForm',
	destroyOnUnmount: true
})(EditProjectModal);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}


export default connect(mapStateToProps, null)(withRouter(EditProjectModal));
