import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import CustomStatusSelect from '../CustomStatusSelect';
import TicketDetails from '../TicketDetails';
import AlertEscTicketModal from '../../screens/Modals/AlertEscTicketModal';
import {NavLink} from 'react-router-dom';
import TicketsService from '../../services/api/tickets';
// import {DueDate} from '../DueDate';
import moment from 'moment';
import UploadFile from '../UploadFile';

import TaxonomiesService from '../../services/api/taxonomies';
import UsersService from '../../services/api/users';
import DropdownEditTicket from '../DropdownEditTicket';
import HtmlEditor from '../HtmlEditor';
import {withRouter} from 'react-router';
import DueDate from '../DueDate';
import UploadService from '../../services/api/upload';
import SelectUsers from '../SelectUsers';
import {connect} from 'react-redux';
import {PermissibleRender} from '@brainhubeu/react-permissible';

const _ = require('lodash');


class TicketItemExpanded extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ticket: {owner: {}},
			statuses: [],
			people: [],
			users: [],
			filteredPeople: [],
			followers: [],
			dropdownState: false,
			followersState: false,
			isEdit: false,
			isSubmitting: false,
			initialValue: null,
			assignee: null,
			isUploadComponentHidden: true,
			isAlertEscModalOpen: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);

	}

	closeModal() {
		let {isModal} = this.props;
		isModal ?
			this.props.closeModal() :
			this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push(`/projects/${this.props.project.readable_id}/tickets`);

	}

	async componentDidMount() {
		this.checkFiles();
		await this.getTicketById(this.props.match.params.id);
		await this.getPeople();
	}

	async getTicketById(id) {
		let {isModal} = this.props,
			ticketId = isModal ? this.props.ticket.id : this.props.match.params.id;
		await TicketsService.getById(ticketId)
			.then(response => {
				this.setState({
					ticket: response,
					due_date: response.due_date ? moment.utc(response.due_date).fromNow() : 'date not set',
					followers: response.followers.map(res => {
						return res.user_id;
					}),
					initialValue: response.description
				});
			})
			.catch(err => {
				console.log(err);
			});
		this.getStatuses();
	}

	getStatuses() {
		TaxonomiesService.get('statuses')
			.then(res => {
				let canCloseTickets = _.includes(this.props.userPermissions, 'can_close_ticket');
				let statuses = res.map(status => {
					return {
						value: status.id,
						label: status.name,
						order: status.order,
						bgColor: status.color
					};
				}).sort((a, b) => a.order - b.order).filter(status => {
					if (status.label === 'Closed') {
						if (canCloseTickets) {
							return status;
						} else {
							return null;
						}
					}
					return status;
				});
				this.setState({statuses});
			})
			.catch(err => {
				console.log(err);
			});
	}

	async getPeople() {
		this.setState({
			people: [],
			users: []
		});

		await UsersService.getAllUsers({
			limit: '-1',
			related: 'user_profile.position',
			filters: { is_archived: false }
		})
			.then(res => {
				let users = res.data.map(user => {
					return {
						...user,
						...{
							label: user.first_name + ' ' + user.last_name,
							value: user.id
						}
					};
				});
				let people = res.data.map(person => {
					person.selected = this.state.followers.includes(person.id);
					person.label = person.first_name + ' ' + person.last_name;
					person.value = person.id;
					return person;
				}).sort((a, b) => (a.selected > b.selected) ? -1 : 1);
				this.setState({
					users,
					people,
					filteredPeople: people,
					assignee: users.find(user => {
						return user.id === this.state.ticket.assignee_id;
					})
				});
			})
			.catch(err => {
				console.log(err);
			});

	}

	updateFollowers(data) {
		TicketsService.updateTicket(this.state.ticket.id, {followers: data})
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			});
	}

	async changeStatusTicket(event) {
		let oldStatus = this.state.statuses.find(status => status.value === this.state.ticket.status_id);

		if (oldStatus?.value === event?.value) {
			return;
		}
		this.setState(prevState => ({
			ticket: {
				...prevState.ticket,
				status_id: event.value
			}
		}));
		try {
			await TicketsService.updateTicket(this.state.ticket.id, {status_id: event.value});
		} catch (e) {
			this.setState(prevState => ({
				ticket: {
					...prevState.ticket,
					status_id: oldStatus?.value
				}
			}));
		}

	}


	handleDateChange(date) {
		this.setState({
			ticket: {...this.state.ticket, ...{due_date: date}}
		});
		TicketsService.updateTicket(this.state.ticket.id, {'due_date': date});
	}

	handleInputChange = (value, name) => {
		this.setState(prevState => ({
			ticket: {
				...prevState.ticket,
				[name]: value
			}
		}));
	};

	handleSubmit = () => {
		this.setState({
			isSubmitting: true,
			isEdit: false,
			initialValue: this.state.ticket.description
		});

		TicketsService.updateTicket(this.state.ticket.id, {
			'title': this.state.ticket.title,
			'description': this.state.ticket.description
		})
			.then(res => {
				this.setState({isSubmitting: false});
			})
			.catch(err => {
				console.log(err);
				this.setState({isSubmitting: false});
			});
	};

	async closeTicket(id) {
		await TicketsService.closeTicket(id);
		this.getTicketById(id);
	}


	searchFollowers = (input) => {
		this.setState({input}, () => this.filterList());
	};

	filterList() {
		let people = this.state.people;
		let input = this.state.input;

		people = people.filter(function (person) {
			return (person.first_name.toLowerCase() + ' ' + person.last_name.toLowerCase()).indexOf(input) != -1; // returns true or false
		});
		this.setState({filteredPeople: people});
	}

	handleInputChangeAsignne = async (event) => {
		await this.setState({assignee: event});
		TicketsService.updateTicket(this.state.ticket.id, {
			assignee_id: this.state.assignee
		});
	};

	checkFiles() {
		const ticketId = this.props.match.params.id || this.props.ticket.id;
		UploadService.getResources(
			'Ticket',
			ticketId
		)
			.then(response => {
				this.setState({
					isUploadComponentHidden: response.length === 0
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	handleRequestClose = (event) => {
		if (this.state.isEdit && event.keyCode === 27) {
			event.preventDefault();
			this.setState({isAlertEscModalOpen: true});
		} else {
			this.closeModal();
		}
	};
	handleCloseAlertEscModal = () => {
		this.setState({isAlertEscModalOpen: false});
	}

	render() {
		const customStatusOptions = this.state.statuses;
		const ticketId = this.props.match.params.id || this.props.ticket.id;
		return (
			<Modal
				isOpen={true}
				onRequestClose={this.handleRequestClose}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={true}
			>
				<Fragment>
					<div className="e-modal">
						<div className="e-modal__container wide">
							<button onClick={() => {
								this.closeModal();
							}} className="e-modal__close"></button>
							<div className="ticket-item">
								<div className="ticket-head">
									<div className="ticket-head__row meta-data">
										<div
											className="ticket-head__cell ticket__status has-tooltip"
											data-original-title="null">
											{/* <TicketStatus statusName={"closed"} type={'pill'} statusColor={"#8F95B3"} statusId={"id"} /> */}
											<CustomStatusSelect
												options={customStatusOptions}
												onChange={this.changeStatusTicket.bind(this)}
												value={this.state.statuses.find(status => {
													return this.state.ticket.status_id === status.value;
												})}/>
										</div>
										{/*todo import sharde/asigned compoenent */}
										<div
											className={'morph-grid__item morph-ticket-item'}>
											<div
												className={'morph-ticket-item__group gamma-delta-epsilon'}
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center'
												}}>
												<div
													className="morph-ticket-item__cell delta"
													style={{margin: 0}}>
													<div
														className="morph-ticket-item__asignee">
														<SelectUsers
															users={this.state.users}
															onChange={(data) => this.handleInputChangeAsignne(data)}
															selected={this.state.assignee}/>
													</div>

												</div>
												<div
													className="morph-ticket-item__cell epsilon"
													style={{marginLeft: 18}}>
													<div
														className="morph-ticket-item__due-date">
														<div
															className="dropdown">
															<DueDate
																value={this.state.ticket.due_date && new Date(this.state.ticket.due_date)}
																minDate={new Date()}
																onChange={(date) => this.handleDateChange(date)}/>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/*edit button*/}
										<DropdownEditTicket
											closeTicket={() => this.closeTicket(this.state.ticket.id)}
											showEditTicket={() => this.setState({isEdit: !this.state.isEdit})}
										/>
									</div>
									<div className="ticket-head__row">
										<div
											className="ticket-head__cell ticket__title">
											{!this.state.isEdit ?
												<h2>{this.state.ticket.title}</h2>
												: <div className={'f-row'}>
													<div
														className={'f-element'}>
														<input type="text"
																	 name={'title'}
																	 value={this.state.ticket.title}
															// defaultValue={this.state.ticket.title}
																	 onChange={(event) => this.handleInputChange(event.target.value, 'title')}
														/>
													</div>
												</div>
											}
										</div>
									</div>
									<div className="ticket-head__row">
										<div
											className="ticket-head__cell ticket__relations">
											<NavLink
												to={`/projects/${this.props.project.id}`}
												className="ticket-project-name"
											>
												{this.props.project.title}
											</NavLink>
											<div className={'ticket-head-wrapper'}>
												<span className="ticket-id">
													#{this.state.ticket.readable_id}
												</span>
												<span className="created-by">
													 Created by&nbsp;
													<PermissibleRender
														userPermissions={this.props.userPermissions}
														requiredPermissions={['can_view_user_profile']}
														renderOtherwise={
															<span>{this.state.ticket.owner.first_name} {this.state.ticket.owner.last_name}</span>}
														oneperm
													>
															<NavLink
																to={`/people/${this.state.ticket.owner.id}`}
																alt={this.state.ticket.owner.label}
																target="_blank">
																{this.state.ticket.owner.first_name} {this.state.ticket.owner.last_name}
															</NavLink>

													</PermissibleRender>
													&nbsp;on {moment(this.state.ticket.created_at).format('DD MMM YYYY')}
												</span>
											</div>
										</div>
										{/*todo import due date shared/component*/}
										<SelectUsers
											users={this.state.filteredPeople}
											selected={this.state.filteredPeople.filter(user => user.selected)}
											isMultiSelect={true}
											onChange={data => this.updateFollowers(data)}
										/>
									</div>
								</div>

								<div className="ticket-body">
									{!this.state.isEdit ?
										<div
											dangerouslySetInnerHTML={{__html: this.state.ticket.description}}/>
										:
										<div>
											<div className={'f-row'}>
												<div className={'f-element'}>
													<HtmlEditor
														name={'ticket-description'}
														value={this.state.ticket.description}
														html={this.state.initialValue}
														onChange={(event) => this.handleInputChange(event, 'description')}/>
												</div>
											</div>
											<div className="file-upload">
												<UploadFile
													isNeedResource={true}
													resource='Ticket'
													resource_id={ticketId}
												/>
											</div>
											<div
												className="f-row btn-container">
												<button
													className="btn outline gainsboro"
													type={'button'}
													onClick={() => this.setState({isEdit: false})}>Cancel
												</button>
												<button
													className="btn fill blue is-animated"
													onClick={this.handleSubmit}>
													{this.state.isSubmitting ?
														<span
															className="animate-loading"><span
															className="circle"/></span>
														:
														<span
															className="btn-label">Save</span>
													}
												</button>
											</div>

											{this.state.isEdit ?
												<button
													className="close-edit-mode-in-modal"
													onClick={() => this.setState({isEdit: false})}></button>
												: null}
										</div>
									}
								</div>

								{/*todo create file-upload component*/}
								{!this.state.isEdit && !this.state.isUploadComponentHidden ?
									<div className="file-upload">
										<UploadFile
											disabled={true}
											resource='Ticket'
											resource_id={ticketId}
										/>
									</div>
									: null
								}

								{/*todo  tabs for ticket*/}
								{!this.state.isEdit ?
									<TicketDetails
										ticket_id={this.state.ticket.id}
									/>
									: null}
								{
									<Modal
										isOpen={this.state.isAlertEscModalOpen}
										onRequestClose={this.handleCloseAlertEscModal}
										contentLabel="Modal"
										portalClassName="modal"
										overlayClassName="modal__overlay"
										className="modal__content"
										bodyOpenClassName="modal--is-open"
										htmlOpenClassName="prevent-scroll"
										shouldCloseOnOverlayClick={true}
										shouldFocusAfterRender={true}
									>
										<AlertEscTicketModal
											onConfirm={this.handleCloseAlertEscModal}
										/>

									</Modal>
								}
							</div>
						</div>
					</div>
				</Fragment>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps, null)(withRouter(TicketItemExpanded));
