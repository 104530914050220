import React, {Component, Fragment} from 'react';

export default class AfterArchiveOrUnarchiveProjectModal extends Component {
	render() {
		if (this.props.project.archived === false) {
			return (
				<span wrapper-name="default">
				<div
					className="message-box dialog-mask">
					<div
						className="dialog-content"><div className="dialog-body"><p>Project was archived!</p>
				</div>
				<footer>
			<button onClick={() => this.props.closeAfterRequest()}
					className="btn outline gainsboro">OK</button>
				</footer>
					</div>
				</div>
			</span>
			)
		} else {
			return (
				<span wrapper-name="default">
					<div
						className="message-box dialog-mask">
						<div
							className="dialog-content">
							<div
								className="dialog-body"><p>Project unarchived!</p>
							</div>
							<footer>
					<button onClick={() => this.props.closeAfterRequest()}
						className="btn outline gainsboro">OK</button>
							</footer>
						</div>
					</div>
				</span>
			)
		}
	}


}
