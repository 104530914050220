import React, {Component, Fragment} from 'react';
import AccountService from "../../services/api/account";
import Cookies from 'universal-cookie';
import {connect} from "react-redux";

import RegisterForm from "./RegisterForm";

class RegisterAccount extends Component {

	constructor(props) {
		super(props);

		// Hold time interval of cheker
		this.checker = null;

		this.state = {
			loading: false,
			cbInstance: window.Chargebee.init({ site: "okapistudio-test" }),
		}
	}

	handleChargebee = (hostedPageDetails) => {
		return this.state.cbInstance.openCheckout({
			hostedPage: function() {
				// Hit your end point that returns hosted page object as response
				// This sample end point will call checkout new api
				// https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
				// If you want to use paypal, go cardless and plaid, pass embed parameter as false
				return hostedPageDetails;
			},
			loaded: function() {
			  console.log("checkout opened");
			},
			error: (data) => { },
			close: () => { },
			success: (hostedPageId) => {
				// Hosted page id will be unique token for the checkout that happened
				// You can pass this hosted page id to your backend
				// and then call our retrieve hosted page api to get subscription details
				// https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page

				// Close chargebee modals
				// Show loading screen
				// Check if tracker was setup and redirect to login screen

				this.state.cbInstance.closeAll();
				this.toggleLoading();

				this.checkSubscription(hostedPageId);
			},
			step: function(value) {}
		});
	}

	toggleLoading = () => {
		this.setState({
			loading: !this.state.loading
		});
	}

	checkSubscription = (hostedPageId) => {
		// Check once per 1,5 seconda if tracker was setup
		AccountService.checkReady(hostedPageId)
			.then(response => {
				this.loginUser(response);
			}).catch(error => {
				setTimeout(() => {
					this.checkSubscription(hostedPageId);
				}, 1500)
			})
	}

	loginUser = (response) => {
		if (response && response.token) {
			const cookies = new Cookies();
			cookies.set('token', response.token, {
				path: '/',
				expires: new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 7)
			});

			this.props.dispatch({
				type: "@@huskytracker/USER_MEMBERSHIPS",
				payload: response.memberships
			});

			this.props.dispatch({
				type: "@@huskytracker/USER_AUTHENTICATED",
				payload: !!cookies.get("token")
			});
		}
	}

	render() {
		return (
			<Fragment>
				<RegisterForm handleChargebee={this.handleChargebee} />
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(RegisterAccount);
