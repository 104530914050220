import React, {Component} from "react";
import { EditorState, Modifier, ContentState} from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import linkifyHtml from 'linkifyjs/html'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, {Quill} from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import AutoLinks from "quill-auto-links";
import ImagePaste from './Quill/ImagePaste';

import UsersService from "../services/api/users";
import UploadService from "../services/api/upload";
Quill.register('modules/autoLinks', AutoLinks)
Quill.register('modules/imagePaste', ImagePaste);
//Quill.register('modules/quillMention', QuillMention)
const mention = {
	defaultMenuOrientation: 'bottom',
	allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
	dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'mentionUserId'],
	mentionDenotationChars: ["@", "#"],
	source: function(searchTerm, renderItem, mentionChar) {
		let values;
		if (mentionChar === "@" || mentionChar === "#") {
			values = users;
		}
		if (searchTerm.length === 0) {
			renderItem(values, searchTerm);
		} else {
			const matches = [];
			for (let i = 0; i < values.length; i++)
				if (
					~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
				)
					matches.push(values[i]);
			renderItem(matches, searchTerm);
		}
	}
}

const onImagePasted = (file) => {
	return UploadService.signResourceAndUpload(
		'Upload',
		Math.random()
			.toString(36)
			.substr(2, 9),
		file,
	)

};

const imagePaste = {

	addImageBlob: function (blob, callback) {
		//
		onImagePasted(blob)
			.then(response => {
				callback(response.url)
			})
			.catch((err) => {
				console.log(err);
			});
		//callback("https://i.picsum.photos/id/581/200/300.jpg")
	}
};
let users = [];


export default class HtmlEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: null,
			content: null
        };

		this.quillRef = null;      // Quill instance
		this.reactQuillRef = null; // ReactQuill component
    }


	attachQuillRefs = () => {
		if (typeof this.reactQuillRef.getEditor !== 'function') return;
		this.quillRef = this.reactQuillRef.getEditor();
	}

	getUsers() {
    	UsersService.getAllUsers({limit: "-1", related: "user_profile.position"})
			.then(res => {
				users = res.data.map(user => {
					return {
						...user,
						...{
							value: user.first_name + ' ' + user.last_name,
							id: user.id,
							"mentionUserId": user.id
						}
				}});
			})
			.catch(err => {
				console.log(err);
			});
	}

	componentDidMount() {
		this.attachQuillRefs()
		this.getUsers()

		if (this.props.html) {
			//this.quillRef.setContents([])
			// initialize the content to exactly what we have in our server (i.e. what we saved the last time as validated/sanitized by our server)
			//this.quillRef.clipboard.dangerouslyPasteHTML(0, this.props.html)
			this.setState({
				content: this.props.html
			})
		}
	}

	getMentions() {
    	let quillContents = this.quillRef.getContents();
    	return quillContents?.ops?.map(op => {
    		if (op?.insert?.mention ?? false) {
    			return op.insert.mention.id
			}
		}).filter(userId => userId);
	}

    clearEditor() {
    	this.setState({
			content: null
		})
	}

    render() {
    	let self = this;
    	const formats = [
			"header",
			"bold",
			"italic",
			"underline",
			"strike",
			"blockquote",
			"list",
			"bullet",
			"indent",
			"link",
			"mention"
		];
    	const modules = {
			mention: mention,
			toolbar: [
				[{ header: [1, 2, false] }],
				["bold", "italic", "underline", "strike", "blockquote"],
				[{ list: "ordered" }, { list: "bullet" }],
				["link"]
			],
			autoLinks: {
				paste: true,
				type: true
			},
		};
        const toolbar = {
            options: ['inline', 'blockType', 'list', 'colorPicker', 'link', 'history'],
            list: {
                options: ['unordered', 'ordered']
            },
            inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']
            },
        }
        return (
			<ReactQuill
				ref={(el) => { this.reactQuillRef = el }}
				modules={modules}
				formats={formats}
				theme="snow"
				value={this.state.content}
				onChange={(html, delta, source) => {
					if (source === 'user') {
						this.setState({content: html})
						this.props.onChange(html);
					}
				}}
			/>
        )
    }
}
