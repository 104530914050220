import React from 'react';
import useForm from 'react-hook-form'
import {Input} from '../inputs/Input';
import {NavLink} from 'react-router-dom';
import {required, isEmailAddress} from "../inputs/validations";
import AuthService from "../services/api/auth";

export default function ResetPasswordForm(props) {
	const {register, handleSubmit, errors, setError, formState} = useForm({
		mode: "onSubmit"
	});

	const onSubmit = data => {
		if(data?.password !== data?.password_confirmation){
			setError("password" , true , "Passwords do not match");
		} else {
			AuthService.resetPassword({...data, reset_password_token: props.match.params.token})
				.then(response => {
					props.history.push('/auth/login');
				})
				.catch(err => {

				})
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="wrapper credentials">
				<div className="f-section">

					<h2 className="f-section__title no-border">Reset your password</h2>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"New Password"} type="password"
								   register={
									   register({
										   validate: {required},
									   })
								   }
								   error={errors.password}
								   name="password"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input placeholder={"Confirm Password"} type="password"
								   register={
									   register({
										   validate: {required},
									   })
								   }
								   error={errors.password_confirmation}
								   name="password_confirmation"/>
						</div>
					</div>

					<div className="f-row is-regular">
						<div className="f-element is-half">
							<NavLink className="f-link" to={"/auth/login"}>&lt; Sign in</NavLink>
						</div>
					</div>

					<div className="f-row btn-container">
						<button 
							// disabled={!formState.isValid || formState.isSubmitting || formState.isSubmitted} 
							type="submit" 
							className="btn fill blue"
						>
							Reset your password
						</button>
					</div>

				</div>
			</div>
		</form>

	)
}
