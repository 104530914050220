import React, {Component, Fragment, useState} from "react";


export default class PillsSelector extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		}
	}


	handleChange(id) {
		let {value} = this.state;
		if(this.props.multi) {
			if(value.find(item => item === id)) {
				value = value.filter(item => item !== id);
			} else {
				value.push(id);
			}
			this.setState({
				value
			})

		} else {
			value = id;
			this.setState({
				value
			})
		}
		this.props.onChange(value);
	}

	render() {
			return (
			<Fragment>
				<div className="f-row pills">
					<div className={"f-element"}>
						{!this.props.grouped ?
							<ul className="s-pills">
								{this.props.options.map(option => {
									return (
										<li key={option.id}>
											<input type="checkbox"
												   name="option"
												   id={`${option.name}-${option.id}`}
												   value={option.id}
												   checked={this.props.multi ? this.state.value.find(item => item === option.id) : this.state.value === option.id}
												   onChange={(e) => this.handleChange(option.id)}
											/>
											<label htmlFor={`${option.name}-${option.id}`}>{option.name}</label>
										</li>);
									})
								}
							</ul>
							:
							this.props.groups.map(parent => {
								return (<div key={parent.id} className="f-section">
									<h3 className="f-section__title">{parent.name}</h3>
									<div className="f-row">
										<ul className="s-pills">
											{this.props.options.map(option => {
													if (parent.id === option.parent_id) {
														return (
															<li key={option.id}>
																<input type="checkbox"
																	   name="skill"
																	   id={`${option.name}-${option.id}`}
																	   value={option.id}
																	   checked={this.props.multi ? this.state.value.find(item => item === option.id) : this.state.value === option.id}
																	   onChange={(e) => this.handleChange(option.id)}
																/>
																<label htmlFor={`${option.name}-${option.id}`}>{option.name}</label>
															</li>
														)
													} else return null;
												})
											}
										</ul>
									</div>
								</div>);
							})
						}

					</div>

				</div>
			</Fragment>
		  );
		}

	}
