import React, {useState,useEffect} from 'react';
import useForm from 'react-hook-form'
import {SelectInput} from '../inputs/SelectInput';
import {Input} from '../inputs/Input';
import {HtmlInput} from "../inputs/HtmlInput";
import ProjectsService from "../services/api/projects";
import SelectUsers from "../components/SelectUsers";
import UsersService from "../services/api/users";
import { values } from 'lodash';

export default function EditProjectForm(props) {
	const { register, handleSubmit, setValue, errors } =  useForm({
		mode: "onChange"
	});

	const [inputs, setInputs] = useState({
		title: props.project.title,
		brief: props.project.brief,
		company: props.project.company.id,
		description: props.project.description,
		owner: props.project.owner.id
	});

	const [dataUsers, setDataUsers] = useState({users: [], isFetching: true});

	const onSubmit = data => {
		data = Object.assign(data , { owner: inputs.owner});

		ProjectsService.editProject(props.project.readable_id, data)
			.then(res => {props.closeModal(props.project.readable_id)})
			.catch(err=>{console.log(err)})

	};

	const handleInputChange = (event, name) => {
		setInputs(inputs => ({...inputs, [name]: event}));
	};

	useEffect(() => {
		UsersService.getAllUsers({limit: -1, filters:{is_archived: false}})
		.then(res => {
			let users = res.data.map(user => {
				return {
					...user,
					...{
						label: user.first_name + ' ' + user.last_name,
						value: user.id,
					}
			}});
			setDataUsers({
				users: users,
				isFetching: false
			});

		}).catch (err => {
			console.log(err);
			setDataUsers({isFetching: false});
		});
	}, [setDataUsers]);


	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={'f-row'}>
				<div className={'f-element'}>
					<Input type="text"
						   defaultValue={inputs.title}
						   setValue={setValue}
						   register={register}
						   placeholder={'Project name'}
						   name="title"
						   id="title" />
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element'}>
					<Input type="text"
						   register={register}
						   defaultValue={inputs.brief}
						   placeholder={'Project brief'}
						   name="brief"
						   id="brief" />
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element'}>
					<SelectInput value={inputs.company}
								 register={register({ required: true })}
								 name={"company"}
								 error={errors.company}
								 options={props.companies}
								 onChange={(event)=> handleInputChange(event, 'company')}/>
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element'}>
					<HtmlInput value={inputs.description}
							   initialValue={props.project.description}
							   register={register}
							   name={"description"}
							   error={errors.description}
							   onChange={(event) => handleInputChange(event, 'description')}/>
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element is-full'}>
					<div className={`ticket-meta`}>
						<div className="ticket-meta-cell ticket-meta-name">Owner</div>
						<div className="ticket-meta-cell ticket-meta-element">
							<input id="owner" name={"owner"} type={'hidden'} ref={register}  />
							{!dataUsers.isFetching &&
							<SelectUsers
								users={dataUsers.users}
								isMultiSelect={false}
								showLabel={true}
								selected={{value: inputs.owner}}
								onChange={(event) => {
									setValue('owner', event);
									handleInputChange(event, 'owner')
								}}
							/>}
						</div>
					</div>
				</div>
			</div>

			<div className="f-row btn-container">
				<button className="btn outline gainsboro" type={'button'} onClick={() => {props.closeModal()}}>Close</button>
				<button className="btn fill blue" type={'submit'}>Save</button>
			</div>

		</form>
	)
}
