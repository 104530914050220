import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import Modal from "react-modal";
import NewCompanyModal from "../Modals/NewCompanyModal";
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';

class SubNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addCompanyModalIsOpen: false,
		}
	}

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head"><h1
							className="page-title">{this.props.title}</h1>

							<div className="btn-wrap page-controls">
								<div className="btn-wrap">
									<PermissibleRender
										userPermissions={this.props.userPermissions}
										requiredPermissions={['can_add_company']}
										oneperm
									>
										<button
											onClick={() => {
												this.setState({
													addCompanyModalIsOpen: true
												})
											}}
											className="btn fill blue edit-company-modal__open">
											Add Company
										</button>
									</PermissibleRender>
								</div>
							</div>
						</div>
						<div className="wrapper wide">
							<ul role="tablist"
								className="navigation-tab-links is-inline is-scrollable">
								<li className={`custom-tab-link ${this.props.status === 'active' ? 'is-active' : ''}`}>
									<button type="button"
											className={`btn link ${this.props.status === 'active' ? 'is-active' : ''}`}
											onClick={() => {
												this.props.changeStatus('status', 'active')
											}}>Active
									</button>
								</li>
								<li className={`custom-tab-link ${this.props.status === 'archived' ? 'is-active' : ''}`}>
									<button type="button"
											className={`btn link ${this.props.status === 'archived' ? 'is-active' : ''}`}
											onClick={() => {
												this.props.changeStatus('status', 'archived')
											}}>Archived
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<Modal
					isOpen={this.state.addCompanyModalIsOpen}
					onRequestClose={() => {
						this.setState({addCompanyModalIsOpen: false})
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<NewCompanyModal closeModal={(data) => {
						this.setState({
							addCompanyModalIsOpen: false
						}, () => {
							if (data) {
								this.props.refreshCompanies()
							}
						})
					}}/>
				</Modal>

			</Fragment>

		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
