import React from 'react';
import {NavLink} from 'react-router-dom';
import Avatar from '../../../components/Avatar';

export const ProjectItem = (props) => {
	
	const {project: {id, company, owner_id , readable_id, title, brief, owner, statuses}} = props;

	return (
		<div key={id} className="morph-grid__item project-item">
			<div className="project-item__group alpha-beta-gamma">
				<div className="project-item__cell alpha">
					<NavLink
						to="/"
						className="project-item__company"
					>
						{company.name}
					</NavLink>
				</div>
				<div className="project-item__cell beta">
					<NavLink
						to={`/projects/${readable_id}`}
						className="project-item__title"
					>
						{title}
					</NavLink>
				</div>
				<div className="project-item__cell gamma">
					<div className="project-item__brief">
						{brief}
					</div>
				</div>
			</div>
			<div className="project-item__group epsilon-zeta">
				<div className="project-item__cell epsilon">
					<div className="project-item__owner">
						<NavLink to={`/people/${owner_id}`}>
							<Avatar
								width={32}
								height={32}
								altText={`${owner.first_name} ${owner.last_name}`}
								userId={owner.id}
								hasAvatar={owner.has_avatar}
							/>
						</NavLink>
					</div>
				</div>
				<div className="project-item__cell zeta">
					<ul className="project-item__ticket-list">
						{statuses.map(status =>
							<li
								key={status.id}
								className="ticket-list__ticket-item"
								style={{color: `${status.color}`}}
							>
								{status.count}
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};
