import React, {Component, Fragment} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from 'react-modal';
import SearchModal from '../Modals/SearchModal';
import Notifications from '../shared/Notifications';
import {logoutUser} from '../Auth/actions/auth';
import UsersService from '../../services/api/users';
import {populateUser} from './actions';
import {isMobile} from 'react-device-detect';
import Avatar from '../../components/Avatar';
import {PermissibleRender} from '@brainhubeu/react-permissible';

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchModalIsOpen: false,
			profileDropdownIsOpen: false,
			notificationDropdownIsOpen: false,
			stickyHeader: false,
			user: null
		};
	}

	componentDidMount() {
		Modal.setAppElement('body');
		if (this.props.isAuthenticated) {
			this.getCurrentUser();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.location !== this.props.location && this.state.profileDropdownIsOpen) {
			this.setState({
				profileDropdownIsOpen: false
			});
		}
		if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
			this.getCurrentUser();
		}
	}


	getCurrentUser() {
		UsersService.getCurrentUser()
			.then(user => {
				this.props.populateUser(user);
			})
			.catch(err => {
				console.error(err);
			});
	}

	closeModal() {
		this.setState({
			searchModalIsOpen: false
		});
	}

	handleSpanClick = () => {
		this.setState({mobileMenuIsOpen: !this.state.mobileMenuIsOpen});
	};

	handlePrimaryMenuClick = () => {
		this.setState({mobileMenuIsOpen: false});
	};

	handleLogoutClick = () => {
		this.props.logoutUser();
	};

	handleCloseModal = () => {
		this.closeModal();
	};

	handleDropdownClick = () => {
		this.setState({profileDropdownIsOpen: !this.state.profileDropdownIsOpen});
	};

	handleDropdownClose = () => {
		this.setState({profileDropdownIsOpen: false});
	};

	handleSearchClick = () => {
		this.setState({searchModalIsOpen: true});
	};

	render() {
		if (!this.props.isAuthenticated) return null;
		return (
			<Fragment>
				<header>
					<nav
						className={`navigation ${isMobile ? 'is-mobile' : ''}`}>
						<div className="wrapper wide">
							<div className="primary-menu__container">
								<span
									onClick={this.handleSpanClick}
									className={`mobile-menu__trigger ${this.state.mobileMenuIsOpen ? 'is-active' : ''}`}
								>
								<span/> <span/> <span/>
								</span>
								<div
									className="primary-menu"
									onClick={this.handlePrimaryMenuClick}
								>
									<ul>
										<li>
											<NavLink to={'/dashboard'}>Dashboard</NavLink>
										</li>
										<li>
											<NavLink to={'/projects'}>Projects</NavLink>
										</li>
										<li>
											<NavLink to={'/tickets'}>Tickets</NavLink>
										</li>
										<PermissibleRender
											userPermissions={this.props.userPermissions}
											requiredPermissions={['can_view_people_page']}
											oneperm
										>
											<li>
												<NavLink to={'/people'}>People</NavLink>
											</li>
										</PermissibleRender>
										<PermissibleRender
											userPermissions={this.props.userPermissions}
											requiredPermissions={['can_view_companies_page']}
											oneperm
										>
											<li>
												<NavLink to={'/companies'}>Companies</NavLink>
											</li>
										</PermissibleRender>
										<PermissibleRender
											userPermissions={this.props.userPermissions}
											requiredPermissions={['can_change_settings']}
											oneperm
										>
											<li>
												<NavLink to={'/settings'}>Settings</NavLink>
											</li>
										</PermissibleRender>
										<PermissibleRender
											userPermissions={this.props.userPermissions}
											requiredPermissions={['can_view_reports_page']}
											oneperm>
											<li>
												<NavLink to={'/reports'}>Reports</NavLink>
											</li>
										</PermissibleRender>
									</ul>
								</div>
							</div>

							<div className="current-company__container">
								<NavLink
									to={'/dashboard'}
									className="current-company"
								>
									<span
										className="current-company__logo"
										style={{display: 'none'}}
									/>
									<span
										className="current-company__symbol"
										style={{
											backgroundColor: 'rgb(237, 99, 71)',
											color: 'rgb(255, 255, 255)'
										}}>o</span>
									<span
										className="current-company__name"
									>
										Okapi Studio (beta)
									</span>
								</NavLink>
							</div>

							{this.props.user && this.props.user.user_profile && this.props.user.user_profile.role.name !== 'client' &&
							<div className="nav-search__container">
								<button
									onClick={this.handleSearchClick}
									className="far-icon circle outline gray hover">
									<i className="far  fa-search"/>
								</button>
							</div>}
							<Notifications/>

							<div className="nav-profile__container">
								<div className="dropdown">
									{this.state.profileDropdownIsOpen &&
									<div
										className="dropdown-close"
										onClick={this.handleDropdownClose}
									/>
									}
									<div
										role="button"
										className="dropdown__trigger"
										onClick={this.handleDropdownClick}
									>
										<span>
										<Avatar
											className="logged-in__avatar"
											width={36}
											height={36}
											altText={`${this.props.user.first_name} ${this.props.user.last_name}`}
											userId={this.props.user.id}
											hasAvatar={this.props.user.has_avatar}
										/>
										</span>
									</div>
									{this.state.profileDropdownIsOpen &&
									<div
										className="dropdown__content small top-right">
										<div className="dropdown-content">
											<div
												className="context-menu arrow top right">
												<ul className="context-menu-list">
													<li>
														<NavLink to={`/my/info`}>
															<i className="material-icons">person</i>
															My Profile
														</NavLink>
													</li>
													<li>
														<button onClick={this.handleLogoutClick}>
															<i className="material-icons">lock_open</i>
															Log Out
														</button>
													</li>
												</ul>
											</div>
										</div>
									</div>}
								</div>
							</div>
						</div>
					</nav>
				</header>
				<Modal
					isOpen={this.state.searchModalIsOpen}
					onRequestClose={this.handleCloseModal}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<SearchModal closeModal={this.handleCloseModal}/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		userPermissions: state.user.permissions,
		isAuthenticated: state.auth.isAuthenticated
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({logoutUser, populateUser}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
