import request from '../shared/request';

export default class UsersService {

	static populateHasAvatarField() {
		return request({
			url: `/users/populate`,
			method: 'GET',
		})
	}

	static getProfile(id, params) {
		return request({
			url: `/users/${id}`,
			method: 'GET',
			params
		})
	}

	static getCurrentUser() {
		return request({
			url: '/users/currentUser',
			method: 'GET'
		})
	}

	static getCurrentUserNotifications(params) {
		return request({
			url: '/notifications',
			params,
			method: 'GET'
		})
	}

	static markNotificationsAsRead() {
		return request({
			url: '/notifications/clear_all',
			method: 'GET'
		})
	}

	static getUserNotificationsCount() {
		return request({
			url: '/notifications/count',
			method: 'GET'
		})
	}

	static getAllUsers(params) {
		return request({
			url: '/users/',
			method: 'GET',
			params
		})
	}

	static inviteUser(data) {
		return request({
			url: '/users/invite',
			method: 'POST',
			data
		})
	}

	static searchUsers(params) {
		return request({
			url: '/users/search',
			method: 'GET',
			params
		})
	}

	static getUserTickets(id, params) {
		return request({
			url: `/users/${id}/tickets`,
			method: 'GET',
			params
		})
	}

	static getUserActivity(id, params) {
		return request({
			url: `/users/${id}/activity`,
			method: 'GET',
			params
		})
	}

	static getUsersForProject(params) {
		return request({
			url: `/users/list`,
			method: 'GET',
			params
		})
	}

	static editUser(id,data) {
		return request({
			url: `/users/${id}`,
			method: 'PUT',
			data
		})
	}

	static saveUserPreferences(id, data) {
		return request({
			url: `/users/${id}/preferences`,
			method: 'PUT',
			data
		})
	}

	static getActiveProjects(user_id) {
		return request({
			url: `/users/${user_id}/activeProjects`,
			method: 'GET',
		})
	}
}
