import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ProjectsService from '../../../../services/api/projects';
import {PermissibleRender} from '@brainhubeu/react-permissible';
import CustomSelect from '../../../../components/CustomSelect';
import TicketSummaryPlaceholder
	from '../../../../placeholders/TicketSummaryPlaceholder';
import {NavLink} from 'react-router-dom';
import DueDate from '../../../../components/DueDate';
import AttachmentsService from '../../../../services/api/attachments';
import {
	canRenderPreview,
	getFontAwesomeIconFromMIME
} from '../../../../components/helpers/preview';

const ProjectFiles = ({project: {id, readable_id, title}, userPermissions}) => {
	const [ticketsFiles, setTicketsFiles] = useState([]);
	const [quantityFilter, setQuantityFilter] = useState(quantityOptions[0]);
	const [ageFilter, setAgeFilter] = useState(ageOptions[0]);
	const [loading, setLoading] = useState(true);

	const handleDownloadAll = async () => {
		try {
			const {url} = await AttachmentsService.downloadResource(`upload/Project/${id}`, id);
			const fetchRes = await fetch(url);
			const blobRes = await fetchRes.blob();
			const archiveName = `${title}-Files.zip`;
			createAndClickLink(blobRes, archiveName);

			function createAndClickLink(blob, archiveName) {
				const a = document.createElement('a');
				a.href = URL.createObjectURL(blob);
				a.setAttribute('download', archiveName);
				a.click();
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleFilter = (setState, value) => setState(value);
	const fetchFiles = useCallback(async () => {
		try {
			const {ticketsFiles} = await ProjectsService.getAttachments(id);
			setTicketsFiles(ticketsFiles);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}, [id]);
	useEffect(() => {
		fetchFiles();
	}, []);
	return (
		<>
			<div className="secondary-bottom">
				<div className="wrapper">
					<ul className="filter-container">
						<CustomSelect
							name="quantity"
							className="filter"
							value={quantityFilter}
							onChange={(value) => handleFilter(setQuantityFilter, value)}
							options={quantityOptions}
						/>
						<CustomSelect
							name="age"
							className="filter"
							value={ageFilter}
							onChange={(value) => handleFilter(setAgeFilter, value)}
							options={ageOptions}
						/>
						<button onClick={() => handleDownloadAll()}>
							Download all
						</button>
					</ul>
				</div>
			</div>
			<section className="page">
				<div className="wrapper">
					<PermissibleRender
						userPermissions={userPermissions}
						requiredPermissions={['can_view_project_files']}
						oneperm
					>
						{loading ?
							<div className="inner-container">
								<div
									className={`morph-grid__container list-view mod-margin`}>
									<TicketSummaryPlaceholder items={10}/>
								</div>
							</div>
							:
							<>
								<div className="list-container">
									{ticketsFiles.length ?
										<div className="inner-container">
											<div
												className={`morph-grid__container list-view mod-margin`}>
												{ticketsFiles.map((file, index) =>
													<FileItem
														key={index}
														file={file}
														projectId={readable_id}
													/>)}
											</div>
										</div>
										:
										<div className="activity-container">
											<h4>There are no files in this
												criteria</h4>
										</div>
									}
								</div>
							</>
						}
					</PermissibleRender>
				</div>
			</section>
		</>
	);
};

const FileItem = ({file: {id, file_file_name, title, resource_id, url, created_at}, projectId}) => {
	const canPreview = canRenderPreview(url);
	return <div
		key={id}
		className="morph-grid__item morph-ticket-item"
		style={{alignItems: 'center'}}
	>
		<div className="morph-ticket-item__group alpha-beta"
			 style={{
				 width: 'auto',
				 maxWidth: '50%'
			 }}>
			<div className="morph-ticket-item__cell alpha">
				<div className="preview">
					<div className="preview_cell">
						<a target={'_blank'} href={url}>
							{canPreview ?
								<img
									src={url}
									alt={'preview'}
									className={'preview_image'}
								/> :
								<i
									className={`fa ${getFontAwesomeIconFromMIME(file_file_name)} fa-4`}
									aria-hidden="true"
								/>
							}
						</a>
					</div>
				</div>
			</div>
			<div className="morph-ticket-item__cell beta">
				<NavLink
					to={`/projects/${projectId}/tickets/${resource_id}`}
					className="ticket-tiem__title"
					style={{textAlign: 'initial'}}>
					{file_file_name}
				</NavLink>
			</div>
		</div>
		<div
			className="morph-ticket-item__group gamma-delta-epsilon">
			<div className="morph-ticket-item__cell gamma">
				<NavLink
					to={`/projects/${projectId}/tickets/${resource_id}`}
					className="morph-ticket-item__project">
					{title || 'Comment'}
				</NavLink>
			</div>
		</div>
		<div className="morph-ticket-item__group gamma-delta-epsilon">
			<div className="morph-ticket-item__cell delta">
				<div
					className="morph-ticket-item__due-date">
					<div className="dropdown">
						<DueDate
							value={created_at && new Date(created_at)}
							minDate={new Date()}
							disabled
						/>
					</div>
				</div>
			</div>
		</div>
	</div>;
};

const quantityOptions = [
	{
		label: 'All files',
		value: 'all'
	},
	{
		label: 'Project files',
		value: 'project'
	},
	{
		label: 'Ticket files',
		value: 'ticket'
	}
];
const ageOptions = [
	{
		label: 'Newest first',
		value: 'newest'
	},
	{
		label: 'Oldest first',
		value: 'oldest'
	}
];

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps, null)(ProjectFiles);
