import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {history} from '../../store';
import Avatar from '../Avatar';
import {canRenderPreview, getFontAwesomeIconFromMIME} from '../helpers/preview';

export default class Activity extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {

		let activity = this.props.activity;
		if (!activity) {
			return null;
		}
		if (activity.type === 'create_ticket') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-plus-square"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"
							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36} height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> created the ticket
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>
							in the project
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'assign' && this.props.user.id === activity.meta.new.id) {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-user-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36} height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> self assigned
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'assign') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-user-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36} height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> assigned
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>
							to <a> {`${activity.meta.new.first_name} ${activity.meta.new.last_name}`} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'change_status') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-flag"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> change status
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a> from <strong
								style={{color: `${activity.meta.old.color}`}}> {activity.meta.old.name} </strong>
							to <strong
								style={{color: `${activity.meta.new.color}`}}> {activity.meta.new.name} </strong>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'change_due_date') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-calendar-edit"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"
							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed the due date
							of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>
							{activity.meta.old && activity.meta.old.length ?
								<span>from
								<strong> {moment(activity.meta.old).format('Do MMMM YYYY')} </strong>
							</span> : null}
							to
							<strong> {moment(activity.meta.new).format('Do MMMM YYYY')}</strong>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'change_description' && activity.targetable_type === 'Ticket') {
			const {meta} = activity;
			const formattedContent = formatContent(meta.new);
			return (
				<div className="act__item report change-description">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed the description of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a> to:
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
					<div className="item__body">
						<span
						dangerouslySetInnerHTML={{__html: formattedContent}}/>
					</div>
				</div>
			);
		} else if (activity.type === 'change_description' && activity.targetable_type === 'Project') {
			const {meta} = activity;
			const formattedContent = formatContent(meta.new);
			return (
				<div className="act__item report change-description">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed the description of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a>to:
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
					<div className="item__body">
						<span
						dangerouslySetInnerHTML={{__html: formattedContent}}/>
					</div>
				</div>
			);
		} else if (activity.type === 'change_brief') {
			return (
				<div className="act__item report change-description">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far  fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									idth={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed brief of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a> to:
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
					<div className="item__body">
						<span> {activity.meta.new} </span>
					</div>
				</div>
			);
		} else if (activity.type === 'add_followers' && activity.targetable_type === 'Ticket') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-user-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div
							className="act__action"> added {activity.meta.map(el => {
							return <Fragment key={el.id}>
								<a
									className='profile__link'

									onClick={() => {
										history.push(`/people/${el.id}`);
									}}>
									<strong>{`${el.first_name} ${el.last_name} `}</strong>
								</a></Fragment>;
						})} to
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'add_followers' && activity.targetable_type === 'Project') {
		
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-user-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link" onClick={() => history.push(`/people/${activity.owner.id}`)}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity?.owner?.first_name} ${activity?.owner?.last_name}`}
									userId={activity?.owner?.id}
									hasAvatar={activity?.owner?.has_avatar}
								/>
								{`${activity?.owner?.first_name} ${activity?.owner?.last_name}`}
							</a>
						</div>
						<div
							className="act__action"> added {activity.meta.map(el => {
							return <Fragment key={el.id}>
								<a
									className='profile__link'

									onClick={() => {
										history.push(`/people/${el.id}`);
									}}>
									<strong>{`${el.first_name} ${el.last_name} `}</strong>
								</a></Fragment>;
						})} to
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets`);
								}}> {this.props.project.title} </a>

						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'remove_followers' && activity.targetable_type === 'Project') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-user-minus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div
							className="act__action"> removed {activity.meta.map(el => {
							return <a key={el.id}
									  className="profile__link"
									  onClick={() => {
										  history.push(`/people/${el.id}`);
									  }}>
								<strong>{`${el.first_name} ${el.last_name} `}</strong>
							</a>;
						})} from
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets`);
								}}> {this.props.project.title} </a>

						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'remove_followers' && activity.targetable_type === 'Ticket') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-user-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div
							className="act__action"> added {activity.meta.map(el => {
							return <Fragment key={el.id}>
								<a
									className='profile__link'

									onClick={() => {
										history.push(`/people/${el.id}`);
									}}>
									<strong>{`${el.first_name} ${el.last_name} `}</strong>
								</a></Fragment>;
						})} to
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>

						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'change_title') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-pen"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> changed the title of
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}>  {activity.meta['new'].length && activity.meta['old']} </a>
							to
							<strong> {activity.meta['new']}</strong>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else if (activity.type === 'comment') {
			const {meta} = activity;
			const content = meta.content || meta;
			const formattedContent = formatContent(content);
			return (
				<div className="act__item comment">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-comment-alt"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> posted a comment in
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}/tickets/${this.props.tickets.id}`);
								}}> {this.props.tickets.title} </a>

						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
					<div className="item__body">
						<br/>
						<span dangerouslySetInnerHTML={{__html: formattedContent}}/>
						{(activity?.meta?.attachmentsURLs?.length > 0 ?? false) &&
						<div className="file-upload">
							<div className="uploader-files">
								{activity?.meta?.attachmentsURLs?.map(attachment => {
									return <div className="uploader-file">
										<div className="file-header">
											<div className="file-avatar">
												{canRenderPreview(attachment?.filename ?? '') ?
													<img src={attachment.url}
														 className={'act-add-attach__item-preview'}/> :
													<i className={`fa ${getFontAwesomeIconFromMIME(attachment.filename ?? '')} fa-4`}
													   aria-hidden="true"></i>}
											</div>

										</div>
										<div className="file-details">
											<a className="file-name"
											   target="_blank"
											   href={attachment.url}>
												{attachment.filename ?? ''}
											</a>
										</div>
									</div>;
								})}
							</div>
						</div>}
					</div>
				</div>
			);
		} else if (activity.type === 'create_project') {
			return (
				<div className="act__item report">
					<div className="item__head">
						<div className="act__type far-icon circle fill gray">
							<i className="far fa-layer-plus"></i>
						</div>
						<div className="act__owner">
							<a className="profile__link"

							   onClick={() => {
								   history.push(`/people/${activity.owner.id}`);
							   }}>
								<Avatar
									width={36}
									height={36}
									altText={`${activity.owner.first_name} ${activity.owner.last_name}`}
									userId={activity.owner.id}
									hasAvatar={activity.owner.has_avatar}
								/>
								{`${activity.owner.first_name} ${activity.owner.last_name}`}
							</a>
						</div>
						<div className="act__action"> created the project
							<a
								onClick={() => {
									history.push(`/projects/${this.props.project.readable_id}`);
								}}> {this.props.project.title} </a>
						</div>
						<div className="act__timestamp">
							{moment(activity.created_at).format('h:mm a')}
						</div>
					</div>
				</div>
			);
		} else
			return null;
	}
}

const formatContent = (content) => {
	//Replace <br> tags with white spaces
	let formattedContent = content.replace(/<br\s*\/?>/gi,' ');
	//Remove all tags
	formattedContent = formattedContent.replace(/(<([^>]+)>)/gi, "");
	const {length} = formattedContent;
	return length > 300 ? `${formattedContent.slice(0,300)}...` : formattedContent;
};
