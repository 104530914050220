import React, {Component, Fragment} from 'react';
import Comments from "./Comments";
import Activity from "./Activity";
import Files from "./Files";
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';

class TicketDetails extends Component {

	constructor(props) {
		super(props);
		this.state = {
			menuState: 'comments'
		};
	}

	menuState = (whichComponent) => {
		if (this.state.menuState != whichComponent) {
			this.setState({menuState: whichComponent});
		}
	};


	render() {
		return(
			<Fragment>
				<div className="tabs-component">
					<ul role="tablist"
						className="custom-tab-links is-inline is-scrollable">
						<li role="presentation"
							className="custom-tab-link is-active">
							<a className={((this.state.menuState == 'comments') ? 'is-active' : null)} onClick={() => this.setState({menuState: 'comments'})}>Comments</a>
						</li>
						<li role="presentation"
							className="custom-tab-link">
							<a className={((this.state.menuState == 'activity') ? 'is-active' : null)} onClick={() => this.setState({menuState: 'activity'})}>Activity</a>
						</li>
						<PermissibleRender
							userPermissions={this.props.userPermissions}
							requiredPermissions={['can_view_ticket_files']}
							oneperm
						>
							<li role="presentation"
								className="custom-tab-link">
								<a className={((this.state.menuState == 'files') ? 'is-active' : null)} onClick={() => this.setState({menuState: 'files'})}>Files</a>
							</li>
						</PermissibleRender>
					</ul>
				</div>
				{
					(this.state.menuState === 'comments') ?
						!this.props.ticket_id ? null :
						<Comments
							ticket_id={this.props.ticket_id}
						/>

						:
					(this.state.menuState === 'activity') ?
						<Activity
							ticket_id={this.props.ticket_id}
						/>
						:
					(this.state.menuState === 'files') ?
						<Files
							resource='Ticket'
							resource_id={this.props.ticket_id}/>
						: null
				}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(TicketDetails);
