import React, {Component, Fragment} from 'react';
import UsersService from "../../services/api/users";
import ProjectsService from "../../services/api/projects";
import TeamMembers from "../../components/ProjectFollowersTeamMembers";
import ClientsMembers from "../../components/ProjectFollowersClientsMembers";

export default class ProjectFollowersModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			membersType: 'team_members',
			team_members: [],
			clients: [],
			searchedFollowers: [],
			followers: []
		}

		this.searchFollowers = this.searchFollowers.bind(this);
	}

	editFollowers = async (user, action, type) => {
		let searchedFollowers = [...this.state.searchedFollowers];
		if (action === 'add') {
			let team = [...this.state[type], user];

			await this.setState({
				searchedFollowers: searchedFollowers.filter(item => item.id !== user.id),
				[type]: team
			});
		} else if (action === 'delete') {
			let team = [...this.state[type]];

			await this.setState({
				[type]: team.filter(item => item.id !== user.id)
			})
		}
		let followers = [...this.state.clients, ...this.state.team_members].map(item => item.id);
		await this.editProject(this.props.project.readable_id, followers);
		this.props.refreshFollowers([...this.state.clients, ...this.state.team_members])

	};

	editProject = (slug, followers) => {
		let data = {followers: followers};
		ProjectsService.editProject(slug, data).then(res => console.log(res));
	};


	sortFollowersByRole = () => {
		let clients = [];
		let team_members = [];
		let projectFollowers = [...this.props.projectFollowers];
		projectFollowers.map(follower => {
			if(follower.role_name === 'client') {
				clients.push(follower);
			} else {
				team_members.push(follower);
			}
		});
		this.setState({
			team_members: team_members,
			clients: clients,
		});

	};

	searchFollowers = (query, type) => {
		UsersService.getUsersForProject({
			query: query,
			type: type
		}).then(res => {
			this.setState({
				searchedFollowers: res.filter(item => ![...this.state.team_members, ...this.state.clients].some(el => el.id === item.id)).map(item => {
					return {
						id: item.id,
						label: item.first_name + ' ' + item.last_name,
						small_avatar: "/avatar_0000.jpeg",
						role_name: item.user_profile.role.name
					}
				})
			});
		});
	};

	componentDidMount() {
		this.sortFollowersByRole();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.projectFollowers !== this.props.projectFollowers) {
			this.sortFollowersByRole()
		}
	}

	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container"><button onClick={()=>{this.props.closeModal()}} className="e-modal__close"></button>
						<div name="search-page" className={'entity-select'}>
							<div className="e-modal__head">
								<h2 className="e-modal__title">People on project</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<div className="tabs-component">
										<ul className="custom-tab-links is-full bottom-margin">
											<li className={`custom-tab-link ${this.state.membersType === "team_members" ? 'is-active' : ''}`}>
												<button type={"button"} className={`btn link ${this.state.membersType === "team_members" ? 'is-active' : ''}`} onClick={() => {this.setState({membersType: 'team_members'})}}>Team members ({this.state.team_members.length})</button>
											</li>
											<li className={`custom-tab-link ${this.state.membersType === "clients" ? 'is-active' : ''}`}>
												<button type={"button"} className={`btn link ${this.state.membersType === "clients" ? 'is-active' : ''}`} onClick={() => {this.setState({membersType: 'clients'})}}>Clients ({this.state.clients.length})</button>
											</li>
										</ul>
										<div className="custom-tab-container">
											{this.state.membersType === 'team_members' &&
											<TeamMembers
												followers={this.state.team_members}
												search={this.searchFollowers}
												searchedFollowers={this.state.searchedFollowers}
												editFollowers={this.editFollowers}/>}
											{this.state.membersType === 'clients' &&
											<ClientsMembers
												followers={this.state.clients}
												search={this.searchFollowers}
												searchedFollowers={this.state.searchedFollowers}
												editFollowers={this.editFollowers}/>}
										</div>
									</div>

									<div className="f-row btn-container">
										<button type="button" className="btn outline gainsboro" onClick={()=>{this.props.closeModal()}}>Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
