import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import Modal from "react-modal";
import NewPeopleModal from "../Modals/NewPeopleModal";
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';

class SubNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfo: false,
			newPeopleModalIsOpen: false,
		}
	}

	componentDidMount() {
		Modal.setAppElement('body');
	}

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
					<div className="page__head">
							<h1 className="page-title">{this.props.title}</h1>
							<div className="btn-wrap page-controls">
								<PermissibleRender
									userPermissions={this.props.userPermissions}
									requiredPermissions={['can_invite_users']}
									oneperm
								>
									<button className="btn fill blue round"
											onClick={() => this.setState({newPeopleModalIsOpen: true})}
									>Invite Person</button>
								</PermissibleRender>

							</div>
						</div>
						<ul 
						role="tablist"
						className="navigation-tab-links is-inline is-scrollable">
							<li className={`custom-tab-link ${!this.props.status  ? 'is-active' : ''}`}>
								<button type="button"
									className={`btn link ${!this.props.status  ? 'is-active' : ''}`}
									onClick={() => {
									this.props.changeStatus('is_archived', false)
									}}>All
								</button>
							</li>
							<li className={`custom-tab-link ${this.props.status ? 'is-active' : ''}`}>
								<button type="button"
									className={`btn link ${this.props.status ? 'is-active' : ''}`}
									onClick={() => {
									this.props.changeStatus('is_archived', true)
									}}>Archived
								</button>
							</li>
						</ul>
						{/* <div className="page__head">
							<h1 className="page-title">People</h1>
							<div className="btn-wrap page-controls">
								<PermissibleRender
									userPermissions={this.props.userPermissions}
									requiredPermissions={['can_invite_users']}
									oneperm
								>
									<button className="btn fill blue round"
											onClick={() => this.setState({newPeopleModalIsOpen: true})}
									>Invite Person</button>
								</PermissibleRender>

							</div>
						</div> */}
					</div>
				</div>

				<Modal
					isOpen={this.state.newPeopleModalIsOpen}
					onRequestClose={()=>{this.setState({newPeopleModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<NewPeopleModal closeModal={()=>{this.setState({newPeopleModalIsOpen: false})}}/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	console.log(state.user)
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
