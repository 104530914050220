import React, { Component, Fragment } from 'react'
import DashboardService from '../../services/api/dasboard'
import moment from 'moment'
import ActivityEntry from '../../components/TicketDetails/ActivityEntry'
import Pagination from '../../components/Pagination'
import UsersService from '../../services/api/users'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'

const backgroundImage = require('./../../assets/images/background_cleaning.png')

const getCategoryFromDate = (date) => {
	return moment(date).format('MMMM D, YYYY')
}

class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			limit: 30,
			offset: 0,
			pages: 1,
			activities: {},
			user: {}
		}

	}

	componentDidMount() {
		this.getActivities()
		this.getUserStats()
	}

	getUserStats() {
		UsersService.getActiveProjects(this.props.user.id)
			.then(response => {
				console.log(response);
				this.setState({
					active_user_tickets: response.active_user_tickets,
					owner_user_tickets: response.owner_user_tickets,
					following_user_tickets: response.following_user_tickets,
					active_user_projects: response.active_user_projects
				})
			})
			.catch(err => {

			})
	}

	getActivities = () => {
		let activitiesCategory = {}
		this.setState({
			loading: true
		})
		DashboardService.getDashboardActivities({
			limit: this.state.limit,
			offset: this.state.offset
		})
			.then(response => {
				let activities = response.activities
				activities.filter((activity) => activity.owner).forEach((activity) => {
					const category = getCategoryFromDate(activity.created_at)
					if (!activitiesCategory[category]) {
						activitiesCategory[category] = []
					}
					activitiesCategory[category].push(activity)
				})
				this.setState({
					activities: activitiesCategory,
					pages: response.pages,
					offset: response.offset,
					loading: false
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	changePage = async page => {
		if (page >= 1 && page <= this.state.pages) {
			await this.setState({
				offset: (page - 1) * this.state.limit
			})
			this.getActivities()
		}
	}

	refreshActivities() {
		this.setState({
			loading: true,
			limit: 30,
			pages: 1,
			offset: 0
		}, () => this.getActivities())
	}

	navigateTo = (filters, type = 'tickets') => () => {
		this.props.history.push(`/${type}?${filters}`)
	}

	render() {
		const DateNow = getCategoryFromDate(new Date())
		const {
			active_user_tickets,
			owner_user_tickets,
			following_user_tickets,
			loading,
			activities
		} = this.state

		const activeTickets = (active_user_tickets + owner_user_tickets + following_user_tickets) || 0

		return (
			<Fragment>
				<div className='wrapper'>
					<div className='activity-summary' style={{
						backgroundImage: `url(${backgroundImage})`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'right',
						backgroundSize: 'contain'
					}}>
						<p>
							<strong>{this.props.user?.first_name}</strong>
							, take 10 minutes to cleanup your list of tickets.
						</p>
						<p>
							Please add comments, change the status, edit the
							delivery date and add any materials that are
							relevant.

							Thank you! &#128522;
						</p>
						<p style={{ textAlign: 'center' }}>
							<button
								onClick={this.navigateTo('filter.status=active&filter.filter=assigned&filter.group_by=project')}
								className='btn fill blue round'
								style={{ textAlign: 'center' }}
							>
								Show me the tickets &#128526;
							</button>
						</p>
					</div>
					<div className='activity-summary'>
						<p>
							Hello {' '}
							<strong>{this.props.user?.first_name}</strong>,
							you are currently working on {' '}
							<UnderlineStrong
								onClick={this.navigateTo('filter.order_type=asc&filter.status=active', 'projects')}
							>
								{this.state.active_user_projects} projects
							</UnderlineStrong>
							{' '} and you have {' '}
							<strong>{activeTickets}</strong>
							{' '} active tickets (
							{
								active_user_tickets !== null &&
								<UnderlineStrong
									onClick={this.navigateTo('filter.status=active&filter.filter=assigned')}
								>
									{active_user_tickets} assigned to you,
								</UnderlineStrong>
							}
							{' '}
							{
								owner_user_tickets !== null &&
								<UnderlineStrong
									onClick={this.navigateTo('filter.status=active&filter.filter=owned')}
								>
									{owner_user_tickets} created by you,
								</UnderlineStrong>
							}
							{' '}
							{
								following_user_tickets !== null &&
								<UnderlineStrong
									onClick={this.navigateTo('filter.status=active&filter.filter=following')}
								>
									{following_user_tickets} that you follow
								</UnderlineStrong>
							}
							).
						</p>
					</div>
					<div className='act__container box-pad-white'>
						{
							loading ? (
								<div className='loader__container'>
									<div className='loader' />
								</div>
							) : (
								<div className='act__group'>
									{
										Object.keys(activities).map((category) =>
											<div
												key={category}
												className='act__timeframe'>
												<div className='act__date'
												>
													{category === DateNow ? 'Today' : `${category}`}
												</div>
												{
													activities[category].map((activity) => {
														let project
														if (activity.tickets.project) {
															project = activity.tickets.project
														} else if (activity.project) {
															project = activity.project
														} else {
															project = {}
														}
														return (
															<ActivityEntry
																key={activity.id}
																user={this.props.user}
																activity={activity}
																tickets={activity.tickets}
																project={project}
															/>
														)
													})
												}
											</div>
										)
									}
								</div>
							)
						}
					</div>
				</div>
				<Pagination
					pages={this.state.pages}
					changePage={(page) => this.changePage(page)}
				/>
			</Fragment>
		)
	}
}

const UnderlineStrong = styled.strong`
	text-decoration: underline;
	cursor: pointer;

`

function mapStateToProps(state) {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps, null)(withRouter(Dashboard))
