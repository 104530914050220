import React, {Component, Fragment} from 'react';
import CustomStatusSelect from '../CustomStatusSelect';
import {NavLink,withRouter} from 'react-router-dom';
import DueDate from '../../components/DueDate';
import TicketsService from '../../services/api/tickets';

import TicketItemExpanded from './TicketItemExpanded';
import SelectUsers from '../SelectUsers';


class TicketItemList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ticketModalIsOpen: false
		};
	}

	updateTicket(label, value) {
		TicketsService.updateTicket(this.props.ticket.id, {[label]: value}, null)
			.then(res => {
				this.props.onUpdate();
			})
			.catch(err => {
				console.log(err);
			});
	}

	onModalClose() {
		if (this.props.isProjectList) {
			window.history.replaceState(null, null, `/projects/${this.props.project.readable_id}/tickets/`);
		} else if (this.props.screen === 'Tickets'){
			window.history.replaceState(null, null, `/tickets/`);
		} else {
			this.props.history.push(this.props.location.pathname);
		}
	}

	render() {
		let {ticket} = this.props;
		if (!ticket) return null;
		let followers = [];

		if (this.props.project) {
			followers = this.props.project.followers.map(follower => {
				return {
					value: follower.user.id,
					id: follower.user.id,
					label: follower.user.first_name + ' ' + follower.user.last_name,
					avatarUrl: '/avatar_0000.jpeg',
					has_avatar: follower.user.has_avatar
				};
			});
		}
		if (this.props.screen === 'Tickets') {
			return (
				<Fragment>
					<div
						key={ticket.id}
						className="morph-grid__item morph-ticket-item"
						style={{backgroundColor:'red'}}
						// style={{alignItems: 'center'}}
					>
						<div className="morph-ticket-item__group alpha-beta"
							 style={{
								 width: 'auto',
								//  backgroundColor:'red',
								 flex: 1,
								 justifyContent:'space-between',
								 alignItems:'center',
								 maxWidth: this.props.project ? '100%' : '50%',
								//  marginBottom:'50px'
							 }}>
							<div style={{display:'flex', justifyContent:'center', height:'100%', alignItems:'center'}}>
							<div className="morph-ticket-item__cell alpha">
								<div
									className="morph-ticket-item__status custom-bullet-status-select">
									<CustomStatusSelect
										options={this.props.statuses}
										value={{
											label: ticket.status.name,
											value: ticket.status.id,
											bgColor: ticket.status.color
										}}
										onChange={(value) => {
											this.updateTicket('status_id', value.value);
										}}/>
								</div>
							</div>
							<div className="morph-ticket-item__cell beta">
								<NavLink
									to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}/tickets/${ticket.id}`}
									className="ticket-tiem__title"
									style={{textAlign: 'initial'}}>{ticket.title}</NavLink>
							</div>
							</div>
							{!this.props.isProjectList &&
							<div className="morph-ticket-item__cell gamma">
								<NavLink
									to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}`}
									className="morph-ticket-item__project">{ticket?.project?.title ?? this.props.project.title}</NavLink>
							</div>
							}
						</div>
						<div style={{display:'flex' , flexDirection:'row'}}>
						<div
							className="morph-ticket-item__group gamma-delta-epsilon">
							<div className="morph-ticket-item__cell gamma">
								{/* <NavLink to="/projects/call-card"
										 className="morph-ticket-item__project">{}</NavLink> */}
							</div>
						</div>
						<div
							className="morph-ticket-item__group gamma-delta-epsilon" style={{ display:'flex' , justifyContent:'space-between' , width:'100%'}}>
							{/* {!this.props.isProjectList &&
							<div className="morph-ticket-item__cell gamma">
								<NavLink
									to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}`}
									className="morph-ticket-item__project">{ticket?.project?.title ?? this.props.project.title}</NavLink>
							</div>
							} */}
							<div className="morph-ticket-item__cell delta">
								<div
									className="morph-ticket-item__due-date">
									<div className="dropdown">
										<DueDate
											value={ticket.due_date && new Date(ticket.due_date)}
											minDate={new Date()}
											onChange={(value) => {
												this.updateTicket('due_date', value);
											}}/>
									</div>
								</div>
							</div>
							<div className="morph-ticket-item__cell epsilon">
								<div className="morph-ticket-item__asignee">
									<div className="morph-ticket-item__asignee">
										<SelectUsers
											users={this.props.people}
											selected={ticket.assigneed && {
												value: ticket.assigneed.id,
												label: ticket.assigneed.first_name + ' ' + ticket.assigneed.last_name
											}}
											onChange={(data) => {
												this.updateTicket('assignee_id', data);
											}}/>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div key={ticket.id}
						 className="morph-grid__item morph-ticket-item"
						 style={{backgroundColor:'yellow'}}
					>
						<div className="morph-ticket-item__group alpha-beta">
							<div className="morph-ticket-item__cell alpha">
								<div
									className="morph-ticket-item__status custom-bullet-status-select">
									<CustomStatusSelect
										options={this.props.statuses}
										value={{
											label: ticket.status.name,
											value: ticket.status.id,
											bgColor: ticket.status.color
										}}
										onChange={(value) => {
											this.updateTicket('status_id', value.value);
										}}/>
								</div>
							</div>
							<div className="morph-ticket-item__cell beta">
								<NavLink
									to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}/tickets/${ticket.id}`}
									className="ticket-tiem__title"
									style={{textAlign: 'initial'}}>
									{ticket.title}
								</NavLink>
							</div>
						</div>
						<div
							className="morph-ticket-item__group gamma-delta-epsilon">
							<div className="morph-ticket-item__cell gamma">
								<NavLink to="/projects/call-card"
										 className="morph-ticket-item__project">{}</NavLink>
							</div>
							<div className="morph-ticket-item__cell delta">
								<div className="morph-ticket-item__due-date">
									<div className="dropdown">
										<DueDate
											value={ticket.due_date && new Date(ticket.due_date)}
											minDate={new Date()}
											onChange={(value) => {
												this.updateTicket('due_date', value);
											}}/>
									</div>
								</div>
							</div>
							<div className="morph-ticket-item__cell epsilon">
								<div className="morph-ticket-item__asignee">
									<SelectUsers
										users={followers}
										selected={ticket.assigneed && {
											value: ticket.assigneed.id,
											label: ticket.assigneed.first_name + ' ' + ticket.assigneed.last_name
										}}
										onChange={(value) => {
											this.updateTicket('assignee_id', value);
										}}/>
								</div>
							</div>
						</div>
					</div>
					{this.state.ticketModalIsOpen &&
					<TicketItemExpanded
						isModal={true}
						ticket={ticket} {...this.props}
						project={this.props.project}
						closeModal={() => {
							this.onModalClose();
							this.setState({ticketModalIsOpen: false});
						}}/>}
				</Fragment>
			);
		}
	}
}
export default withRouter(TicketItemList)
