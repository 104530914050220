import React, {Component, Fragment} from 'react'
import {Redirect, Route, Switch} from 'react-router'

import SubNavigation from "./SubNavigation"

import Statuses from './Statuses'
import Skills from './Skills'
import Positions from './Positions'
import Roles from "./Roles";


export default class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOnStatusesRoute: false,
			isOnPositionsRoute: false,
			isOnSkillsRoute: false,
			showAddStatus: false,
			showAddPosition: false,
			showAddSkill: false
		};
	}

	onStatusesRoute() {
		this.setState({
			isOnStatusesRoute: true,
			isOnPositionsRoute: false,
			isOnSkillsRoute:false,
			showAddStatus: false,
		})
	}

	onPositionsRoute() {
		this.setState({
			isOnStatusesRoute: false,
			isOnPositionsRoute: true,
			showAddPosition: false,
			isOnSkillsRoute:false,
		})
	}

	onSkillsRoute() {
		this.setState({
			isOnStatusesRoute: false,
			isOnPositionsRoute: false,
			isOnSkillsRoute:true,
			showAddSkill: false,
		})
	}


	handleAddSetting(name) {
		this.setState({
			[name]: !this.state[name]
		})
	}

	render() {
		let handleRoute = {
			isOnStatusesRoute: this.state.isOnStatusesRoute,
			isOnPositionsRoute: this.state.isOnPositionsRoute,
			isOnSkillsRoute: this.state.isOnSkillsRoute
		};
		return (
			<Fragment>
				<SubNavigation
					title="Settings"
					handleRoute={handleRoute}
					handleAddSetting={this.handleAddSetting.bind(this)}
				/>
				<Fragment>
					<Switch>
						<Redirect exact from='/settings' to='/settings/statuses' />
						<Route path='/settings/statuses' render={(props) => <Statuses {...props}  handleRoute={this.onStatusesRoute.bind(this)} addStatus={this.state.showAddStatus}/>} />
						<Route path='/settings/skills' render={(props) => <Skills {...props} handleRoute={this.onSkillsRoute.bind(this)} addSkill={this.state.showAddSkill}/>} />
						<Route path='/settings/positions' render={(props) => <Positions {...props}  handleRoute={this.onPositionsRoute.bind(this)} addPosition={this.state.showAddPosition}/>} />
						<Route path={'/settings/roles'} render={(props) => <Roles />} />
					</Switch>
				</Fragment>
			</Fragment>
		);
	}
}
