import React, {Component} from 'react';
import SubNavigation from './SubNavigation';
import {Redirect, Route, Switch} from 'react-router';

import Tickets from './Tickets';
import Info from './Info';
import Activity from './Activity';
import ProjectsService from '../../../services/api/projects';
import TicketsKanban from './Kanban';

import NotAssignedModal from '../../Modals/NotAssignedModal';
import Modal from 'react-modal';
import ProjectFiles from './Files';

export default class Projects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project: null,
			loading: false,
			isOpenNotAssignedModal: false
		};
	}

	componentDidMount() {
		this.getProject(this.props.match.params.slug);
	}

	getProject(slug) {
		this.setState({project: null, loading: true});
		ProjectsService.getBySlug({related: 'owner,company'}, slug)
			.then((res) =>
				this.setState({
					project: res,
					isOpenNotAssignedModal: false,
					projectFollowers: res.followers.map((follower) => {
						return {
							id: follower.user.id,
							label:
								follower.user.first_name +
								' ' +
								follower.user.last_name,
							avatarUrl: `https://s3.eu-central-1.amazonaws.com/data.huskytracker.com/upload/User/${follower.user.id}/files/profile.jpg`,
							small_avatar: '/avatar_0000.jpeg',
							role_name: follower.user.user_profile.role.name,
							has_avatar: follower.user.has_avatar

						};
					})
				}))
			.catch(error => {
				console.log(error);
				this.setState({isOpenNotAssignedModal: true});
			})
			.finally(() => this.setState({loading: false}));
	}

	differenceOfFollowers(array1, array2) {
		let add = [];
		let remove = [];
		array1.map((item) => {
			if (!array2.includes(item)) {
				remove.push(item);
			}
		});
		array2.map((item) => {
			if (!array1.includes(item)) {
				add.push(item);
			}
		});
		return {add, remove};
	}

	refreshProjectFollowers(arrayOfFollowers) {
		let followers = [...this.state.projectFollowers];
		let diff = this.differenceOfFollowers(followers, arrayOfFollowers);
		followers = [
			...diff.add,
			...followers.filter((item) => !diff.remove.includes(item))
		];
		this.setState({
			projectFollowers: followers
		});
	}

	downloadAllFiles() {
		console.log(this.state.project);
		/*AttachmentsService.downloadResource(`Project/${this.state.project.id}`, this.state.project.id)
			.then(r => {
				fetch(r.url)
					.then(res => res.blob())
					.then(b => {
						var a = document.createElement("a");
						a.href = URL.createObjectURL(b);
						a.setAttribute("download", `${this.state.project.title}-Files.zip`);
						a.click();
					})
			})
			.catch(err => {
				console.log(err);
			})*/
	}

	render() {
		const {project, loading, projectFollowers} = this.state;
		if (loading) return null;
		return (
			<>
				{project ? <>
						<SubNavigation
							project={project}
							projectFollowers={projectFollowers}
							refreshProject={(data) => {
								this.getProject(data);
							}}
							refreshProjectFollowers={this.refreshProjectFollowers.bind(
								this
							)}
						/>
						<>
							<Switch>
								<Redirect exact from={this.props.match.url}
										  to={`${this.props.match.url}/tickets`}/>
								<Route path={`${this.props.match.url}/tickets`}
									   component={(props) => <Tickets {...props}
																	  project={this.state.project}/>}/>
								<Route path={`${this.props.match.url}/info`}
									   component={(props) => <Info {...props}
																   project={this.state.project}/>}/>
								<Route path={`${this.props.match.url}/activity`}
									   component={(props) => <Activity {...props}
																	   project={this.state.project}/>}/>
								<Route path={`${this.props.match.url}/kanban`}
									   component={(props) =>
										   <TicketsKanban {...props}
														  project={this.state.project}/>}/>
								<Route path={`${this.props.match.url}/files`}
									   component={(props) =>
										   <ProjectFiles {...props}
														 project={this.state.project}/>}/>
							</Switch>
						</>
					</>
					:
					<Modal
						isOpen={this.state.isOpenNotAssignedModal}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={true}
					>
						<NotAssignedModal/>
					</Modal>
				}
			</>
		);
	}
}
