import React, {Component, Fragment} from 'react';
import {Switch, Route, Redirect } from 'react-router-dom';

import RegisterAccount from './RegisterAccount';
import {connect} from "react-redux";

class SetupTracker extends Component {

	render() {
		return (
			<Fragment>
				<Switch>
					<Redirect exact from="/account" to="/account/register" />
					<Route path="/account/register" component={RegisterAccount}/>
				</Switch>
			</Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		memberships: state.user.memberships
	}
}

export default connect(mapStateToProps, null)(SetupTracker);
