export function authReducer(state = {}, action) {
	switch(action.type) {
		case '@@huskytracker/USER_AUTHENTICATED':
			return {
				...state,
				isAuthenticated: action.payload
			};

		case '@@huskytracker/USER_MEMBERSHIPS':
			return {
				...state,
				memberships: action.payload
			};
		default:
			return state;
	}
}
