import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router";
import NewTicketForm from "../../forms/NewTicketForm";
import TaxonomiesService from "../../services/api/taxonomies";

class NewTicketModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companyType: 'existing',
			companies: []
		}
	}

	componentDidMount() {
		this.getStatuses();
	}

	getStatuses() {
		TaxonomiesService.get('statuses')
			.then( res => {
				let statuses = res.map(status => {return {value: status.id, label: status.name, order: status.order, bgColor: status.color}}).sort((a,b) => a.order - b.order);
				this.setState({statuses});
			})
			.catch(err => {
				console.log(err)
			})
	}

	render() {
		let followers = this.props.project.followers.map( follower => {
			return {value: follower.user.id, label: follower.user.first_name + ' ' + follower.user.last_name, avatarUrl: `https://s3.eu-central-1.amazonaws.com/data.huskytracker.com/upload/User/${follower.id}/files/profile.jpg`}
		});
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container"><button onClick={()=>{this.props.closeModal()}} className="e-modal__close"></button>
						<div name="new-ticket" id="new_ticket" >
							<div className="e-modal__head"><h2 className="e-modal__title">New Ticket</h2></div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<NewTicketForm
										statuses={this.state.statuses}
										history={this.props.history}
										followers={followers}
										project={this.props.project}
										closeModal={(data) => this.props.closeModal(data)}
										showSelectProject={this.props.showSelectProject}
										getProjectFollowers={this.props.getProjectFollowers}
										selectProjects={this.props.selectProjects}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withRouter(NewTicketModal);
