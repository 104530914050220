import React, {Component, Fragment, useEffect, useState} from "react";
import Select from 'react-select';
import UsersService from "../services/api/users";
import Avatar from "./Avatar";

export default class TeamMembers extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showResults: false
		}
	}

	onChange = (event) => {
		event.preventDefault();
		if (event.target.value.length >= 2) {
			this.setState({
				showResults: true
			})
			this.props.search(event.target.value, 'team_members');
		} else {
			this.props.search(null, 'team_members');
			this.setState({
				showResults: false
			})
		}
	};

	componentDidMount() {
		//this.getUsers('team_members', "An")
	}

	render() {
		let {followers, searchedFollowers} = this.props;
		console.log(followers);
		return (
			<Fragment>
				<label htmlFor="entity-select__member-search"
					   className="entity-select__label">Add team members</label>
				<div className="entity-select__search-container">
					<input
						type="search"
						id="entity-select__member-search"
						placeholder="Search"
						className="entity-select__search"
						onChange={(e) => this.onChange(e)}
					/>

					<ul className="entity-select__recommendations">
						{searchedFollowers.map(item => {
							return (
								<li onClick={() => this.props.editFollowers(item, 'add', 'clients')} className="entity-select__recommendation">
									<div className="entity">
											<span
												className="entity__avatar"></span>
										<span
											className="entity__name">{item.label}</span>
										<span className="entity__meta">
											<span
												className="entity__type"></span>
											<span
												className="entity-result__add"></span>
										</span>
									</div>
								</li>
							)
						})}
						{this.state.showResults && searchedFollowers.length === 0 &&
							<li className="entity-select__recommendation">
								<div className="entity">
									<span className="entity__avatar"></span>
									<span className="entity__name">
											No person found.
									</span>
								</div>
							</li>
						}
				{/*{(this.state.showResults || this.sat) ? searchedFollowers.map(item => {
					return (

					)}
				) :
					<li className="entity-select__recommendation">
						<div className="entity">
							<span className="entity__avatar"></span>
							<span className="entity__name">
                                        No person found.
								</span>
						</div>
					</li>}*/}
					</ul>

				</div>
				{followers.length ?
					<div>
						<span className="entity-select__label">Team members on the project</span>
						<ul className="entity-select__results">
							{followers.map(follower => {
								return (
									<li
										className="entity-select__result">
										<div className="entity">
										<span className="entity__avatar">
											<Avatar width={30} height={30} altText={follower.label} userId={follower.id} hasAvatar={follower.has_avatar}/>
										</span>
											<span
												className="entity__name">{follower.label}</span>
											<span className="entity__meta">
											<span
												className="entity__type"></span>
											<span className="entity__status" onClick={() => this.props.editFollowers(follower, 'delete', 'team_members')}>
												<span
													className="entity__checked"></span>
											</span>
										</span>
										</div>
									</li>
								)
							})
							}

						</ul>
					</div> : null
				}
			</Fragment>
		)
	}
}
