import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import ProfileInfo from './Info';
import SubNavigation from './SubNavigation';
import UsersService from '../../services/api/users';
import {connect} from 'react-redux';
import {populateUser} from '../shared/actions';
import {bindActionCreators} from 'redux';

const _ = require('lodash');

class Profile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			user: null,
			editProfileModal: false
		};
	}

	componentDidMount() {
		this.getUserProfile(this.props.user.id);
	}

	getUserProfile(id) {
		UsersService.getProfile(id, {related: 'user_profile.company,taxonomies'})
			.then(response => {
				if (this.props.user.id === id) {
					this.props.populateUser();
				}
				this.setState({
					user: response
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	render() {
		if (!this.state.user) {
			return null;
		}
		return (
			<Fragment>
				<SubNavigation
					title="My Profile"
					user={this.state.user}
					editProfileModalIsOpen={this.state.editProfileModal}
					toggleEditProfileModal={(boolean, data) => {
						this.setState({editProfileModal: boolean});
						if (data) {
							this.getUserProfile(this.props.user.id);
						}
					}}
				/>
				<Switch>
					<Route path={`/my/info`} component={(props) =>
						<ProfileInfo
							{...props}
							user={this.state.user}
							getUserProfile={() => this.getUserProfile(this.props.user.id)}
						/>}
					/>
				</Switch>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({populateUser}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));



