import React, {Component, Fragment} from 'react';
import SkillsFilterForm from "../../forms/SkillsFilterForm";
import PillsSelector from "../../components/PillsSelector";
import UsersService from "../../services/api/users";

export default class EditSkillsModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: props.value
		}
	}
	onChange(e){
		this.setState ({
			value: e
		})
	}
	onSubmit(){
		console.log(this.state.value);
		let user = {user_profile: {skill_ids:[]}};
		user.user_profile.skill_ids = this.state.value;
		UsersService.editUser(this.props.user.id, user)
			.then(res => {
				console.log(res);
				this.props.closeModal(res);
			})
			.catch(err => {
				console.log(err);
			})
	}
	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container extra-wide">
						<button onClick={()=>{this.props.closeModal()}} className="e-modal__close"/>
						<div name="skill-filter-modal">
							<div className="e-modal__head">
								<h2 className="e-modal__title">Skills</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">

									<div className="f-section">
										<PillsSelector
											multi={true}
											grouped={true}
											groups={this.props.skillGroups}
											onChange={(event)=> {this.onChange(event)}}
											options={this.props.skills}
											value={this.state.value}/>
									</div>

									<div className="f-row btn-container">
										<button className="btn fill blue"
												onClick={() => this.onSubmit()}>Save</button>
										<button onClick={() => this.props.closeModal()}
												className="btn outline gainsboro">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
