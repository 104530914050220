import React, {Component, Fragment} from 'react';
import Avatar from "../../components/Avatar";
import {NavLink} from "react-router-dom";
import CustomStatusSelect from "../../components/CustomStatusSelect";
import {CustomStatusSelectInput} from "../../inputs/CustomStatusSelectInput";
import {required} from "../../inputs/validations";

class ConfirmDeleteStatusModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newStatus: null
		}
	}

	render() {
		const {forDelete, affectedTickets} = this.props;
		return(
			<div className="e-modal">
				<div className="e-modal__container">
					<button onClick={() => {this.props.toggleConfirm()}} className="e-modal__close"/>
					<div>
						<div className="e-modal__head">
							<h2 className="e-modal__title">Tickets which have <span className='status-style' style={{ '--color': forDelete.color }}>"{ forDelete.name }"</span> as status</h2>
							</div>
						<div className="e-modal__body">
							<div className="e-modal__body-overflow">
									<div className="f-row" >
										<ul className={'ticket-list'}>
										{affectedTickets.map(item =>
											<li>
												<NavLink to={`/projects/${item.project.readable_id}/tickets/${item.id}`} target='_blank'>
													{item.title} ({item.project.title})
												</NavLink>
											</li>
										)}
										</ul>
									</div>

									<div className={"f-row"}>
										<div className="f-element is-full">
											<div className={`ticket-meta`} style={{padding:0, border:0}}>
												<div className="ticket-meta-cell ticket-meta-name">Change status of these ticket with this one</div>
												<div className="ticket-meta-cell ticket-meta-element">
													<CustomStatusSelect
														options={this.props.statuses}
														onChange={(value) => this.setState({ newStatus: value})}
														value={this.props.statuses.find(status => {
															return this.state.newStatus === status.value
														})}/>
												</div>
											</div>
										</div>
									</div>



								<div className="f-row btn-container">
									<button className='btn fill blue round'
											disabled={this.state.newStatus}
											onClick={() => this.props.confirmDelete(this.state.status)}>
										Confirm
									</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ConfirmDeleteStatusModal;
