import React, {Component, Fragment} from 'react';
import CustomSelect from '../../components/CustomSelect';
import CompaniesService from '../../services/api/companies';
import {NavLink, Switch} from 'react-router-dom';
import SubNavigation from "./SubNavigation";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeCompaniesViewType} from '../shared/actions';
import {ViewMenu} from '../../new-components';
import svgCompany from '../../assets/images/defaultcompany.svg'
import getCompanyImage from '../../components/helpers/getCompanyImage'
import getCompanyImageUrl from '../../components/helpers/getCompanyImageUrl'
import defaultAvatar from '../../assets/images/default-avatar.png'

class CompaniesList extends Component {

	constructor() {
		super();
		this.state = {
			viewType: 'list-view',
			companies: null,
			filters: {
				order_by: {label: "Order by", value: null},
				order_type: {label: "Direction", value: 'asc'},
				status: 'active'
			}
		};
	}

	componentDidMount() {
		this.getCompanies();
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters
		}, () => this.getCompanies())
	}



	async getCompanies() {
		try {
			const res = await	CompaniesService.listCompanies({filters: this.state.filters})
			const companies = await Promise.all(res.data.map(async company => ({
					...company,
					avatar: company.avatar_id ? await getCompanyImage(company.avatar_id) : svgCompany
				})
			))
			console.log(companies)
			this.setState({
				companies
			})
		} catch (e){
			console.error(e);
		}

	}

	renderCompany(company) {
		return (
			<div key={company.id} className="morph-grid__item company-item">
				<div className="company-item__group alpha-beta">
					<div className="company-item__cell alpha">
						<div className="company-item__profile-preview">
							<NavLink to={`/companies/${company.id}`}>
								<img alt="company" src={company.avatar} onError={(ev) => ev.target.src = svgCompany} className="profile-preview-avatar" />
								<span className="profile-preview-name">{company.name}</span>
							</NavLink>
						</div>
					</div>
					<div className="company-item__cell beta">
						<div className="company-item__origin">{company.location.country}</div>
					</div>
				</div>
				<div className="company-item__group delta-zeta">
					<div className="company-item__cell delta">
						<div className="company-item__relevant-data">
							<div className="relevant-data-value">{company.active_projects}</div>
							<div className="relevant-data-label">
								Active <br /> Projects{' '}
							</div>
						</div>
					</div>
					<div className="company-item__cell zeta">
						<div className="company-item__relevant-data">
							<div className="relevant-data-value">{company.active_tickets}</div>
							<div className="relevant-data-label">
								Opened <br /> Tickets
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	handleChangeCompaniesViewType = (type) =>
		() => {
			this.props.changeCompaniesViewType(type);
		};

	render() {
		return (
			<Fragment>
				<SubNavigation
					title="All Companies"
					refreshCompanies={() => this.getCompanies()}
					changeStatus={this.onFilter.bind(this)}
					status={this.state.filters.status}
				/>

				<div className="secondary-bottom">
					<div className="wrapper">
						<ul className="filter-container">
							<CustomSelect name="order_by"
										  className="filter"
										  value={this.state.filters.order_by}
										  onChange={this.onFilter.bind(this, 'order_by')}
										  options={[
												{label: "Order by", value: null},
												{label: "Name", value: 'name'},
											  	{label: "By most tickets", value: 'tickets'},
											  	{label: "By most projects", value: 'projects'},
										  ]}
							/>
							<CustomSelect name="order_type"
										  className="filter"
										  value={this.state.filters.order_type}
										  onChange={this.onFilter.bind(this, 'order_type')}
										  options={[
											  {label: "Direction", value: null},
											  {label: "First to last", value: 'asc'},
											  {label: "Last to first", value: 'desc'},
										  ]}
							/>
						</ul>
					</div>
				</div>
				<section className="page">
					<div className="wrapper">
						<ViewMenu
							view={this.props.preferences.companiesView}
							onChangeProjectViewType={this.handleChangeCompaniesViewType}
						/>
						<div className="list-container">
							<div className="inner-container">
								<div className={`morph-grid__container ctrl ${this.props.preferences.companiesView}`}>
									{this.state.companies &&
										this.state.companies.map((company) => this.renderCompany(company))}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		preferences: state.preferences
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ changeCompaniesViewType }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
