import React from 'react';
import useForm from 'react-hook-form'
import {Input} from '../../inputs/Input';
import AccountService from "../../services/api/account";
import {required, isEmailAddress} from "../../inputs/validations";

export default function RegisterForm({ handleChargebee }) {
	const {register, handleSubmit, errors, setValue, watch  } = useForm({
		mode: "onChange",
		defaultValues: { user: {} }
	});
	const watchSubscription = watch('subscription_plan');

	const onSubmit = data => {
		handleChargebee(
			AccountService.register(data)
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="wrapper credentials">
				<div className="f-section">

					<h2 className="f-section__title text-center">Setup up new account</h2>

					<div className="plan-list">
						<div
							className={`plan-item ${watchSubscription === 'starter' ? 'selected' : null}` }
							onClick={() => setValue('subscription_plan', 'starter')}
						>
							<span className="name">Starter</span>
							<span className="price">$0.00 / month</span>
							<span className="details">3 projects<br/> Unlimited users</span>
						</div>
						<div
							className={`plan-item ${watchSubscription === 'pro' ? 'selected' : null}` }
							onClick={() => setValue('subscription_plan', 'pro')}
						>
							<span className="name">Pro</span>
							<span className="price">$9.00 / month</span>
							<span className="details">Unlimited projects<br/> Unlimited users</span>
						</div>
					</div>
					<input type="hidden" name="subscription_plan" value="starter" ref={register} />

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input
								type="text"
								placeholder="Company name..."
								register={
									register({
										validate: {required},
									})
								}
								error={errors.name}
								name="name"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input
								type="text"
								placeholder="Company subdomain..."
								register={
									register({
										validate: {required, min: 3 },
									})
								}
								error={errors.subdomain}
								name="subdomain"
							/>
						</div>
					</div>

					<div className={'f-row is-h-centered '}>
						<div className={'f-element is-half'}>
							<Input
								type="text"
								placeholder="First name..."
								register={
									register({
										validate: {required},
									})
								}
								error={errors['user.first_name']}
								name="user.first_name"
							/>
						</div>

						<div className={'f-element is-half'}>
							<Input
								type="text"
								placeholder="Last name..."
								register={
									register({
										validate: {required},
									})
								}
								error={errors['user.last_name']}
								name="user.last_name"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input
								placeholder="Email..."
								type="text"
								register={
									register({
										validate: {isEmailAddress, required},
									})
								}
								error={errors['user.email']}
								name="user.email"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input
								placeholder="Password..."
								type="password"
								error={errors['user.password']}
								register={
									register({
										validate: required
									})
								}
								name="user.password"
							/>
						</div>
					</div>

					<div className={'f-row'}>
						<div className={'f-element'}>
							<Input
								placeholder="Password confirmation..."
								type="password"
								error={errors['user.password_confirmation']}
								register={
									register({
										validate: required
									})
								}
								name="user.password_confirmation"
							/>
						</div>
					</div>

					<div className="f-row btn-container">
						<button type="submit" className="btn fill blue">Next step</button>
					</div>

				</div>
			</div>
		</form>

	)
}
