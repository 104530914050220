import React from 'react';

export const PageTitle = (props) => {
	const {children} = props;
	return (
		<h1 className="page-title">
			{children}
		</h1>
	);
};
