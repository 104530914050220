import React from 'react'
import SubNavigation from '../shared/SubNavigation/SubNavigation'
import {ActivityReports} from './ActivityReports'

export const Reports = () => {
	return (
		<>
			<SubNavigation title="Reports" />
			<ActivityReports/>
		</>
	)
}
