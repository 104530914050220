import request from '../shared/request'

export default class ReportsService {
	static getReports() {
		return request({
			url: '/reports',
			method: 'GET',
		})
	}
}
