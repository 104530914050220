import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
const _ = require('lodash');



const PrivateRoute = ({ component: Component, isAuthenticated, props, ...rest}) => (
	<Route
		{...rest}
		render={props_ => (
			isAuthenticated ?
				(<Component {...props_} {...props} />)
				:
				(<Redirect to={{ pathname: `/auth/login`, state: { from: props_.location}, search: `next=${props_.location.pathname}` }} />)
		)}
	/>
);

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated
	};
}

export default connect(mapStateToProps, null)(PrivateRoute);
