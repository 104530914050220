import React, {Component, Fragment} from 'react';
import SkillsFilterForm from "../../forms/SkillsFilterForm";

export default class SkillsFilterModal extends Component {

	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container extra-wide">
						<button onClick={()=>{this.props.closeModal()}} className="e-modal__close"/>
						<div name="skill-filter-modal">
							<div className="e-modal__head">
								<h2 className="e-modal__title">Filter by skills</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<SkillsFilterForm
										onFilterChange={this.props.onFilterChange}
										skillFilters={this.props.skillFilters}
										skills={this.props.skills}
										skillGroups={this.props.skillGroups}
										loading={this.props.loading}
										peopleFiltered={this.props.peopleFiltered}
										closeModal={this.props.closeModal}/>

								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
