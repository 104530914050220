import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

export default () => {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DNS,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.2,
	});
}
