import React,{useMemo} from 'react';
import Select from 'react-select';
import * as PropTypes from 'prop-types';

export const ChangeRoleSelect = (props) => {
	const {value, onChange, roles} = props;

	const formatLabel = (text) => text.charAt(0).toUpperCase() + text.slice(1);

	const options = useMemo(() => roles.map((role) => ({
		value: role.id,
		label: formatLabel(role.name)
	})), [roles]);

	const selectedValue = useMemo(() => ({
		value,
		label: formatLabel(roles.find(role => role.id === value)?.name || '')
	}), [value, roles]);

	return <Select
		options={options}
		value={selectedValue}
		onChange={onChange}
	/>;
};

ChangeRoleSelect.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	roles: PropTypes.array
};
