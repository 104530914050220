import React, {Component} from 'react';
import RecoveryForm from "../../forms/RecoveryForm";

export default class Recovery extends Component {
	render() {
		return (
			<RecoveryForm/>
		);
	}
}
