import React, {useState} from 'react';
import {useHistory} from 'react-router';

export default ({closeModal}) => {
	const [query, setQuery] = useState('');
	const history = useHistory();

	const handleOnClick = () => {
		history.push(`/search?query=${query}`);
		closeModal();
	};
	const handleKeyDown = (e) => {
		if(e.key === 'Enter'){
			e.preventDefault();
			handleOnClick();
		}
	};
	const handleOnCloseModal = () => closeModal();
	const handleOnChange = (event) => setQuery(event.target.value);

	return <>
		<div className="e-modal" onKeyDown={handleKeyDown}>
			<div className="e-modal__container">
				<button onClick={handleOnCloseModal} className="e-modal__close"/>
				<div className="search-page">
					<div className="e-modal__head">
						<h2 className="e-modal__title">Search</h2></div>
					<div className="e-modal__body">
						<div className="e-modal__body-overflow">
							<div className="f-section">
								<div className="f-row">
									<div className="f-element is-full">
										<input
											type="text"
											name="query"
											placeholder="Keywords"
											className=""
											aria-required="true"
											aria-invalid="false"
											onChange={handleOnChange}
										/>
									</div>
								</div>
							</div>
							<div className="f-row btn-container">
								<button onClick={handleOnClick} className="btn fill blue">Go</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>;
};
