import React, {Component} from 'react';
import defaultAvatar from '../assets/images/default-avatar.png';

export default class Avatar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const altText = this.props.alt || '';
		const width = this.props.width || 36;
		const height = this.props.height || 36;
		let {userId, className, hasAvatar} = this.props;
		return <img
			style={{width, height, borderRadius: '50%'}}
			className={className} alt={altText}
			src={hasAvatar ? `https://s3.eu-central-1.amazonaws.com/data.huskytracker.com/upload/User/${userId}/files/profile.jpg` : defaultAvatar}
			onError={(ev) => ev.target.src = defaultAvatar}
			width={width} height={height}
		/>;
	}
}
