import React from 'react';
import {ProjectViewButton} from './ProjectViewButton';

export const ViewMenu = (props) => {
	const {view, onChangeProjectViewType} = props;
	return (
		<ul className="morph-grid-control">
			<li>
				<ProjectViewButton
					active={view === 'list-view'}
					onClick={onChangeProjectViewType('list-view')}
					icon={'fa-bars'}
				/>
			</li>
			<li>
				<ProjectViewButton
					active={view === 'card-view'}
					onClick={onChangeProjectViewType('card-view')}
					icon={'fa-grip-horizontal'}
				/>
			</li>
		</ul>
	);
};
