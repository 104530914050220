import request from '../shared/request';

export default class TicketsService {

	static getAll(params) {
		return request({
			url: '/tickets',
			method: 'GET',
			params
		})
	}

	static getById(id, params = null) {
		return request({
			url: `/tickets/${id}`,
			method: 'GET',
			params
		})
	}

	static updateTicket(id, data, params) {
		return request({
			url: `/tickets/${id}`,
			method: 'PUT',
			data: data,
			params
		})
	}

	static createTicket(data) {
		return request({
			url: `/tickets`,
			method: 'POST',
			data: data
		})
	}
	static closeTicket(id){
		return request({
			url:`/tickets/${id}/close`,
			method:'PUT',
		})
	}

	static postComment(id,data) {
		return request({
			url: `/tickets/${id}/comment`,
			method: 'POST',
			data: data
		})
	}
	static getComments(params) {
		return request({
			url: 'tickets/comments',
			method: 'GET',
			params
		})
	}
	static deleteComment(id) {
		return request({
			url: `/tickets/comment/${id}`,
			method: 'DELETE'
		})
	}
	static editComment(id, data) {
		return request({
			url: `/tickets/comment/${id}`,
			method: 'PUT',
			data
		})
	}

	static getActivities(params) {
		return request({
			url: `/tickets/activities`,
			method: 'GET',
			params
		})
	}

	static searchTickets(params) {
		return request({
			url: '/tickets/search',
			method: 'GET',
			params
		})
	}

}
