import React, {Component, Fragment} from 'react';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

class Landing extends Component {

	render() {
		return (
			<Fragment>
				<div className="wrapper credentials">
					<div className="f-section text-center">
						<h2 className="f-section__title no-border">Welcome to HuskyTracker!</h2>

						<p className="f-info">
							Continue with our awesome project tracking application.
						</p>

						<div className="f-row btn-container">
							<Link to="/auth/login" className="btn outline blue">Go to login</Link>
							<span style={{margin: '0 0 0 16px'}}>- or -</span>
							<Link to='/account/register' className="btn fill blue">Setup new tracker</Link>
						</div>
					</div>
				</div>

			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		isAuthenticated: state.auth.isAuthenticated,
		memberships: state.user.memberships
	};
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Landing);
