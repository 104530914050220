import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import Avatar from "./Avatar";
import moment from 'moment'
import {withRouter} from "react-router";

class KanbanCard extends Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.props.history.push(`/projects/${this.props.project_id}/tickets/${this.props.id}`)
	}

	render() {
		const {
			showDeleteButton,
			style,
			tagStyle,
			onClick,
			onDelete,
			className,
			id,
			title,
			label,
			description,
			tags,
			assignee,
			due_date,
			sort,
			project_id
		} = this.props
		return <Fragment>
			<div onClick={this.onClick} className={'custom-trello-card'} style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column', borderRadius: 3, borderBottom: '1px solid #ccc', position: 'relative', padding: 10, cursor: 'pointer', maxWidth: 250, minWidth: 230, marginBottom: 8, backgroundColor: "#FFF", whiteSpace: 'pre-wrap', fontFamily: 'Roboto'}}>
				<div style={{fontSize: 14, whiteSpace: 'pre-wrap', color: '#3C4061', fontWeight: 500}}>{title}</div>
				<div style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginTop: 12}}>
					<Avatar className={'custom-single-user-select__avatar'} width={32} height={32} userId={assignee?.id ?? null} hasAvatar={assignee?.has_avatar}/>
					<div style={{fontSize: 14, whiteSpace: 'pre-wrap', textAlign: 'center', color: '#3C4061', fontWeight: 400}}>{due_date ? moment(due_date).format("DD MMM YYYY") : 'No Due Date'}</div>
				</div>
			</div>
		</Fragment>
	}
}

KanbanCard.propTypes = {
	showDeleteButton: PropTypes.bool,
	onDelete: PropTypes.func,
	onClick: PropTypes.func,
	style: PropTypes.object,
	tagStyle: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	label: PropTypes.string,
	description: PropTypes.string,
	tags: PropTypes.array,
	assignee: PropTypes.any,
	due_date: PropTypes.any,
	sort: PropTypes.any,
	project_id: PropTypes.any
}

KanbanCard.defaultProps = {
	showDeleteButton: true,
	onDelete: () => {},
	onClick: () => {},
	style: {},
	tagStyle: {},
	title: 'no title',
	description: '',
	label: '',
	tags: [],
	className: '',
	due_date: null,
	project_id: 0
}

export default withRouter(KanbanCard)
