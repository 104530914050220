// reducers.js

import {connectRouter} from 'connected-react-router'
import {reducer as formReducer} from 'redux-form';
import {authReducer} from "./screens/Auth/reducers/auth";
import {preferencesReducer, userReducer} from "./screens/shared/reducers";

export default (history) => {
    return{
        router: connectRouter(history),
        auth: authReducer,
        form: formReducer,
		user: userReducer,
		preferences: preferencesReducer
    }
}
