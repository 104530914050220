import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router";
import CompaniesService from "../../services/api/companies";
import NewProjectForm from "../../forms/NewProjectForm";
class NewProjectModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			companies: []
		}
	}

	componentDidMount() {
		CompaniesService.listCompanies({limit: -1})
      .then(res => {
				this.setState({
					companies: res.data.map(company => {return {value: company.id, label: company.name}})
				})
			})
	}

	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container"><button onClick={()=>{this.props.closeModal()}} className="e-modal__close"/>
						<div name="new-ticket">
							<div className="e-modal__head"><h2 className="e-modal__title">New Project</h2></div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<NewProjectForm {...this.props} companies={this.state.companies} closeModal={() => this.props.closeModal()} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}


export default withRouter(NewProjectModal);
