import React, {Component} from 'react';
import ResetPasswordForm from "../../forms/ResetPassword";

export default class Recovery extends Component {
	render() {
		return (
			<ResetPasswordForm {...this.props}/>
		);
	}
}
