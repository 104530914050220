import React, { Fragment} from 'react';
export function ToggleField ({ register,type,value,name}) {

	return (
		<Fragment>
			<input className="switch"
				   type={type}
				   ref={register}
				   name={name}
				   checked={value}
				   id={name}/>
		</Fragment>
	);
}
