import React, {Component, Fragment} from 'react';
import Select, {components} from 'react-select';
import Avatar from './Avatar';

//  CUSTOM AVATAR + NAME LIST
const customSingleUserOption = props => {
	const {data, innerProps} = props;
	return (
		<components.Option {...props} {...innerProps}>
			<div
				className={`custom-single-user-select__option-container ${props.isSelected ? 'isSelected' : ''}`}>
				<Avatar
					className={'custom-single-user-select__avatar'}
					width={30}
					height={30}
					altText={data.label}
					userId={data.id}
					hasAvatar={data.has_avatar}
				/>
				<span
					className="custom-single-user-select__name">{data.label}</span>
			</div>
		</components.Option>
	);
};
const customMultiUserOption = props => {
	const {data, innerProps} = props;
	return (
		<components.Option {...props} {...innerProps}>
			<div
				className={`custom-single-user-select__option-container ${props.isSelected ? 'isSelected' : ''}`}>
				<Avatar
					className={'custom-single-user-select__avatar'}
					width={30}
					height={30}
					altText={data.label}
					userId={data.id}
					hasAvatar={data.has_avatar}
				/>
				<span
					className="custom-single-user-select__name">{data.label}</span>
			</div>
		</components.Option>
	);
};

const customMultiValue = props => {
	return null;
};

export default class SelectUsers extends Component {

	selectRef;

	constructor(props) {

		super(props);
		let selected = (this.props.users && this.props.selected) && (!this.props.isMultiSelect ?
			this.props.users.filter(user => user.value === this.props.selected.value)[0]
			:
			this.props.users.filter(user => this.props.selected.filter(selected => selected.value === user.value).length === 1));
		this.state = {
			dropdownOpen: false,
			selectedUsers: selected
		};

		this.toggle = this.toggle.bind(this);
		this.onChange = this.onChange.bind(this);

	}

	componentDidUpdate(prevProps) {
		if (this.props.users !== prevProps.users) {
			let selected = (this.props.users && this.props.selected) && (!this.props.isMultiSelect ?
				this.props.users.filter(user => user.value === this.props.selected.value)[0]
				:
				this.props.users.filter(user => this.props.selected.filter(selected => selected.value === user.value).length === 1));
			this.setState({
				selectedUsers: selected
			});
		}
	}

	toggle() {
		this.setState({dropdownOpen: !this.state.dropdownOpen});
	}


	onChange(value) {
		if (!this.props.isMultiSelect) {
			this.setState({
				selectedUsers: value
			});
			this.props.onChange(value.value);
			this.toggle();
		} else {
			this.setState({
				selectedUsers: value
			});
			this.props.onChange(value.map(user => user.value));
		}

	}

	onClear() {
		console.log('clear');
	}

	render() {
		const {users, showLabel, isMultiSelect} = this.props;
		const {selectedUsers, dropdownOpen} = this.state;
		if (!users) return null;
		return (
			<Fragment>
				{dropdownOpen &&
				<div onClick={this.toggle} className="custom-popup-exit"/>}
				<div className="custom-single-user-select__container">
					{selectedUsers ?
						(isMultiSelect ?
								<Fragment>
									<ul className="entity-list">
										{selectedUsers.map((user, key) => {
											if (user) return (
												<li className="entity-list__user"
													key={key}>
													<button
														type={'button'}
														onClick={this.toggle}
														className="custom-single-user-select__trigger has--avatar">
														<Avatar
															className={'custom-single-user-select__avatar'}
															width={30}
															height={30}
															altText={user.label}
															userId={user.value}
															hasAvatar={user.has_avatar}
														/>
													</button>
												</li>);
											else return null;
										})}
									</ul>
									{this.state.selectedUsers != '' ?
										(<button type={'button'}
												 onClick={this.toggle}
												 className="far-icon outline gray hover circle custom-single-user-select__trigger">
											<i className="far fa-user-plus"></i>
										</button>)
										:
										(<button type={'button'}
												 onClick={this.toggle}
												 className="far-icon outline gray hover text-btn custom-single-user-select__trigger">
											Add Follower
										</button>)
									}
								</Fragment>
								:
								<button
									type={'button'}
									onClick={this.toggle}
									className="custom-single-user-select__trigger has--avatar">
									{showLabel &&
									<span
										className="custom-single-user-select__label">
									{selectedUsers.label}
								</span>
									}
									<Avatar
										className={'custom-single-user-select__avatar'}
										width={30}
										height={30}
										altText={selectedUsers.label}
										userId={selectedUsers.value}
										hasAvatar={selectedUsers.has_avatar}
									/>
								</button>
						)
						:
						(isMultiSelect ?
							<button type={'button'} onClick={this.toggle}
									className="far-icon outline gray hover text-btn custom-single-user-select__trigger">
								{/* <i className="far fa-user-plus"></i> */}
								Add Follower
							</button>
							:
							<button type={'button'} onClick={this.toggle}
									className="custom-single-user-select__trigger">
								<i className="material-icons">person</i>
							</button>)
					}
					{dropdownOpen &&

					<div className="custom-single-user-select__holder">

						<button type={'button'} onClick={this.toggle}
								className="custom-single-user-select__close">
							<i className="material-icons">clear</i>
						</button>
						<Select
							ref={ref => (this.selectRef = ref)}
							options={users}
							value={selectedUsers}
							components={{
								Option: isMultiSelect ? customMultiUserOption : customSingleUserOption,
								MultiValueContainer: customMultiValue
							}}
							onClear={() => {
								this.onClear();
								this.selectRef.blur();
							}}
							openOnFocus
							menuIsOpen
							hideSelectedOptions={false}
							defaultMenuIsOpen={true}
							onChange={this.onChange}
							className={'custom-single-user-select'}
							classNamePrefix={'custom-single-user-select'}
							isMulti={isMultiSelect}
							isSearchable={true}
							placeholder="Search ..."
						/>
					</div>
					}
				</div>
			</Fragment>
		);
	}
}
