import React, {Component, Fragment} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {PageTitle} from '../../../new-components';

class SubNavigation extends Component {

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head">
							<PageTitle>
								{this.props.title}
							</PageTitle>
						</div>
					</div>
					{this.props.projectScreen &&
					<div className="wrapper wide">
						<ul className="navigation-tab-links is-inline is-scrollable">
							<li>
								<NavLink
									activeClassName="is-active"
									to={`${this.props.match.url}/info`}
								>
									Info
								</NavLink>
							</li>
							<li>
								<NavLink
									activeClassName="is-active"
									to={`${this.props.match.url}/tickets`}
								>
									Tickets
								</NavLink>
							</li>
							<li>
								<NavLink
									activeClassName="is-active"
									to={`${this.props.match.url}/activity`}
								>
									Activity
								</NavLink>
							</li>
						</ul>
					</div>
					}
				</div>
			</Fragment>
		);
	}
}

export default withRouter(SubNavigation);
