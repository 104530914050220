import request from '../shared/request';

export default class CommentsService {

	static searchComments(params) {
		return request({
			url: '/comments/search',
			method: 'GET',
			params
		})
	}
}
