import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'

class SubNavigation extends Component {

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head">
							<h1 className="page-title">{this.props.title}</h1>
							<div className="btn-wrap page-controls">
								{/*If user is on /settings/statuses it shows the button*/}
								{this.props.handleRoute.isOnStatusesRoute &&
								<button
									onClick={() => this.props.handleAddSetting('showAddStatus')}
									className="btn fill blue create-status__toggle">Add Ticket Status</button>}

								{this.props.handleRoute.isOnPositionsRoute &&
								<button
									onClick={() => this.props.handleAddSetting('showAddPosition')}
									className="btn fill blue create-status__toggle">Add Company Position</button>}

								{this.props.handleRoute.isOnSkillsRoute &&
								<button
									onClick={() => this.props.handleAddSetting('showAddSkill')}
									className="btn fill blue create-status__toggle">Add Skill Group</button>}
							</div>
						</div>
					</div>
					<div className="wrapper wide">
						<ul className="navigation-tab-links is-inline is-scrollable">
							<li><NavLink activeClassName="is-active" to='/settings/statuses'>Statuses</NavLink></li>
							<li><NavLink activeClassName="is-active" to='/settings/skills'>Skills</NavLink></li>
							<li><NavLink activeClassName="is-active" to='/settings/positions'>Positions</NavLink></li>
							<li><NavLink activeClassName="is-active" to='/settings/roles'>Roles</NavLink></li>
						</ul>

					</div>

				</div>
			</Fragment>
		);
	}
}

export default withRouter(SubNavigation);
