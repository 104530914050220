import React, {Component, Fragment} from 'react';
import Dropzone from 'react-dropzone';
import UploadService from '../services/api/upload';
import iconLoader from '../assets/images/icons/i_loader.svg';
import {canRenderPreview, getFontAwesomeIconFromMIME} from './helpers/preview';
import {connect} from 'react-redux';
import {PermissibleRender} from '@brainhubeu/react-permissible';

class UploadFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previews: this.props.files || [],
			isDeleting: false,
			resource_id:
				this.props.resource_id ||
				Math.random()
					.toString(36)
					.substr(2, 9),
			resource_type: this.props.resource || 'Upload',
			loadingCounter: 0
		};
	}

	componentDidMount() {
		if (!this.props.files && !this.props.isComment) {
			this.getFiles();
		}
	}

	isLoading() {
		return this.state.loadingCounter > 0;
	}

	updateLoadingCounter(value) {
		if (this.state.loadingCounter === 0 && value < 0) return;
		this.setState({
			loadingCounter: this.state.loadingCounter + value
		});
	}

	getFiles() {
		UploadService.getResources(
			this.state.resource_type,
			this.state.resource_id
		)
			.then(response => {
				this.setState({previews: response});
			})
			.catch(err => {
				console.log(err);
			});
	}

	addFile(file) {

		this.updateLoadingCounter(+1);
		UploadService.signResourceAndUpload(
			this.state.resource_type,
			this.state.resource_id,
			file
		)
			.then(response => {
				this.updateLoadingCounter(-1);
				response.file_file_size = file.size;
				this.setState({
					previews: [...this.state.previews, response]
				});
			})
			.catch(() => this.updateLoadingCounter(-1));

		if (this.props.onFileAdded) {
			this.props.onFileAdded(
				{
					resource_type: this.state.resource_type,
					resource_id: this.state.resource_id,
					file_file_name: file.name
				},
				file
			);
		}
	}

	removeFile(file) {
		this.setState({
			previews: this.state.previews.filter(
				e => e.file_file_name !== file.file_file_name
			)
		});

		if (this.props.onFileRemoved) {
			this.props.onFileRemoved(file);
		}

		this.updateLoadingCounter(+1);

		UploadService.removeResource(
			file.resource_type,
			file.resource_id,
			file.file_file_name
		)
			.then(response => {
				this.updateLoadingCounter(-1);
			})
			.catch(err => {
				console.log(err);
				this.updateLoadingCounter(-1);
			});
	}

	formatFileSize(bytes, decimalPoint) {
		if (bytes == 0) return '0 Bytes';
		var k = 1000,
			dm = decimalPoint || 2,
			sizes = [
				'Bytes',
				'KB',
				'MB',
				'GB',
				'TB',
				'PB',
				'EB',
				'ZB',
				'YB'
			],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
			' ' +
			sizes[i]
		);
	}

	clearAttachments = () => {
		this.setState({previews: []});
	};

	isImage(fileName) {
		const fileNamePattern = /^.*\.(.*)$/;
		const imageFileType = [
			'apng',
			'bmp',
			'gif',
			'ico',
			'cur',
			'jpg',
			'jpeg',
			'jfif',
			'pjpeg',
			'pjp',
			'png',
			'svg',
			'tif',
			'tiff',
			'webp'
		];

		return imageFileType.includes(
			fileNamePattern.exec(fileName.toLowerCase())[1]
		);
	}

	previewContent() {
		return this.state.previews.map((file, index) => {
			const preview = canRenderPreview(file.url)
				? <img
					src={file.url}
					alt={'preview'}
					className={'preview_image'}
				/>
				:
				<i className={`fa ${getFontAwesomeIconFromMIME(file.file_file_name)} fa-4`}
				   aria-hidden="true"/>;

			return (
				<div key={index} className="preview_cell">
					<a target={'_blank'} href={file.url}>
						{preview}
						<p>{file.file_file_name}</p>
						<p>{this.formatFileSize(file.file_file_size)}</p>

					</a>
					<PermissibleRender
						userPermissions={this.props.userPermissions}
						requiredPermissions={['can_delete_files']}
						oneperm
					>
						{!this.props.disabled && <div
							className="btn outline"
							onClick={() => this.removeFile(file)}
						>
							Remove file
						</div>
						}
					</PermissibleRender>

				</div>
			);
		});
	}

	render() {
		return (
			<Fragment>
				<div className="upload__item">
					{this.isLoading() && (
						<div className="upload__loader_container">
							<div className="upload__loader">
								<img src={iconLoader} alt="Loading"/>
							</div>
						</div>
					)}
					<div className="upload__zone">
						{this.state.previews.length > 0 && (
							<div className="preview">
								{this.previewContent()}
							</div>
						)}
						<Dropzone
							onDrop={
								acceptedFiles => {
									acceptedFiles.map(f => {
										this.addFile(f);
									});
								}
							}
							multiple={true}
							disabled={this.state.image || this.props.disabled}
						>
							{({getRootProps, getInputProps}) => (
								!this.props.disabled && <div
									className="dropzone"
									{...getRootProps()}
								>
									<input {...getInputProps()} />
									<p style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
										Drag &amp; Drop file here to
										upload or &nbsp;
										<button
											type={'button'}
											disabled={
												this.state.image
											}
											className="btn link"
										>
											select from computer
										</button>
									</p>
								</div>
							)}
						</Dropzone>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(UploadFile);
