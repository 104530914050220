import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {connect} from "react-redux";
import {withRouter} from "react-router";

const PublicRoute = ({ component: Component, isAuthenticated, memberships, props, ...rest}) => {
	let url = '/auth';

	if (isAuthenticated) {
		if (memberships.length > 1) {
			// @TODO: multiple tenant modal for user
			url = '/dashboard'
		} else if (memberships.length === 1) {
			url = rest?.location?.state?.from?.pathname ?? '/dashboard'
			const cookies = new Cookies();
			cookies.set('accountdomain', memberships[0]['subdomain'], {
				path: '/',
				expires: new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 7)
			});
		}
	}

	return (
		<Route
			{...rest}
			render={props_ => (
				isAuthenticated ?
					(<Redirect to={{ pathname: url, state: { from: props_.location} }} />)
					: (<Component {...props_} {...props} />)
			)}
		/>
	);
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		memberships: state.auth.memberships
	};
}

export default connect(mapStateToProps, null)(withRouter(PublicRoute));
