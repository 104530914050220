import React, {Fragment} from 'react';
import DatePicker from 'react-datepicker';
import CustomCalendarComponent from '../components/CustomCalendarComponent';

export function DueDate ({ register,value, disabled, maxDate,minDate, name, onChange, error}) {

	return (
		<Fragment>
			<input className={'input'}
				   type="hidden"
				   ref={register}
				   name={name}
				   value={value}
				   id={name} />
			<DatePicker
				disabled={disabled}
				value={value}
				maxDate={maxDate}
				minDate={minDate}
				customInput={ <CustomCalendarComponent
					ipDate={value}
					handleIpChange={(val)=>this.onChange(val)}
				/>}
				dropdownMode="select"
				selected={value}
				onChange={onChange}
			/>
			{error && <p className={'is-error'}>{error.message}</p>}
		</Fragment>
	);
}
