import React, { Component, Fragment } from "react";
import Select, { components } from "react-select";

//  CUSTOM STATUS OUTPUT
const customStatusOutput = ({ data }) => (
	<div className="react-status-selected">
		{data.bgColor ? (
			<div className="react-status-pill" style={{backgroundColor : data.bgColor }}><span style={{color : '#fff'}}>{data.label}</span></div>
		) : null}
	</div>
);

//  CUSTOM STATUS PLACHEOLDER
const customStatusPlaceholder = ({ data }) => (
	<div className="react-status-placeholder react-status-pill"><span>Please Select ...</span></div>
);

//  SELECT CUSTOM OPTIONS WITH ICONS
const customStatusOption = props => {
	const { data, innerProps } = props;
	console.log("Q" , data);
	return (
		<components.Option {...props} {...innerProps}>
			{data.bgColor ? (
				<div className="status-option">
					<span className="status-option__color" style={{borderColor : data.bgColor }} ></span>
					<span className="status-option__label">{data.label}</span>
				</div>
			) : null}
		</components.Option>
	);
};

export default class CustomStatusSelect extends Component {
	selectRef;

	render() {
	  const { defaultValue, options, onChange, value, onClear } = this.props;
	  if (!options) return null;

	  return (
		<Fragment>
			<Select
				ref={ref => (this.selectRef = ref)}
				defaultValue={defaultValue}
				options={options}
				value={value}

				components={{
					SingleValue: customStatusOutput,
					Placeholder: customStatusPlaceholder,
					Option: customStatusOption,
				}}

				onClear={() => {
					onClear();
					this.selectRef.blur();
				}}

				className={"custom-status-select"}
				classNamePrefix={"custom-status-select"}

				isSearchable={false}
				onChange={onChange}
			/>

		</Fragment>
	  );
	}
  }
