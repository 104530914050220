import request from '../shared/request';

export default class PermissionsService {

	static getRoles() {
		return request({
			url: `/permissions/roles`,
			method: 'GET',
		})
	}

	static getPermissions() {
		return request({
			url: `/permissions`,
			method: 'GET'
		})
	}

	static togglePermissionForRole(permissionId, roleId) {
		return request({
			url: `/permissions/toggle`,
			method: 'PATCH',
			data: {
				permissionId,
				roleId
			}
		})
	}

	static getPermissionsForUser(userId) {
		return request({
			url: `/permissions/user/${userId}`,
			method: 'GET'
		})
	}

	static togglePermissionForUser(userId, data) {
		return request({
			url: `/permissions/user/${userId}`,
			method: 'PUT',
			data
		})
	}

}
