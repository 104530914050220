import React, {Component, Fragment} from 'react';
import Dropzone from 'react-dropzone';
import Avatar from '../../components/Avatar';
import UploadService from '../../services/api/upload';
import UsersService from '../../services/api/users';
import cameraIcon from '../../assets/images/icons/i_camera.svg';
import loaderIcon from '../../assets/images/icons/i_loader.svg';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '50%',
		height: '50%',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		flexDirection: 'column'
	}
};
export default class ChangeAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			avatar: undefined,
			resource_id: this.props.currentUserID,
			resource_type: 'User',
			avatarKey: 0,
			userImage: null,
			cropImageModalIsOpen: false,
			crop: {
				aspect: 1,
				unit: '%',
				width: 100,
				height: 100
			},
			croppedImageUrl: null
		};

		this.onCropChange = this.onCropChange.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
	}

	getFiles() {
		this.setState({
			avatarKey: this.state.avatarKey + 1
		});
	}

	newAvatarFile(file) {
		const fileBlob = file.slice(0, file.size, file.type);
		const newAvatar = new File([fileBlob], 'profile.jpg', {
			type: file.type
		});

		return newAvatar;
	}

	onImageLoaded = image => {
		this.imageRef = image;
	};

	async addFile() {
		const newAvatar = new File([this.state.croppedImageUrl], 'profile.jpg', {
			type: 'image/*'
		});
		

		console.log(this.state.croppedImageUrl);
		console.log("avatar" , this.state.avatar);

		this.setState({isLoading: true});

		console.log(this.state.resource_id);

		UploadService.signResourceAndUpload(
			this.state.resource_type,
			this.state.resource_id,
			newAvatar
		).then(response => {
			console.log("res", response.url);
			this.getFiles();
			// this.setState({isLoading: false, cropImageModalIsOpen: false, avatar: response.url});
			// window.location.reload(true)
		}).catch((err) => {
			this.setState({isLoading: false, cropImageModalIsOpen: false});
		});

		UsersService.editUser(this.state.resource_id , {
			has_avatar: true
		}).then((res) => {
			this.setState({isLoading: false , cropImageModalIsOpen: false });
			// window.location.reload(true)
		}).catch(err => {
			this.setState({isLoading: false});
		});
	}

	onCropChange(crop, percentCrop) {
		this.setState({crop});
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(blob);
			}, 'image/*');
		});
	}

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'profile.jpeg'
			);
			this.setState({croppedImageUrl});
		}
	}

	render() {
		const icon = this.state.isLoading ? loaderIcon : cameraIcon;

		console.log("icon" , this.state);

		return (
			<Fragment>
				<Dropzone
					onDrop={acceptedFiles => {
						if (acceptedFiles?.length > 0 ?? false) {
							const reader = new FileReader();
							reader.addEventListener('load', () =>
								this.setState({
									userImage: reader.result,
									cropImageModalIsOpen: true
								})
							);
							reader.readAsDataURL(acceptedFiles[0]);
						}
					}
					}
					accept="image/*"
					multiple={false}
					disabled={this.state.isLoading}
				>
					{({getRootProps, getInputProps}) => (
						<div
							className="avatar-change-container"
							{...getRootProps()}
						>
							<input {...getInputProps()} />
							<Avatar
								alt={this.props.altText}
								src={this.state.avatar}
								width={112}
								height={112}
								userId={this.props.currentUserID}
								key={this.state.avatarKey}
								hasAvatar={this.props.hasAvatar}
							/>
							<div className={'avatar-change_icon-container'}>
								<div className="avatar-change_icon">
									<img
										src={icon}
										alt="Change avatar"
									/>
								</div>
							</div>
						</div>
					)}
				</Dropzone>
				<Modal
					isOpen={this.state.cropImageModalIsOpen}
					contentLabel="Modal"
					portalClassName="modal profile-image"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
					style={customStyles}
				>
					<Fragment>
						<ReactCrop
							imageStyle={{height: 400}}
							src={this.state.userImage}
							crop={this.state.crop}
							ruleOfThirds
							onImageLoaded={this.onImageLoaded}
							onComplete={this.onCropComplete}
							onChange={this.onCropChange}
						/>
						<div className="f-row btn-container">
							<button className="btn outline gainsboro" type={'button'}
											onClick={() => {
												this.setState({cropImageModalIsOpen: false});
											}}>Cancel
							</button>
							<button disabled={false} className="btn fill blue" type={'submit'}
											onClick={() => this.addFile()}>Save
							</button>
						</div>
					</Fragment>
				</Modal>
			</Fragment>
		);
	}
}