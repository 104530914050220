import React, {Component, Fragment} from 'react';
import {NavLink} from "react-router-dom";

export default class ProjectItemPlaceholder extends Component {

	render() {
		let items = this.props.items || 5;
		return(
			<Fragment>
				{[...Array(items).keys()].map(i =>
					<div key={i} className="morph-grid__item project-item">
						<div className="project-item__group alpha-beta-gamma">
							<div className="project-item__cell alpha" style={{backgroundColor: "#F3F3F3"}}>
								<NavLink to="/" className="project-item__company" style={{backgroundColor: "#F3F3F3"}}/>
							</div>
							<div className="project-item__cell beta" style={{backgroundColor: "#F3F3F3", width: '30%', height: 20, marginBottom: 12}}/>
							<div className="project-item__cell gamma">
								<div className="project-item__brief" style={{backgroundColor: "#F3F3F3", width: '50%', height: 20}}/>
							</div>
						</div>
						<div className="project-item__group epsilon-zeta">
							<div className="project-item__cell epsilon">
								<div className="project-item__owner">
									<NavLink to="/">
										<div style={{borderRadius: 50, backgroundColor: "#F3F3F3", width: 30, height: 30}}/>
									</NavLink>
								</div>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		)
	}

}
