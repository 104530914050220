import React, { Component, Fragment } from 'react'
import {NavLink} from 'react-router-dom'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import AddStatus from '../../../forms/AddStatusForm'
import Taxonomies from '../../../services/api/taxonomies'
import ConfirmDeleteStatusModal from "../../Modals/ConfirmDeleteStatusModal";
import _ from "lodash";
import StatusColorPicker from "../../../components/StatusColorPicker";

const DragHandle = SortableHandle(() =>
	<div className='far-icon sort-handle cursor-row-resize'>
		<i className="far  fa-bars"></i>
	</div>
)

const Status = SortableElement(({ details, onDelete, onEdit, onStatusSave, toEdit, editError, color, changeColor}) => {

	return (
		<div className='settings-list__item status-item'>
			<div className='settings-list__cell delta'>
				<DragHandle />
			</div>
			<div className='settings-list__cell alpha ai-c'>
				{toEdit && (toEdit.id === details.id) ?
					<form onSubmit={(e) => {e.preventDefault(); onStatusSave(e, details)}}>
						<input type={'hidden'} name="color" value={color}/>
						<StatusColorPicker color={color} onChange={data => {changeColor(data)}}/>
						<input
							type='text'
							className={`input ${editError ? 'is-error' : ''}`}
							placeholder='Type a skill name...'
							defaultValue={details.name}
							name='name'
						/>
						<button className='btn round fill blue save'>Save</button>
						<button className='btn round fill save' onClick={(e) => {e.preventDefault(); onEdit(null)}}>Cancel</button>
					</form>
					:
					<Fragment>
						<div className='status-style' style={{ '--color': details.color }}>
							{ details.name }
						</div>
						<button
							onClick={(e) => {e.preventDefault(); onEdit(details)}}
							className='btn simple icon edit small'
							style={{ marginLeft: '10px', marginTop: '6px'}}
						/>
					</Fragment>}
			</div>
			<div className='settings-list__cell beta'></div>
			<div className='settings-list__cell gamma'>
				{['primary', 'secondary'].indexOf(details.scope) === -1 &&
					<a href='#delete'
					   className='btn outline gray-blue'
					   onClick={(e) => onDelete(e, details) }>Delete</a>
				}
			</div>
		</div>
	)
});

const StatusList = SortableContainer(({ items, onDelete, onEdit, onStatusSave, toEdit, editError, color,changeColor }) => {
	return (
		<div className='settings-list__container'>
			{items.map((item, index) => (
				<Status key={item.id}
						index={index}
						details={item}
						onDelete={onDelete}
						onEdit={onEdit}
						onStatusSave={onStatusSave}
						toEdit={toEdit}
						editError={editError}
						color={color}
						changeColor={changeColor}/>
			))}
		</div>
	)
});

export default class Statuses extends Component {

	constructor() {
		super();
		this.state = {
			statuses: [],
			confirmBoxOpen: false,
			forDelete: {},
			toEdit: null,
			editError: null,
			color: '#ddd',
			affectedTickets: [],
			newStatusId: null
		};
		this.saveStatus = this.saveStatus.bind(this);
	}


	componentDidMount() {
		this.getStatuses()
	}

	toggleConfirm = () => {
		this.setState({
			confirmBoxOpen: !this.state.confirmBoxOpen
		})
	};

	getStatuses = () => {
		this.props.handleRoute();
		Taxonomies.get('statuses').then(response => {
			this.setState({
				statuses: response
			})
		}).catch((error) => {
			// @TODO: Show error notification
		})
	};

	persistStatus = (e,data) => {
		if (data) {
			const name = e.target.name.value;
			const color = e.target.color.value;
			Taxonomies.updateTaxonomy('statuses', data.id, {name, color}).then(response => {
				// e.target.reset()
				this.setState({toEdit: null})
				// @TODO: show notification
				this.getStatuses()
			}).catch(error => {
				// @TODO: show notification
			})
		} else {
			Taxonomies.create('statuses', e).then((response) => {
				this.getStatuses()
			})
		}
	}

	persistOrder = () => {
		const { statuses } = this.state;
		const ordered = statuses.map((item, index) => ({ id: item.id, order: index }))

		Taxonomies.updateOrder('statuses', ordered).then(response => {
			// @TODO: show success notification
		}).catch(error => {
			// @TODO: show error notification
		})
	}

	onSorted = ({oldIndex, newIndex}) => {
		this.setState(({statuses}) => ({
			statuses: arrayMove(statuses, oldIndex, newIndex),
		}), () => {
			this.persistOrder()
		})
	}

	editStatus = (details) => {
		this.setState({toEdit: details, color: details ? details.color : '#ddd'})
	}

	deleteStatus = (e, details) => {
		const { statuses } = this.state;

		Taxonomies.delete('statuses', details.id).then(response => {
			// No affected tickets, no box with details
			if(response.affected_tickets.length === 0) {
				if(window.confirm(`Remove '${details.name}' status?`))
					return Taxonomies.confirmDelete('statuses', details.id).then(() => {
						this.setState({
							statuses: statuses.filter(i => i.id !== details.id)
						});
					})
				return ;
			}

			window.scroll({
				top: 0,
				behavior: 'smooth'
			})

			this.setState({
				forDelete: details,
				confirmBoxOpen: true,
				affectedTickets: response.affected_tickets
			})
		})
	}

	confirmDeleteStatus = (e, details) => {
		// @TODO: send confirm message to API with selected status ID
	}

	saveStatus(e,data) {
		e.preventDefault();
		const name = e.target.name.value;
		if (_.isEmpty(name)) {
				this.setState({editError: 'Status name can not be empty.'});
		} else {
			this.setState({editError: null, toEdit: null});
			this.persistStatus(e, data)
		}
	}

	render() {
		const { statuses, confirmBoxOpen, forDelete, affectedTickets } = this.state

		return (
			<section className='page'>
				<div className='wrapper'>
					{confirmBoxOpen  && <ConfirmDeleteStatusModal confirmDelete={(data) => {this.confirmDeleteStatus(data)}}
																  forDelete={forDelete}
																  statuses={statuses}
																  affectedTickets={affectedTickets}
																  toggleConfirm={() => this.toggleConfirm()}/>}

					<h3 className='page-section-title'>
						Ticket statuses ({statuses.length})
					</h3>

					{this.props.addStatus && <AddStatus onSave={this.persistStatus} />}

					<StatusList
						items={statuses}
						onSortEnd={this.onSorted}
						onDelete={this.deleteStatus}
						onEdit={this.editStatus}
						onStatusSave={this.saveStatus}
						toEdit={this.state.toEdit}
						editError={this.state.editError}
						color={this.state.color}
						changeColor={(data) => this.setState({color: data})}
						useDragHandle
						lockAxis='y'
					/>
				</div>
			</section>
		)
	}

}
