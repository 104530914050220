import React, { useState} from 'react'
import _ from 'lodash'
import StatusColorPicker from "../components/StatusColorPicker";

export default function AddStatus({ onSave }) {
	const [name, setName] = useState('')
	const [color, setColor] = useState('#ddd')
	const [error, setError] = useState('')

	const handleSubmit = () => {
		if (_.isEmpty(name)) {
			setError('Type name for new status.')
		} else {
			setName('');
			setColor('#ddd')
			onSave({ color, name })
		}
	}

	return (
		<div className='settings-list__container' style={{ marginBottom: '40px' }}>
			<div className='settings-list__item status-item'>
				<StatusColorPicker color={color} onChange={data => setColor(data)}/>
				<div className='create-status__cell beta'>
					<div className='f-element'>
						<input
							type='text'
							className={`input ${error ? 'is-error' : ''}`}
							placeholder='Add Ticket Status'
							onChange={(e) => setName(e.target.value) }
							value={name}
						/>
					</div>
				</div>
				<div className='settings-list__cell gamma'>
					<button className='btn round fill blue' onClick={handleSubmit}>
						Save
					</button>
				</div>
			</div>
		</div>
	)
}

