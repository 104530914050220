import request from '../shared/request';
export default class DashboardService {


	static getDashboardActivities(params) {
		return request({
			url: `/dashboard`,
			method: 'GET',
			params
		})
	}
}
