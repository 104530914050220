import React, {Component} from 'react';
import PermissionsService from "../../../services/api/permissions";

const _ = require('lodash')

export default class Roles extends Component {

	constructor(props) {
		super(props);
		this.state = {
			roles: [],
			permissions: []
		}
	}

	getRoles() {
		PermissionsService.getRoles()
			.then(response => {
				this.setState({
					roles: response.data
				})
			})
			.catch(err => {

			})
	}

	getPermissions() {
		PermissionsService.getPermissions()
			.then(response => {
				this.setState({
					permissions: response.data
				})
			})
			.catch(err => {

			})
	}

	componentDidMount() {
		this.getRoles();
		this.getPermissions();
	}

	togglePermissionForRole(permission_id, role_id) {
		PermissionsService.togglePermissionForRole(permission_id, role_id)
			.then(response => {
				console.log(response);
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {
		return (
			<section className="page">
				<div className="wrapper">
					<div className="permissions_container desktop">
						<div className="permissions_head">
							<div className="permissions_row">
								<div className="permissions_col row-label"></div>
								{this.state.roles.map(role => {
									return <div className={`permissions_col col-${role.name}`} style={{textTransform: 'capitalize'}}>{role.name}</div>
								})}

							</div>
						</div>
						<div className="permissions_body">
							<div className="permissions_section">
								<div className="section-label">Name</div>
								{this.state.permissions.map(permission => {
									return (
										<div className="permissions_row">
											<div className="permissions_col row-label">{permission.description}</div>
											{this.state.roles.map(role => {
												let is_active = _(permission)
													.get('roles_permissions', [])
													.filter(({role_id, permission_id}) => _.every([
														_.includes([permission.id], permission_id),
														_.includes([role.id], role_id)
													]))
													.reduce((acc, cur) => cur, false);
												return (
													<div className={`permissions_col col-${role.name}`}>
														<input defaultChecked={is_active} onChange={() => this.togglePermissionForRole(permission.id, role.id)} id={`${role.id}-${permission.id}`} className={'checkbox'} type="checkbox" style={{display: 'initial'}}/>
													</div>
												)
											})}
										</div>
									)
								})}

							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

}
