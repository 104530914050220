import React, {Component, Fragment} from 'react';
import {NavLink} from "react-router-dom";

export default class TicketSummaryPlaceholder extends Component {

	render() {
		let items = this.props.items || 5;
		return (
			<Fragment>
				{[...Array(items).keys()].map(i =>
					<div key={i} className="morph-grid__item morph-ticket-item">
						<div className="morph-ticket-item__group alpha-beta">
							<div className="morph-ticket-item__cell alpha">
								<div className="morph-ticket-item__status custom-bullet-status-select" >
									{/* <TicketStatus statusName={"closed"} statusColor={"#8F95B3"} statusId={"id"} /> */}
									<div style={{backgroundColor: '#F3F3F3', width: 20, height: 20, borderRadius: 10}}/>

								</div>
							</div>
							<div className="morph-ticket-item__cell beta" style={{display: 'flex', alignItems: 'center'}}>
								<div style={{width: 200, backgroundColor: '#F3F3F3', height: 20}}/>
							</div>
						</div>
						<div className="morph-ticket-item__group gamma-delta-epsilon">
							<div className="morph-ticket-item__cell gamma">
								<NavLink to="/projects/call-card" className="morph-ticket-item__project">{}</NavLink>
							</div>
							<div className="morph-ticket-item__cell delta" style={{display: 'flex', alignItems: 'center'}}>
								<div className="morph-ticket-item__due-date" style={{backgroundColor: '#F3F3F3', width: '100%', height: 20}}>
									<div className="dropdown">
										<div role="button" className="dropdown__trigger">
											<span className="pointer"/>
										</div>
										<div className="dropdown__content top-center"/>
									</div>
								</div>
							</div>
							<div className="morph-ticket-item__cell epsilon">
								<div className="morph-ticket-item__asignee">
									<div className="dropdown">
										<div role="button" className="dropdown__trigger">
											<div className="profile-label">
												<div className="row-avatar">
													<div style={{borderRadius: 50, backgroundColor: "#F3F3F3", width: 30, height: 30}}/>
												</div>
											</div>
										</div>
										<div className="dropdown__content top-center"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}
