import React, {Component} from 'react';
import PillsSelector from "../components/PillsSelector";

export default class SkillsFilterForm extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleOnSubmit = this.handleOnSubmit.bind(this);
	}

	handleChange(e) {

		console.log('value', e);

		this.props.onFilterChange({value: e.join(',')});

	}
	handleOnSubmit(e) {
		e.preventDefault();
		this.props.closeModal();
	}

	render() {
		let checkedItems = this.props.skillFilters.value?.length > 0 ? this.props.skillFilters.value.split(',') : [];
		return (<form onSubmit={this.handleOnSubmit}>

				<PillsSelector
					multi={true}
					grouped={true}
					groups={this.props.skillGroups}
					onChange={(event)=> {this.handleChange(event)}}
					options={this.props.skills}
					value={checkedItems}/>

				<div className="f-row btn-container">
					<button
						className={`btn fill blue is-animated ${this.props.loading ? 'active' : ''}`}
						type={'submit'}>
						{this.props.loading ?
							<span className="animate-loading"><span
								className="circle"/></span>
							:
							<span className="btn-label">{this.props.peopleFiltered} Found</span>
						}
					</button>
				</div>
			</form>
		)
	}
}

