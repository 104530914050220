import React, {Component, Fragment} from 'react';
import {Route, Switch} from "react-router";
import List from "./../../../shared/TicketsList";
import TicketItemExpanded from "./../../../../components/Ticket/TicketItemExpanded";

export default class Tickets extends Component {

	render() {
		return (
			<Fragment>
				<Switch>
					<Route exact path={this.props.match.url} component={(props) => <List {...props} isProjectList={true} project={this.props.project}/>}/>
					<Route path={`${this.props.match.url}/:id`} component={(props) => <TicketItemExpanded {...props} project={this.props.project} />}/>
				</Switch>
			</Fragment>
		);
	}
}




