import React, {Component} from 'react';
import {SketchPicker} from 'react-color';

export default class StatusColorPicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pickerActive: false
		};
		this.windowClick = this.windowClick.bind(this);
	}

	componentDidMount() {
		if (window) {
			window.addEventListener('click', this.windowClick, false);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.windowClick, false);
	}

	windowClick(event) {
		if (event.target.classList.contains('searchable-marker') || event.target.classList.contains('cursor-pointer')) {
			event.preventDefault();
			return;
		} else {
			this.setState({
				pickerActive: false
			});
		}
	}

	render() {
		const {pickerActive, color} = this.state;
		return (
			<div className='settings-list__cell alpha status-item'>
				<div
					className='status-style cursor-pointer'
					style={{'--color': this.props.color}}
					onClick={() => this.setState({pickerActive: !pickerActive})}/>
				{pickerActive &&
				<div className='status-color-picker'>
					<SketchPicker
						onChange={(c) => this.props.onChange(c.hex)}
						presetColors={[]}
						disableAlpha={true}
						color={this.props.color}
					/>
				</div>
				}
			</div>
		);
	}
}
