import React, {useState} from 'react';
import useForm from 'react-hook-form';
import {isEmailAddress, required} from "../inputs/validations";
import {Input} from "../inputs/Input";
import {SelectInput} from '../inputs/SelectInput';
import UsersService from "../services/api/users";

export default function NewPeopleForm(props) {
	const { handleSubmit, errors, register, setError } =  useForm({
		mode: "onChange"
	});

	const [inputs, setInputs] = useState({});
	const [peopleType, setPeopleType] = useState('client');

	const onSubmit = data => {
		data.role = peopleType;
		UsersService.inviteUser(data).then(res => {
				props.onSuccess()
			})
			.catch(e => {
				console.log(e);
				setError('email', 'submission', e.data?.message)
			})
	};

	const handleInputChange = (event, name) => {
		setInputs(inputs => ({...inputs, [name]: event}));
	};


	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="tabs-component">
				<ul role="tablist" className="custom-tab-links is-full bottom-margin">
					<li className={`custom-tab-link ${peopleType === 'client' ? 'is-active' : ''}`}>
						<button type="button" className={`btn link ${peopleType === 'client' ? 'is-active' : ''}`} onClick={() => {setPeopleType('client')}}>Client</button>
					</li>
					<li className={`custom-tab-link ${peopleType === 'employee' ? 'is-active' : ''}`}>
						<button type="button" className={`btn link ${peopleType === 'employee' ? 'is-active' : ''}`} onClick={() => {setPeopleType('employee')}}>Employee</button>
					</li>
					<li className={`custom-tab-link ${peopleType === 'freelancer' ? 'is-active' : ''}`}>
						<button type="button" className={`btn link ${peopleType === 'freelancer' ? 'is-active' : ''}`} onClick={() => {setPeopleType('freelancer')}}>Freelancer</button>
					</li>
					<li className={`custom-tab-link ${peopleType === 'administrator' ? 'is-active' : ''}`}>
						<button type="button" className={`btn link ${peopleType === 'administrator' ? 'is-active' : ''}`} onClick={() => {setPeopleType('administrator')}}>Administrator</button>
					</li>
				</ul>
			</div>
			{peopleType === 'client' ?
				<section className={"custom-tab-content is-active"}>
					<div className="f-section">
						<div className="f-row">
							<div className="f-element">
								<Input type="text"
									   register={register({validate: required})}
									   error={errors.first_name}
									   name="first_name"
									   placeholder={"First name"}
								/>
							</div>
							<div className="f-element">
								<Input type="text"
									   register={register({validate: required})}
									   error={errors.last_name}
									   name="last_name"
									   placeholder={"Last name"}
								/>
							</div>
						</div>
						<div className="f-row">
							<div className="f-element">
								<Input type="text"
									   register={
										   register({
											   validate: {isEmailAddress, required},
										   })
									   }
									   error={errors.email}
									   name="email"
									   placeholder={"Email"}
								/>
							</div>
						</div>

						<div className="f-row">
							<div className="f-element">
								<SelectInput value={inputs.company_id}
											 register={
												 register({
													 validate: peopleType === "client" && required,
												 })
											 }
											 name={"company_id"}
											 error={errors.company_id}
											 options={props.companies}
											 onChange={(event)=> handleInputChange(event, 'company_id')}/>

							</div>
						</div>

						<div className="f-row btn-container">
							<input type="submit"
								   name="commit"
								   value="Send invitation"
								   data-disable-with="Send invitation"
								   className="btn fill blue"/>
						</div>
					</div>
				</section>
				:
			<section className={"custom-tab-content is-active"}>
				<div className="f-section">
					<div className="f-row">
						<div className="f-element">
							<Input type="text"
								   register={register({validate: required})}
								   error={errors.first_name}
								   name="first_name"
								   placeholder={"First name"}
							/>
						</div>
						<div className="f-element">
							<Input type="text"
								   register={register({validate: required})}
								   error={errors.last_name}
								   name="last_name"
								   placeholder={"Last name"}
							/>
						</div>
					</div>
					<div className="f-row">
						<div className="f-element">
							<Input type="text"
								   register={
									   register({
										   validate: {isEmailAddress, required},
									   })
								   }
								   error={errors.email}
								   name="email"
								   placeholder={"Email"}
							/>
						</div>
					</div>

					<div className="f-row btn-container">
						<input type="submit"
							   name="commit"
							   value="Send invitation"
							   data-disable-with="Send invitation"
							   className="btn fill blue"/>
					</div>
				</div>
			</section>}
		</form>
	)
}
