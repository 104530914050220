import React, {Component} from 'react';
import CustomSelect from '../../components/CustomSelect';
import ProjectsService from '../../services/api/projects';
import SubNavigation from './SubNavigation';
import ProjectItemPlaceholder from '../../placeholders/ProjectItem';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeProjectsViewType} from '../shared/actions';
import AddNewProjectItem from '../../placeholders/AddNewProjectItem';
import NewProjectModal from '../Modals/NewProjectModal';
import Modal from 'react-modal';
import {getFilters} from '../../helpers/filters';
import {ProjectItem} from './Project/ProjectItem';
import {ProjectViewButton, ViewMenu} from '../../new-components';

class ProjectsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			viewType: 'list-view',
			newProjectModalIsOpen: false,
			filters: getFilters({
				status: {label: 'My projects', value: 'active'},
				order_by: {label: 'Order by title', value: null},
				order_type: {label: 'Direction', value: 'asc'},
				group_by: {label: 'Grouped', value: null}
			}),
			currentSearchLocation: window.location.search,
			projects: [],
			projectsGroup: {}
		};
	}

	componentDidMount() {
		this.getProjects();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (window.location.search !== this.state.currentSearchLocation) {
			this.setState({
				filters: getFilters({
					status: {label: 'My projects', value: 'active'},
					order_by: {label: 'Order by title', value: null},
					order_type: {label: 'Direction', value: 'asc'},
					group_by: {label: 'Grouped', value: null}
				}),
				currentSearchLocation: window.location.search,
				projects: []
			}, () => this.getProjects());
		}
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters
		}, () => this.getProjects());
	}

	getProjects() {
		ProjectsService.listProjects({
			related: 'owner,company',
			filters: this.state.filters
		})
			.then(res => {
				if (this.state.filters.group_by.value) {
					let object = this.groupProjects(res);
					this.setState({projectsGroup: object});
				}
				this.setState({
					projects: res,
					loading: false
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	groupProjects(data) {
		let projectsCategories = {};
		let projects = [...data];
		projects.forEach((project) => {
			let category;
			if (this.state.filters.group_by.value === 'owner') {
				category = project.owner.first_name + ' ' + project.owner.last_name || 'undefined';
			} else if (this.state.filters.group_by.value === 'company') {
				category = project.company.name;
			} else
				return; //to do others category
			if (!projectsCategories[category]) {
				projectsCategories[category] = [];
			}
			projectsCategories[category].push(project);
		});
		return projectsCategories;
	}

	handleClose = () => {
		this.setState({newProjectModalIsOpen: false});
	};

	handleChangeProjectsViewType = (type) =>
		() => {
			this.props.changeProjectsViewType(type);
		};

	render() {
		return <>
			<SubNavigation title="My Projects"/>
			<div className="secondary-bottom">
				<div className="wrapper">
					<ul className="filter-container">
						<CustomSelect
							name="status"
							className="filter"
							value={this.state.filters.status}
							onChange={this.onFilter.bind(this, 'status')}
							options={statusOptions}
						/>
						<CustomSelect
							name="order_by"
							className="filter"
							value={this.state.filters.order_by}
							onChange={this.onFilter.bind(this, 'order_by')}
							options={orderByOptions}
						/>
						<CustomSelect
							name="order_type"
							className="filter"
							value={this.state.filters.order_type}
							onChange={this.onFilter.bind(this, 'order_type')}
							options={orderTypeOptions}
						/>
						<CustomSelect
							name="group_by"
							className="filter"
							value={this.state.filters.group_by}
							onChange={this.onFilter.bind(this, 'group_by')}
							options={groupByOptions}
						/>
					</ul>
				</div>
			</div>
			<section className="page">
				<div className="wrapper">
					<ViewMenu
						view={this.props.preferences.projectsView}
						onChangeProjectViewType={this.handleChangeProjectsViewType}
					/>
					{this.state.filters.group_by.value ?
						<>
							{Object.keys(this.state.projectsGroup).map((category) =>
								<div className="list-container">
									<h3>{category}</h3>
									<div
										className="inner-container">
										<div
											className={`morph-grid__container ctrl ${this.props.preferences.projectsView}`}
										>
											{!this.state.projectsGroup[category] &&
											<ProjectItemPlaceholder
												items={this.props.preferences.projectsView === 'list-view' ? 5 : 6}/>
											}
											{this.state.projectsGroup[category] && this.state.projectsGroup[category].map(project =>
												<ProjectItem project={project}/>)}
											{this.props.preferences.projectsView !== 'list-view' &&
											<AddNewProjectItem
												onClick={() => this.setState({newProjectModalIsOpen: true})}
											/>
											}
										</div>
									</div>
								</div>
							)
							}
						</>
						:
						<div className="list-container">
							<div className="inner-container">
								<div
									className={`morph-grid__container ctrl ${this.props.preferences.projectsView}`}
								>
									{this.state.loading &&
									<ProjectItemPlaceholder
										items={this.props.preferences.projectsView === 'list-view' ? 5 : 6}
									/>
									}
									{this.state.projects && this.state.projects.map(project =>
										<ProjectItem project={project}/>)}
									{this.props.preferences.projectsView !== 'list-view' &&
									<AddNewProjectItem
										onClick={() => this.setState({newProjectModalIsOpen: true})}
									/>
									}
								</div>
							</div>
						</div>
					}
				</div>
			</section>
			<Modal
				isOpen={this.state.newProjectModalIsOpen}
				onRequestClose={this.handleClose}
				contentLabel='Modal'
				portalClassName='modal'
				overlayClassName='modal__overlay'
				className='modal__content'
				bodyOpenClassName='modal--is-open'
				htmlOpenClassName='prevent-scroll'
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={true}
			>
				<NewProjectModal closeModal={this.handleClose}/>
			</Modal>
		</>;
	}
}

const statusOptions = [
	{
		label: 'My projects',
		value: 'active'
	},
	{
		label: 'All projects',
		value: null
	},
	{
		label: 'Archived projects',
		value: 'archived'
	}
];
const orderByOptions = [
	{
		label: 'Order by title',
		value: null
	},
	{
		label: 'By date added',
		value: 'created_at'
	},
	{
		label: 'By activity',
		value: 'activity'
	}
];
const orderTypeOptions = [
	{
		label: 'Direction',
		value: null
	},
	{
		label: 'First to last',
		value: 'asc'
	},
	{
		label: 'Last to first',
		value: 'desc'
	}
];
const groupByOptions = [
	{
		label: 'Grouped',
		value: null
	},
	{
		label: 'By activity',
		value: 'activity'
	},
	{
		label: 'By company',
		value: 'company'
	},
	{
		label: 'By owner',
		value: 'owner'
	}
];

function mapStateToProps(state) {
	return {
		preferences: state.preferences
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({changeProjectsViewType}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
