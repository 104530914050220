import React, {Component, Fragment} from 'react';
import svgCompany from "../../assets/images/defaultcompany.svg"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import companies from "../../services/api/companies";
import UploadService from "../../services/api/upload";
import { v4 as uuidv4 } from 'uuid'
import getCompanyImage from '../../components/helpers/getCompanyImage'
import defaultAvatar from '../../assets/images/default-avatar.png'

export default class NewCompanyModal extends Component {
	constructor(props) {
		super(props);
		this.options = countryList().getData();
		this.state = {
			options: this.options,
			name: null,
			description: null,
			phone_number: "",
			location: {
				city: "",
				state: "",
				address: "",
				country: "",
			},
			legal_info: {
				name: "",
				vat_number: "",
				registration_number: "",
			},
			payment_info: {
				currency: "",
				bank_name: "",
				swift_code: "",
				account_number: "",
			},
			contact_info: {
				email: "",
				skype: "",
				website: "",
				last_name: "",
				first_name: "",
				phone_number: "",
			},
			avatar_id: null,
			avatarSrc: svgCompany,
			...props.company,
			// avatar_company: {
			// 	...props.avatar
			// }
		}
	}

	onChangeLocation(name, event) {
		this.setState({
			location: {
				...this.state.location,
				[name]: event.target.value
			}
		})
	}

	onChangePaymentInfo(name, event) {
		this.setState({
			payment_info: {
				...this.state.payment_info,
				[name]: event.target.value
			}
		})
	}

	onChangeContactInfo(name, event) {
		this.setState({
			contact_info: {
				...this.state.contact_info,
				[name]: event.target.value
			}

		})
	}

	onChangeLegalInfo(name, event) {
		this.setState({
			legal_info: {
				...this.state.legal_info,
				[name]: event.target.value
			}

		})
	}

	onChangeInputDefault(name, event) {
		this.setState({
			[name]: event.target.value
		})
	}

	async handleFileInput(files) {
		const avatar_id = this.state.avatar_id || uuidv4()
		if(!files[0]) return
		try {
			await UploadService.signResourceAndUpload(
				'Company',
				avatar_id,
				files[0]
			)
			const avatarSrc = await getCompanyImage(avatar_id)
			await companies.editCompany(this.state.id, {avatar_id})

			this.setState({
				avatar_id,
				avatarSrc
			})
		} catch (e) {
			console.error(e)
		}
	}

	onSubmit() {
		const {
			name,
			description,
			contact_info,
			location,
			legal_info,
			payment_info
		} = this.state

		const data = {
			name,
			description,
			location,
			legal_info,
			payment_info,
			contact_info,
			phone_number: contact_info.phone_number
		}
		this.props.closeModal()
		companies.editCompany(this.state.id, data)
		.then((res) => {
			this.props.onUpdate()
		}).catch(e => console.log(e))
	}

	async componentDidMount() {
		const {avatar_id} = this.state

		if (avatar_id) {
			const avatarSrc = await getCompanyImage(avatar_id)

			this.setState({
				avatarSrc
			})
		}
	}
	handleClose = () => {
		this.props.onUpdate()
		this.props.closeModal()
	}

	render() {
		const { avatarSrc } = this.state
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container">
						<span onClick={this.handleClose}
							  className="e-modal__close">
						</span>
						<div className="new-company">
							<div className="e-modal__head">
								<span
									className="deco size36 round icon edit">
								</span>
								<h2 className="e-modal__title">Edit
									"{this.state.name}"</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									<div className="f-section">
										<div className="re-avatar-upload">
											<div
												className="f-row is-h-centered">
												<img
													src={avatarSrc}
													className="re-avatar-preview"
													onError={(ev) => ev.target.src = svgCompany}
												/>
											</div>

											<div
												className="f-row btn-container">
												<label
													htmlFor="companyAvatar"
													className="btn outline blue">Change
													photo

													<input type="file"
														   id="companyAvatar"
														   onChange={(event) => this.handleFileInput(event.target.files)}/>

												</label>
											</div>
										</div>
									</div>
									<div className="f-section">
										<h3 className="f-section__title">
											Company Information</h3>
										<div className="f-row">
											<div className="f-element">
												<label>Company name</label>
												<input
													type="text"
													placeholder="Name"
													name="name"
													className=""
													aria-required="true"
													aria-invalid="false"
													onChange={(event) => this.onChangeInputDefault('name', event)}
													value={this.state.name}
												/>
											</div>
										</div>
										<div className="f-row">
											<div className="f-element">
												<label>Description</label>
												<textarea name="description"
														  className=""
														  aria-required="true"
														  aria-invalid="false"
														  onChange={(event) => this.onChangeInputDefault('description', event)}
														  value={this.state.description}
												/>

											</div>
										</div>
										<div className="f-row">
											<div className="f-element is-full">
												<label>Address</label>
												<textarea
													onChange={(event) => this.onChangeLocation('address', event)}
													value={this.state.location.address}
												>
												</textarea>
											</div>
											<div className="f-element is-third">
												<input placeholder="City"
													   type="text"
													   onChange={(event) => this.onChangeLocation('city', event)}
													   value={this.state.location.city}

												/>
											</div>
											<div className="f-element is-third">
												<input placeholder="State"
													   type="text"
													   onChange={(event) => this.onChangeLocation('state', event)}
													   value={this.state.location.state}

												/>
											</div>
											<div className="f-element is-third">
												<Select
													options={this.state.options}
													value={this.state.options.find((item => {
														return item.label === this.state.location.country
													}) )}
													onChange={(event) =>
														this.setState({
															location: {
																...this.state.location,
																country: event.label
															},
														})
													}
												/>
											</div>
										</div>
										<div className="f-section">
											<h3 className="f-section__title">Contact
												Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>First name</label>
													<input
														placeholder="First Name"
														type="text"
														value={this.state.contact_info.first_name}
														onChange={(event) => this.onChangeContactInfo('first_name', event)}/>

												</div>
												<div className="f-element">
													<label>Last name</label>
													<input
														placeholder="Last Name"
														type="text"
														value={this.state.contact_info.last_name}
														onChange={(event) => this.onChangeContactInfo('last_name', event)}/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Email</label>
													<input
														placeholder="Email"
														type="text"
														value={this.state.contact_info.email}
														onChange={(event) => this.onChangeContactInfo('email', event)}
													/>
												</div>
												<div className="f-element">
													<label>Website</label>
													<input
														placeholder="Website"
														type="text"
														value={this.state.contact_info.website}
														onChange={(event) => this.onChangeContactInfo('website', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Phone Number</label>
													<input
														placeholder="Phone Number"
														type="text"
														value={this.state.contact_info.phone_number}
														onChange={(event) => this.onChangeContactInfo('phone_number', event)}
													/>
												</div>
												<div className="f-element">
													<label>Skype</label>
													<input
														placeholder="Skype"
														type="text"
														value={this.state.contact_info.skype}
														onChange={(event) => this.onChangeContactInfo('skype', event)}
													/>
												</div>
											</div>
										</div>
										<div className="f-section"><h3
											className="f-section__title">Legal
											Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>Company legal
														name</label>
													<input
														placeholder=""
														type="text"
														value={this.state.legal_info.name}
														onChange={(event) => this.onChangeLegalInfo('name', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>EIN/J/Registration
														Number</label>
													<input
														placeholder=""
														type="text/"
														value={this.state.legal_info.registration_number}
														onChange={(event) => this.onChangeLegalInfo('registration_number', event)}

													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>VAT number</label>
													<input
														placeholder=""
														type="text"
														value={this.state.legal_info.vat_number}
														onChange={(event) => this.onChangeLegalInfo('vat_number', event)}

													/>
												</div>
											</div>
										</div>
										<div className="f-section"><h3
											className="f-section__title">Payment
											Information</h3>
											<div className="f-row">
												<div className="f-element">
													<label>IBAN/ABA Account
														Number</label>
													<input
														placeholder=""
														type="text"
														value={this.state.payment_info.account_number}
														onChange={(event) => this.onChangePaymentInfo('account_number', event)}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>Bank name</label>
													<input
														placeholder=""
														type="text"
														onChange={(event) => this.onChangePaymentInfo('bank_name', event)}
														value={this.state.payment_info.bank_name}
													/>
												</div>
											</div>
											<div className="f-row">
												<div className="f-element">
													<label>SWIFT Code</label>
													<input
														placeholder=""
														type="text"
														value={this.state.payment_info.swift_code}
														onChange={(event) => this.onChangePaymentInfo('swift_code', event)}
													/>
												</div>
												<div className="f-element">
													<label>Currency</label>
													<input
														placeholder=""
														type="text"
														value={this.state.payment_info.currency}
														onChange={(event) => this.onChangePaymentInfo('currency', event)}
													/>
												</div>
											</div>
										</div>
										<div className="f-section">
											<div
												className="f-row btn-container">
												<button
													className="btn fill blue"
													onClick={() => this.onSubmit()}
												>
													Edit Company
												</button>
												<button
													onClick={() => this.props.closeModal()}
													className="btn outline gainsboro">Close
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
