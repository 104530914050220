import React from 'react';
import moment from 'moment';
import {connect} from "react-redux";
const _ = require('lodash');

class CustomCalendarComponent extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			timeFormat: _.get(this.props.user, 'meta.preferences.date_format', 'custom')
		}
	}

	getDiffString(diff){
		let sign = Math.sign(diff._milliseconds);
		let years =  Math.abs(diff.asYears());
		let months = Math.abs(diff.asMonths());
		let days = Math.abs(diff.asDays());
		if(years < 1) {
			if (months < 1) {
				if( Math.floor(days) === 0 ) {
					return 'due today';
				} else {
					return sign === -1 ? 'due ' + parseInt(days) + (days === 1 ? ' day' : ' days') + ' ago' : 'due in ' + parseInt(days) + (days === 1 ? ' day' : ' days');
				}
			} else {
				return sign === -1 ? 'due ' + parseInt(months) + (parseInt(months) === 1 ? ' month' : ' months') + ' ago' : 'due in ' + parseInt(months) + (parseInt(months) === 1 ? ' month' : ' months');
			}
		} else {
			return sign === -1 ? 'due ' + parseInt(years) + (parseInt(years) === 1 ? ' year' : ' years') + ' ago' : 'due in ' + parseInt(years) + (parseInt(years) === 1 ? ' year' : ' years');
		}
	}

	render() {
		let currentDay = new Date().setHours(0,0,0,0);
		let dueDay = new Date(this.props.ipDate).setHours(0,0,0,0)
		if (this.props.ipDate) {
			let diff = moment.duration(moment(dueDay).diff(moment(currentDay)));
			let date = this.state.timeFormat === 'custom' ? this.getDiffString(diff) : moment(this.props.ipDate).format("DD/MM/YYYY");
			let styleBorderDate = moment(this.props.ipDate).format('DD MMM');
			return (
				this.props.styleBorder ? 
				<div onClick={this.props.onClick} style={{cursor:'pointer' , backgroundColor:'#f2f3f5'}} className="rounded-full font-semibold py-1 px-2">
					<span className="text-gray_blue" style={{fontSize:'11px' , lineHeight:'22px' , fontFamily:'SFUITextMedium'}}>{styleBorderDate}</span>
				</div>
				:
				<span style={{cursor: 'pointer'}} onClick={this.props.onClick}>{date}</span>
			)
		} else {
			return (<button type={'button'} className="far-icon circle outline gray hover custom-select-label" onClick={this.props.onClick}><i className="far fa-calendar"></i></button>)
		}
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	}
}

export default connect(mapStateToProps, null)(CustomCalendarComponent)
