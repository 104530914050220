import React, { Component, Fragment } from 'react';
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from "react-sortable-hoc";
import _ from "lodash";

const DragHandle = SortableHandle(() =>
	<div className='far-icon sort-handle cursor-row-resize'>
		<i className="far  fa-bars" />
	</div>
);

const Skill = SortableElement(({ details, toEdit, editError, onDelete, onEdit, onSkillSave, children }) => {
	return (
		<div key={details.id} className='skill-item'>
			{toEdit && (toEdit.id === details.id) ?
				<div className='skill-new-item'>
					<form onSubmit={(e) => {e.preventDefault(); onSkillSave(e, details)}}>
						<input
							type='text'
							className={`input ${editError ? 'is-error' : ''}`}
							placeholder='Type a skill name...'
							defaultValue={details.name}
							name='name'
						/>
						<button className='btn round fill blue save'>Save</button>
						<button className='btn round fill save' onClick={(e) => {e.preventDefault(); onEdit(null)}}>Cancel</button>
					</form>
				</div>
					:
				<Fragment>
					<DragHandle/>
					<span>{details.name}</span>
					<button
						onClick={(e) => {e.preventDefault(); onEdit(details)}}
						className='btn icon edit small'
						style={{ marginLeft: '10px', background: 'transparent', paddingTop: '3px'}}
					/>
					<button
						onClick={(e) => {e.preventDefault(); onDelete(e, details, 'skill')}}
						className='btn icon close small'
						style={{ marginLeft: '10px', background: 'transparent', paddingTop: '3px'}}
					/>
				</Fragment>
				}
		</div>
	)
});

const SkillList = SortableContainer(({ items, onDelete, onEdit, toEdit, editError, onSkillSave }) => {
	return (
		<div>
			{items.map((item,index) => {
				return (<Skill
					key={item.id}
					index={index}
					details={item}
					onDelete={onDelete}
					onEdit={onEdit}
					toEdit={toEdit}
					editError={editError}
					onSkillSave={onSkillSave}/>)
			})}
		</div>
	)
});

export default class Skills extends Component {

	constructor() {
		super();
		this.sort = this.sort.bind(this);
		this.state = {
			error: null,
			toEdit: null
		}
		this.onEditSkill = this.onEditSkill.bind(this);
		this.saveSkill = this.saveSkill.bind(this);
	}


	sort(data){
		data.skills = this.props.skills;
		this.props.onSortSkillsEnd(data)
	}

	saveSkill(e,data) {
		e.preventDefault();
		const name = e.target.name.value;
		if (_.isEmpty(name)) {
			if (data) {
				this.setState({editError: 'Skill name can not be empty.'});
			} else {
				this.setState({error: 'Type new skill name.'});
			}

		} else {
			this.setState({error: null, editError: null, toEdit: null});
			this.props.onSkillSave(this.props.skillGroup.id, e, data)
		}
	}

	onEditSkill(details){
		this.setState({
			toEdit: details,
		})
	}

	render() {

		return (
			<div className='skill-list'>

				<SkillList
					items={this.props.skills}
					onSortEnd={this.sort}
					useDragHandle
					lockToContainerEdges={true}
					lockOffset={"0%"}
					helperClass={"order-selected-item"}
					lockAxis='y'
					toEdit={this.state.toEdit}
					editError={this.state.editError}
					onDelete={this.props.onDelete}
					onEdit={this.onEditSkill}
					onSkillSave={this.saveSkill}/>

				<div className='skill-new-item'>
					<form onSubmit={(e) => {this.saveSkill(e)}}>
						<input
							type='text'
							style={{width: 168}}
							className={`input ${this.state.error ? 'is-error' : ''}`}
							placeholder='Type a skill name...'
							name='name'
						/>
						<button className='btn round fill blue save'>Save</button>
					</form>
				</div>
			</div>

		)
	}

}
