import React, {Fragment} from 'react';
import HtmlEditor from "../components/HtmlEditor";


export function HtmlInput ({ register,value, initialValue, name, onChange, error, placeholder}) {
	return (
		<Fragment>
			<input className={'input'}
				   type="hidden"
				   ref={register}
				   name={name}
				   value={value}
				   id={name} />
			<HtmlEditor placeholder={placeholder} onChange={(event) => {onChange(event)}} html={initialValue} error={error}/>
			{error && <p className={'is-error'}>{error.message}</p>}
		</Fragment>
	);
}
