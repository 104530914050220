import React, {Component, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import List from "./List";
import Project from "./Project";

export default class Projects extends Component {

	render() {
		return (
			<Fragment>
				<Switch>
					<Route exact path={this.props.match.url} component={List}/>
					<Route path={`${this.props.match.url}/:slug`} component={Project}/>
				</Switch>
			</Fragment>
		)
	}
}




