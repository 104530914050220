import React, { Component, useState } from 'react';
import Taxonomies from '../../../services/api/taxonomies';
import _ from "lodash";
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import SkillList from "./SkillList";
import ConfirmDeleteSkillModal from "../../Modals/ConfirmDeleteSkillModal";

function AddSkill({ onSave }) {
	const [name, setName] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = () => {
		if (_.isEmpty(name)) {
			setError('Type name for new group.')
		} else {
			setName('')
			onSave({ name })
		}
	}

	return (
		<div className='settings-list__container' style={{ marginBottom: '40px' }}>
			<div className='settings-list__item status-item form'>
				<div className='create-status__cell alpha'>
					<div className='f-element'>
						<input
							type='text'
							className={`input ${error ? 'is-error' : ''}`}
							placeholder='Create skill group...'
							onChange={(e) => setName(e.target.value) }
							value={name}
						/>
					</div>
				</div>
				<div className='settings-list__cell gamma'>
					<button className='btn round fill blue' onClick={handleSubmit}>
						Save
					</button>
				</div>
			</div>
		</div>
	)
}

const byGroupSkills = (groupId, list) => {
	return list.filter(item => item.parent_id === groupId);
}

const getSkillGroups = (list) => {
	const groups = list.filter(item => _.isNil(item.parent_id));
	return _.sortBy(
		_.uniqBy(groups, 'id'), 'order'
	)
}

const DragHandle = SortableHandle(() =>
	<div className='far-icon sort-handle cursor-row-resize'>
		<i className="far  fa-bars" />
	</div>
);

const SkillGroup = SortableElement(({ details, onDelete, onSkillSave, onEditGroupName, onSaveGroupName, editError, toEdit, onSortSkillsEnd, children }) => {
	return (
		<div className='settings-list__item skill-item'>
			<div className='settings-list__cell delta'>
				<DragHandle />
			</div>
			<div className='settings-list__cell alpha'>
				{toEdit && toEdit.id === details.id ?

					<div className='skill-new-item'>
						<form className={"no-margin"} onSubmit={(e) => {e.preventDefault(); onSaveGroupName(e, details)}}>
							<input
								type='text'
								className={`input ${editError ? 'is-error' : ''}`}
								placeholder='Type a skill name...'
								defaultValue={details.name}
								name='name'
							/>
							<button className='btn round fill blue save'>Save</button>
							<button className='btn round fill outline save' onClick={(e) => {e.preventDefault(); onEditGroupName(null)}}>Cancel</button>
						</form>
					</div>
					:
					<div className='skill-group-name'>
						{ details.name } ({children.length})
						<button
							onClick={(e) => {e.preventDefault(); onEditGroupName(details)}}
							className='btn icon edit small'
							style={{ marginLeft: '10px', background: 'transparent'}}
						/>
					</div>
				}
				<SkillList skills={children} onSortSkillsEnd={onSortSkillsEnd} onSkillSave={onSkillSave} skillGroup={details} onDelete={onDelete}/>
			</div>
			<div className='settings-list__cell gamma'>
				<a href='#delete'
				   className='btn outline gray-blue'
				   onClick={(e) => onDelete(e, details, 'group') }>Delete</a>
			</div>
		</div>
	)
});

const SkillGroups = SortableContainer(({ items, onDelete, skills, onEditGroupName, onSaveGroupName, toEdit, editError, onSkillSave,onSortSkillsEnd }) => {
	return (
		<div className='settings-list__container skill-list__container'>
		{items.map((item,index) => {
			return (<SkillGroup
				key={item.id}
				index={index}
				details={item}
				onSortSkillsEnd={onSortSkillsEnd}
				onEditGroupName={onEditGroupName}
				onSaveGroupName={onSaveGroupName}
				toEdit={toEdit}
				editError={editError}
				onDelete={onDelete}
				children={byGroupSkills(item.id, skills)}
				onSkillSave={onSkillSave}/>)
		})}
		</div>
	)
});

export default class Skills extends Component {

	constructor() {
		super();
		this.state = {
			skills: [],
			formOpen: false,
			skillGroups: [],
			confirmModalOpen: false,
			forDelete: {},
			affectedTickets: [],
			toEdit: {}
		}
		this.onEditGroupName = this.onEditGroupName.bind(this);
		this.saveGroupName = this.saveGroupName.bind(this);
	}

	componentDidMount() {
		this.getSkills()
	}

	getSkills = () => {
		this.props.handleRoute();
		Taxonomies.get('skills').then(response => {
			this.setState({
				skills: response,
				skillGroups: getSkillGroups(response),
				forDelete: {},
				confirmModalOpen: false,
				affectedPeople: []
			})
		}).catch((error) => {
			// @TODO: Show error notification
		})
	}

	updateSkill = (parent_id, e,data) => {
		const name = e.target.name.value;
		e.target.reset();
		e.preventDefault();

		if(data) {
			//update
			Taxonomies.updateTaxonomy('skills', data.id, {name}).then(response => {
				// e.target.reset()
				// @TODO: show notification
				this.getSkills()
			}).catch(error => {
				// @TODO: show notification
			})
		} else {
			//create
			Taxonomies.create('skills', { name, parent_id }).then(response => {
				// e.target.reset()
				// @TODO: show notification
				this.getSkills()
			}).catch(error => {
				// @TODO: show notification
			})
		}
	}

	updateSkillGroup = (data) => {
		const name = data.name;

		if (_.isEmpty(name)) alert('Type new skill name.')
		else {
			Taxonomies.create('skills', { name }).then(response => {
				// @TODO: show notification
				this.getSkills()
			}).catch(error => {
				// @TODO: show notification
			})
		}
	}

	persistGroupsOrder = () => {
		const { skillGroups } = this.state
		const ordered = skillGroups.map((item, index) => ({ id: item.id, order: index }))

		Taxonomies.updateOrder('skills', ordered).then(response => {
			// @TODO: show success notification
		}).catch(error => {
			// @TODO: show error notification
		})
	}
	onSortedSkill =  ({oldIndex, newIndex, skills}) => {
		let asd = JSON.parse(JSON.stringify(skills));
		let order = JSON.parse(JSON.stringify(arrayMove(asd, oldIndex, newIndex)));
		let sortSkills = [];
		order.map((value, key) => { value.order = asd[key].order; sortSkills.push(value)})

		let newSkills =	this.state.skills.map((skill)=>{
			sortSkills.map(sSkill => {
				if (skill.id === sSkill.id) {
					skill.order = sSkill.order;
				}
			})
			return skill;
		})

		this.setState(({skills}) => ({
			skills: newSkills.sort((a,b) => a.order - b.order),
		}), () => {
			this.updateSkillsOrder()
		});
	}
	updateSkillsOrder = () => {
		const { skills } = this.state
		const ordered = skills.map((item, index) => ({ id: item.id, order: index }))

		Taxonomies.updateOrder('skills', ordered).then(response => {
			// @TODO: show success notification
		}).catch(error => {
			// @TODO: show error notification
		})
	}

	deleteSkill = (e, details, type = 'group') => {
		Taxonomies.delete('skills', details.id).then(response => {
			// No affected people, no box with details
			if(response.affected_people.length === 0) {
				this.confirmDelete(details);
			}
			else {
				this.setState({
					forDelete: details,
					confirmModalOpen: true,
					affectedPeople: response.affected_people
				})
			}

		})
	}

	toggleConfirm = () => {
		this.setState({
			confirmModalOpen: !this.state.confirmModalOpen
		})
	}

	confirmDelete = (details) => {
		return Taxonomies.confirmDelete('skills', details.id).then(() => {
			this.getSkills()
		})
	}

	onSorted = ({oldIndex, newIndex}) => {
		this.setState(({skillGroups}) => ({
			skillGroups: arrayMove(skillGroups, oldIndex, newIndex),
		}), () => {
			this.persistGroupsOrder()
		});
	}

	onEditGroupName(skillGroup) {
		this.setState({
			toEdit: skillGroup
		})
	}

	saveGroupName(e,data) {

		e.preventDefault();
		const name = e.target.name.value;
		if (_.isEmpty(name)) {
			if (data) {
				this.setState({editError: 'Skill name can not be empty.'});
			} else {
				this.setState({error: 'Type new skill name.'});
			}

		} else {
			this.setState({error: null, editError: null, toEdit: null});
			this.updateSkill(null, e, data)
		}
	}


	render() {
		const { skills, skillGroups, confirmModalOpen, forDelete, affectedPeople } = this.state;
		return (
			<section className='page'>
				<div className='wrapper'>
					{confirmModalOpen && <ConfirmDeleteSkillModal confirmDelete={(data) => {this.confirmDelete(data)}}
																  forDelete={forDelete}
																  affectedPeople={affectedPeople}
																  toggleConfirm={() => this.toggleConfirm()}/>}

					<h3 className='page-section-title'>
						Skill groups ({skillGroups.length})
					</h3>

					{this.props.addSkill && <AddSkill onSave={this.updateSkillGroup} />}

					<SkillGroups
							items={skillGroups}
						   onSortEnd={this.onSorted}
							onSortSkillsEnd={this.onSortedSkill}
							lockToContainerEdges={true}
							lockOffset={"0%"}
							helperClass={"order-selected-card"}
						   useDragHandle
						   lockAxis='y'
						   onDelete={this.deleteSkill}
							onEditGroupName={this.onEditGroupName}
							toEdit={this.state.toEdit}
						   skills={skills}
							onSaveGroupName={this.saveGroupName}
						   onSkillSave={this.updateSkill}/>
				</div>
			</section>
		)
	}

}
