import React, {Component, Fragment} from 'react';
import UploadFile from "../../../../components/UploadFile";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {connect} from "react-redux";

class Info extends Component {

	render() {
		return (
			<Fragment>
				<section className="page">
					<div className="wrapper">
						<div className="project-info-container">
							<div dangerouslySetInnerHTML={{ __html: this.props.project.description }} />
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_project_files']}
								oneperm
							>
								<UploadFile
									isNeedResource={true}
									resource='Project'
									resource_id={this.props.project.id}
								/>
							</PermissibleRender>
						</div>
					</div>
				</section>

			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}
export default connect(mapStateToProps, null)(Info);




