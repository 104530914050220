import React, {Component, Fragment} from 'react';
import EditProfileForm from "../../forms/EditProfileForm";

export default class EditProfileModal extends Component {


	render() {
		return (
			<Fragment>
				<div className="e-modal">
					<div className="e-modal__container">
						<span onClick={() => this.props.closeModal()}
							  className="e-modal__close">
						</span>
						<div name="new-company">
							<div className="e-modal__head">
								<span
									className="deco size36 round icon edit">
								</span>
								<h2 className="e-modal__title">Edit Profile</h2>
							</div>
							<div className="e-modal__body">
								<div className="e-modal__body-overflow">
									{this.props.user && <EditProfileForm user={this.props.user} closeModal={(data) => {this.props.closeModal(data)}}/>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
