import React, {Component, Fragment} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ProfileInfo from './Profile';
import SubNavigation from './SubNavigation';
import UsersService from '../../../services/api/users';
import UserTickets from './Tickets';
import UserActivity from './Activity';
import Permissions from './Permissions';

export default class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				meta: {},
				user_profile: {
					company: {
						payment_info: {}
					}
				},
				taxonomies: [],
				editProfileModal: false
			},
			active_user_tickets: 0,
			owner_user_tickets: 0,
			following_user_tickets: 0,
			active_user_projects: 0
		};

		this.getUserProfile = this.getUserProfile.bind(this);
	}


	getUserProfile() {
		UsersService.getProfile(this.props.match.params.id, {related: 'user_profile.company,taxonomies'})
			.then(response => {
				this.setState({
					user: response
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	getUserActiveProjects(user_id) {
		UsersService.getActiveProjects(user_id)
			.then(response => {
				this.setState({
					active_user_tickets: response.active_user_tickets,
					owner_user_tickets: response.owner_user_tickets,
					following_user_tickets: response.following_user_tickets,
					active_user_projects: response.active_user_projects
				});
			})
			.catch(err => {

			});
	}

	componentDidMount() {
		this.getUserProfile();
		this.getUserActiveProjects(this.props.match.params.id);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getUserProfile();
		}
	}

	render() {
		let label = `${this.state.user.first_name || ''} ${this.state.user.last_name || ''}`;
		return (
			<Fragment>
				<SubNavigation
					title={label}
					user={this.state.user}
					editProfileModalIsOpen={this.state.editProfileModal}
					toggleEditProfileModal={(boolean, data) => {
						this.setState({editProfileModal: boolean});
						if (data) {
							this.getUserProfile(this.state.user.id);
						}
					}}/>
				<Switch>
					<Redirect exact to={`${this.props.match.url}/info`}
							  from={`${this.props.match.url}`}/>
					<Route path={`${this.props.match.url}/info`}
						   component={(props) => <ProfileInfo
							   active_user_tickets={this.state.active_user_tickets + this.state.owner_user_tickets + this.state.following_user_tickets}
							   active_user_projects={this.state.active_user_projects} {...props}
							   profile={this.state.user}/>}/>
					<Route path={`${this.props.match.url}/tickets`}
						   component={(props) =>
							   <UserTickets {...this.props}/>}/>
					<Route path={`${this.props.match.url}/activity`}
						   component={(props) =>
							   <UserActivity {...this.props}/>}/>
					<Route path={`${this.props.match.url}/permissions`}
						   component={(props) =>
							   <Permissions {...this.props} />}/>
				</Switch>
			</Fragment>
		);
	}
}

