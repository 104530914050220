import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Modal from "react-modal";
import EditProfileModal from "../../Modals/EditProfileModal";

class SubNavigation extends Component {

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head"><h1 className="page-title">{this.props.title}</h1></div>
					</div>
					<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_change_settings']}
								oneperm
							>
						<div className="wrapper wide">
							<div className="page__head">
								<h1 className="page-title">My Profile</h1>
								<div className="btn-wrap page-controls">
									<button className="btn fill blue round" onClick={() => this.props.toggleEditProfileModal(true)}>Edit Profile</button>
								</div>
							</div>
						</div>
					</PermissibleRender>
					<div className="wrapper wide">
						<ul className="navigation-tab-links is-inline is-scrollable">
							<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/info`}>Info</NavLink></li>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_user_tickets']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/tickets`}>Tickets</NavLink></li>
							</PermissibleRender>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_user_activity']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/activity`}>Activity</NavLink></li>
							</PermissibleRender>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_change_settings']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/permissions`}>Permissions</NavLink></li>
							</PermissibleRender>
						</ul>
					</div>
				</div>
				<Modal
					isOpen={this.props.editProfileModalIsOpen}
					onRequestClose={()=>{this.props.toggleEditProfileModal(false);}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<EditProfileModal user={this.props.user} closeModal={(data) => this.props.toggleEditProfileModal(false, data)}/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
