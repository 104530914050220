import React, {Component, Fragment} from 'react';
import DashboardService from "../../../services/api/dasboard";
import moment from "moment";
import Pagination from "../../../components/Pagination";
import UsersService from "../../../services/api/users";
import ActivityEntry from "../../../components/TicketDetails/ActivityEntry";

const getCategoryFromDate = (date) => {
	return moment(date).format("MMMM D, YYYY")
};

export default class UserActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			limit: 30,
			offset: 0,
			pages: 1,
			activity: {},
			user: {}
		}

	}

	componentDidMount() {
		this.getActivities();
	}

	getActivities = () => {
		let activitiesCategory = {};
		this.setState({
			loading: true
		});
		UsersService.getUserActivity(this.props.match.params.id, {
			limit: this.state.limit,
			offset: this.state.offset
		})
			.then(response => {
				let activities = response.events;
				activities.forEach((activity) => {
					const category = getCategoryFromDate(activity.created_at);
					if (!activitiesCategory[category]) {
						activitiesCategory[category] = [];
					}
					activitiesCategory[category].push(activity)
				});
				this.setState({
					activity: activitiesCategory,
					pages: response.pages,
					offset: response.offset,
					loading: false,
					owner: response.owner
				})
			})
			.catch(err => {
				console.log(err)
			})
	};

	changePage = async page => {
		if (page >= 1 && page <= this.state.pages) {
			await this.setState({
				offset: (page - 1) * this.state.limit,
			});
			this.getActivities()
		}
	};

	refreshActivities() {
		this.setState({
			loading: true,
			limit: 30,
			pages: 1,
			offset: 0,
		}, () => this.getActivities());
	}

	render() {
		let DateNow = getCategoryFromDate(new Date());
		return (
			<Fragment>
				<div className='wrapper'>
					<div className="act__container box-pad-white">
						{this.state.loading ? <div className="loader__container">
								<div className="loader"></div>
							</div> :
							<div className="act__group">
								{Object
									.keys(this.state.activity)
									.map((category) => {
										return <div key={category} className="act__timeframe">
											<div className="act__date">
												{category === DateNow ? 'Today' : `${category}`}
											</div>
											{this.state.activity[category].map(activity => {
												let project;
												if (activity.tickets.project) {
													project = activity.tickets.project
												} else if (activity.project) {
													project = activity.project
												} else {
													project = {}
												}

												return (
													<ActivityEntry
														key={activity.id}
														user={this.state.user}
														activity={activity}
														tickets={activity.tickets}
														project={project}
													/>
												)
											})}
										</div>
									})}
							</div>}
					</div>
				</div>
				<Pagination pages={this.state.pages}
							changePage={(page) => this.changePage(page)}/>

			</Fragment>
		)
	}
}
