import React, {Component, Fragment} from 'react';
import ProjectsService from "../../../../services/api/projects";
import moment from "moment";
import UsersService from "../../../../services/api/users";
import ActivityProject from "./List";

const getCategoryFromDate = (date) => {
	return moment(date).format("MMMM D, YYYY")
};

export default class Activity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project: {},
			events: {},
			user: {}
		}
	}

	componentDidMount() {
		this.getActivities();
		this.getCurrentUser();
	}

	getActivities() {
		let activitiesCategories = {};
		ProjectsService.getActivity({id: this.props.project.id}).then(res => {
			res.events.forEach(activity => {
				const category = getCategoryFromDate(activity.created_at)
				if (!activitiesCategories[category]) {
					activitiesCategories[category] = [];
				}
				activitiesCategories[category].push(activity)
			})
			this.setState({
				project: res.project,
				events: activitiesCategories
			})
		})
	}

	getCurrentUser() {
		UsersService.getCurrentUser()
			.then(user => {
				this.setState({
					user: {
						id: user.id,
						first_name: user.first_name,
						last_name: user.last_name,
						avatar: null
					}
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	render() {
		let DateNow = getCategoryFromDate(new Date());
		return (
			<Fragment>
				<div className="wrapper">
				<div className="custom-tab-container">
					<section id="activity" role="tabpanel" className="custom-tab-content is-active">
						<div className="act__container box-pad-white">
							<div className="act__group">
							{Object
								.keys(this.state.events)
								.map((category) => {
									return <div className="act__timeframe" key={category}>
											<div className="act__date">
												{category === DateNow ? 'Today' : `${category}`}
											</div>
											{this.state.events[category].map(activity => {
												return (
													<ActivityProject
														key={activity.id}
														activity={activity}
														project={this.state.project}
														user={this.state.user}
													/>
												)
											})}
									</div>
								})}
							</div>
						</div>
					</section>
				</div>
				</div>

			</Fragment>
		);
	}
}




