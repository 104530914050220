import React, {Component, Fragment} from 'react';
import DatePicker from "react-datepicker";
import CustomCalendarComponent from "../components/CustomCalendarComponent";
import "react-datepicker/dist/react-datepicker.css";

export default class DueDate extends Component{
	render(){
		return (
			<DatePicker
				popperPlacement="auto"
			    // popperModifiers={
				// 	[
				// 		{
				// 			name: 'flip',
				// 			options: {
				// 				allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
				// 			  },
				// 			// enabled: true,
				// 		  }
				// 	]
				// }
				className={this.props.className}
				disabled={this.props.disabled}
				value={this.props.value && new Date(this.props.value)}
				selected={this.props.value && new Date(this.props.value)}
				minDate={this.props.minDate}
				customInput={ <CustomCalendarComponent
					styleBorder={this.props.styleBorder}
					ipDate={this.props.value}
					handleIpChange={(val)=>this.onChange(val)}
				/>}
				showMonthDropdown={this.props.showMonthDropdown}
				showYearDropdown={this.props.showYearDropdown}
				onChange={this.props.onChange}
			>
				<div className={'btn'} onClick={()=>{this.props.onChange(null)}}>Clear</div>
			</DatePicker>
		)
	}
}
