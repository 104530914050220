import React, {Component} from 'react';
import Board from 'react-trello'
import ProjectsService from "../../../../services/api/projects";
import KanbanCard from "../../../../components/KanbanCard";
import TicketsService from "../../../../services/api/tickets";
import TaxonomiesService from "../../../../services/api/taxonomies";
const _ = require('lodash');
const data = {
	lanes: [
		{
			id: 'lane1',
			title: 'Planned Tasks',
			label: '2/2',
			cards: [
				{id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins', draggable: true},
				{id: 'Card2', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
			]
		},
		{
			id: 'lane2',
			title: 'Completed',
			label: '0/0',
			cards: []
		}
	]
}
export default class TicketsKanban extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				lanes: []
			}
		}
		this.handleDragEnd = this.handleDragEnd.bind(this);
	}

	groupTickets(data) {
		let ticketsCategories = {};
		let tickets = [...data];
		tickets.forEach((ticket) => {
			let category;
			if(true) {
				category = ticket.status.name;
			}
			else
				return; //to do others category
			if(!ticketsCategories[category]) {
				ticketsCategories[category] = [];
			}
			ticketsCategories[category].push(ticket)
			ticketsCategories[category].id = ticket.status.id
		});
		return ticketsCategories
	}

	handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails) {
		if (!sourceLaneId || !targetLaneId) {
			return;
		}
		let cards = _(this.state.data.lanes).find((o) => {return o.id === targetLaneId}).cards ?? [];
		let prevTicketId = _.get(cards, [position - 1], null)?.id ?? null
		let nextTicketId = _.get(cards, [position], null)?.id ?? null
		TicketsService.updateTicket(cardId, {status_id: targetLaneId, previousTicketId: prevTicketId, nextTicketId: nextTicketId}, null)
			.then(res => {
				this.getTickets()
			})
			.catch(err => {console.log(err)})
	}

	getStatuses() {
		TaxonomiesService.get('statuses')
			.then(response => {
				let length = response.length;
				let lanes = response.map((status, index) => {
					return {
						id: status.id,
						title: status.name,
						label: `${index + 1}/${length}`,
						cards: [],
						style: {backgroundColor: status.color}
					}
				})
				//hardcoded order
				lanes = _.sortBy(lanes, function (item) {
					return item.title === 'Closed' ? 0 : 1;
				})
				this.setState({
					data: {
						lanes: lanes.reverse()
					}
				}, () => {
					this.getTickets()
				})


			})
	}

	getTickets() {
		ProjectsService.getTickets({limit: -1, offset: 0, filters: {
				status: {label: "All Tickets", value: 'active,archived'},
			}}, this.props.project.readable_id)
			.then(response => {
				let tickets = response.data;
				let lanes = JSON.parse(JSON.stringify(this.state.data.lanes))
				lanes = lanes.map(lane => {
					let cards = tickets.filter(ticket => ticket.status.id === lane.id).map(v => {
						return {id: v.id, title: v.title, editable: false, description: <div contentEditable='false' dangerouslySetInnerHTML={{ __html: v.description }}></div>, assignee: v.assigneed, due_date: v.due_date, updated_at: v.updated_at, sort: v.sort, project_id: this.props.project.readable_id}
					}).sort((a, b) => a.sort - b.sort)
					lane.cards = cards;
					return lane;
				})
				//hardcoded order
				lanes = _.sortBy(lanes, function (item) {
					return item.title === 'Closed' ? 1 : 0;
				})

				this.setState({
					data: {
						lanes: lanes
					}
				})
			})
			.catch(err => {
				console.error(err);
			})
	}
	componentDidMount() {
		this.getStatuses()
	}

	render() {
		return <Board onCardClick={this.onCardClick} handleDragEnd={this.handleDragEnd} components={{Card: KanbanCard}} style={{backgroundColor: 'initial'}} data={this.state.data} editable={false}/>
	}
}
