import Select from 'react-select';
import React, {Component} from 'react';
import {checkQueryValue, setFilters} from '../helpers/filters';

export default class CustomSelect extends Component {

	handleChange = (data) => {
		if (!this.props.noQueryParams) {
			setFilters(this.props.name, data.value);
		}
		this.props.onChange(data);
	};


	render() {
		const {options, name, isMulti, value, disabled, searchable, className, noQueryParams} = this.props;
		const queryValue = checkQueryValue(name);
		const filterValue = queryValue ? options.find(option => option.value === checkQueryValue(this.props.name).value) : value;
		const selectValue = noQueryParams ? value : filterValue;
		if (!options) return null;


		return (
			<Select
				id="state-select"
				className={`react-select-container ${className} ${disabled ? 'disabled' : ''}`}
				clearable={false}
				classNamePrefix="react-select"
				options={options}
				onChange={this.handleChange}
				value={selectValue}
				isMulti={isMulti}
				isDisabled={disabled}
				isSearchable={searchable || false}
				// menuIsOpen='true'
			/>
		);
	}
}
