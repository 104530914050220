import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const _ = require('lodash');

const client = (() => {
	console.log("wind" , window.location.hostname);
	return axios.create({
		baseURL: process.env.REACT_APP_API_URL || `https://api-${window.location.hostname}/api`
	});
})();

const request = function(options, store) {
	const onSuccess = function(response) {
		console.debug('Request Successful!', response);
		return response.data;
	}

	const onError = function(error) {
		return Promise.reject(error.response || error.message);
	};

	let accountDomain = _.get(options, 'headers.account-domain', '')

	options.headers = {
		"Authorization": "Bearer " + cookies.get('token'),
		'account-domain': cookies.get('accountdomain') || accountDomain || 'staging'
	};

	return client(options)
		.then(onSuccess)
		.catch(onError);
};

export default request;
