import React, {Fragment} from 'react';
import UsersService from '../../../services/api/users';
import Pagination from '../../../components/Pagination';
import TicketSummaryPlaceholder
	from '../../../placeholders/TicketSummaryPlaceholder';
import TicketItemList from '../../../components/Ticket/TicketItemList';
import CustomSelect from '../../../components/CustomSelect';
import TaxonomiesService from '../../../services/api/taxonomies';
import {getFilters} from '../../../helpers/filters';
import TicketItemList2 from '../../../components/Ticket/TicketItemList2';

export default class UserTickets extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			limit: 30,
			pages: 1,
			offset: 0,
			hasMore: false,
			tickets: [],
			filters: getFilters({
				status: this.props.user?.meta?.preferences?.default_ticket_filtering === 'active,archived' ? {
					label: 'All Tickets',
					value: 'active,archived'
				} : {label: 'Active Tickets', value: 'active'},
				filter: {label: 'No filter', value: null},
				order_by: {label: 'Order by importance', value: null},
				order_type: {label: 'Direction', value: null},
				group_by: {label: 'Group by', value: null}
			}),
			currentSearchLocation: window.location.search,
			statuses: [],
			ticketsGroup: {}
		};

		this.getTickets = this.getTickets.bind(this);
		this.changePage = this.changePage.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.tickets !== this.state.tickets) {
			return true;
		}
		return false;
	}

	getTickets() {
		this.setState({
			loading: true
		}, () => {
			UsersService.getUserTickets(this.props.match.params.id, {
				limit: this.state.limit,
				offset: this.state.offset,
				related: this.state.related,
				filters: this.state.filters
			})
				.then(response => {
					let object = {};
					if (this.state.filters.group_by.value) {
						object = this.groupTickets(response.data);
					}
					let tickets = response.data;
					this.setState({
						tickets,
						pages: response.pages,
						offset: response.offset,
						ticketsGroup: object,
						loading: false
					});
				})
				.catch(err => {
					console.error(err);
				});
		});
	}

	groupTickets(data) {
		let ticketsCategories = {};
		let tickets = [...data];
		tickets.forEach((ticket) => {
			let category;
			const groupByValue = this.state.filters.group_by.value;

			switch (groupByValue) {
				case 'status':
					category = [ticket.status.name];
					break;
				case 'project':
					category = [ticket.project.title];
					break;
				case 'people':
					category = [];
					if (ticket.owner_id) category.push(ticket.owner_id);
					if (ticket.assignee_id && ticket.owner_id !== ticket.assignee_id) category.push(ticket.assignee_id);
					break;
				default:
					category = ['All'];
			}

			category.map(key => {
				let keyName = key;
				if (groupByValue === 'people') {
					keyName = this.userName(key);
				}

				if (!ticketsCategories[keyName]) {
					ticketsCategories[keyName] = [];
				}

				ticketsCategories[keyName].push(ticket);
			});

		});


		return ticketsCategories;
	}

	getStatuses() {
		TaxonomiesService.get('statuses')
			.then(res => {
				let statuses = res.map(status => {
					return {
						value: status.id,
						label: status.name,
						order: status.order,
						bgColor: status.color
					};
				}).sort((a, b) => a.order - b.order);
				this.setState({statuses});
			})
			.catch(err => {
				console.log(err);
			});
	}

	refreshTickets() {
		this.setState({
			loading: true,
			limit: 30,
			pages: 1,
			offset: 0
		}, () => this.getTickets());
	}

	componentDidMount() {
		this.getTickets();
		this.getStatuses();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (window.location.search !== this.state.currentSearchLocation) {
			this.setState({
				filters: getFilters({
					status: this.props.user?.meta?.preferences?.default_ticket_filtering === 'active,archived' ? {
						label: 'All Tickets',
						value: 'active,archived'
					} : {label: 'Active Tickets', value: 'active'},
					filter: {label: 'No filter', value: null},
					order_by: {label: 'Order by importance', value: null},
					order_type: {label: 'Direction', value: null},
					group_by: {label: 'Group by', value: null}
				}),
				currentSearchLocation: window.location.search,
				tickets: []
			}, () => this.refreshTickets());

		}
	}

	changePage(page) {
		if (page >= 1 && page <= this.state.pages) {
			this.setState({
				offset: (page - 1) * this.state.limit
			}, () => {
				this.getTickets();
			});
		}
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters,
			loading: true,
			limit: 30,
			pages: 1,
			offset: 0
		}, () => this.getTickets());
	}

	render() {
		return (
			<Fragment>
				<div className="secondary-bottom">
					<div className="wrapper">
						<ul className="filter-container">

							<CustomSelect name="status"
										  className="filter"
										  value={this.state.filters.status}
										  onChange={this.onFilter.bind(this, 'status')}
										  options={[
											  {
												  label: 'Active Tickets',
												  value: 'active'
											  },
											  {
												  label: 'Closed Tickets',
												  value: 'archived'
											  }
										  ]}
							/>

							<CustomSelect name="filter"
										  className="filter"
										  value={this.state.filters.filter}
										  onChange={this.onFilter.bind(this, 'filter')}
										  options={[
											  {
												  label: 'No filter',
												  value: null
											  },
											  {
												  label: 'Assigned to me',
												  value: 'assigned'
											  },
											  {
												  label: 'Followed by me',
												  value: 'following'
											  },
											  {
												  label: 'Created by me',
												  value: 'owned'
											  }
										  ]}
							/>

							<CustomSelect name="order_by"
										  className="filter"
										  value={this.state.filters.order_by}
										  onChange={this.onFilter.bind(this, 'order_by')}
										  options={[
											  {
												  label: 'Order by importance',
												  value: null
											  },
											  {
												  label: 'By activity',
												  value: 'activity'
											  },
											  {
												  label: 'By title',
												  value: 'title'
											  },
											  {
												  label: 'By due date',
												  value: 'due_date'
											  },
											  {
												  label: 'By creation',
												  value: 'created_at'
											  },
											  {
												  label: 'By Project',
												  value: 'project'
											  }
										  ]}
							/>

							<CustomSelect name="order_type"
										  className="filter"
										  value={this.state.filters.order_type}
										  onChange={this.onFilter.bind(this, 'order_type')}
										  options={[
											  {
												  label: 'Direction',
												  value: null
											  },
											  {
												  label: 'First to last',
												  value: 'asc'
											  },
											  {
												  label: 'Last to first',
												  value: 'desc'
											  }
										  ]}
							/>

							<CustomSelect name="group_by"
										  className="filter"
										  value={this.state.filters.group_by}
										  onChange={this.onFilter.bind(this, 'group_by')}
										  options={[
											  {
												  label: 'Group by',
												  value: null
											  },
											  {
												  label: 'Project',
												  value: 'project'
											  },
											  {
												  label: 'By activity',
												  value: 'activity'
											  },
											  {
												  label: 'Newest',
												  value: 'created'
											  },
											  {
												  label: 'Due date',
												  value: 'due_date'
											  },
											  {
												  label: 'Status',
												  value: 'status'
											  }

										  ]}
							/>


						</ul>
					</div>
				</div>
				{this.state.filters.group_by.value ?
					<Fragment>
						{Object.keys(this.state.ticketsGroup).map((category) => {
							return (
								<section className="page">
									<div className="wrapper">
										<div className="list-container">
											{this.state.loading && !this.state.tickets.length &&
											<div className="inner-container">
												<div
													className={`morph-grid__container list-view mod-margin`}>
													<TicketSummaryPlaceholder
														items={10}/>
												</div>
											</div>
											}
											{this.state.tickets.length ?
												<div
													className="inner-container">
													<div
														className={`morph-grid__container list-view mod-margin`}>
														<div
															className="activity__date">
															<strong> {category} </strong>
														</div>
														{this.state.ticketsGroup[category].map(ticket =>
															<TicketItemList2
																key={ticket.id}
																statuses={this.state.statuses}
																project={ticket.project}
																onUpdate={() => this.refreshTickets()}
																ticket={ticket}
															/>)}
													</div>
												</div>
												:
												<div
													className="activity-container">
													<center><h4>There is no
														ticket within this
														search criteria.</h4>
													</center>
												</div>
											}

										</div>
									</div>
								</section>
							);
						})}
					</Fragment>
					:
					<section className="page">
						<div className="wrapper">
							<div className="list-container">
								{this.state.loading && !this.state.tickets.length &&
								<div className="inner-container">
									<div
										className={`morph-grid__container list-view mod-margin`}>
										<TicketSummaryPlaceholder items={10}/>
									</div>
								</div>
								}
								{this.state.tickets.length ?
									<div className="inner-container">
										<div
											className={`morph-grid__container list-view mod-margin`}>
											{this.state.tickets.map(ticket =>
												<TicketItemList2
													key={ticket.id}
													statuses={this.state.statuses}
													project={ticket.project}
													onUpdate={() => this.refreshTickets()}
													ticket={ticket}
												/>)}
										</div>
									</div>
									:
									<div className="activity-container">
										<center><h4>There is no ticket within
											this search criteria.</h4></center>
									</div>
								}

							</div>
						</div>
					</section>
				}

				<Pagination
					pages={this.state.pages}
					changePage={(page) => this.changePage(page)}
				/>
			</Fragment>
		);
	}
}

