import React, {Fragment} from 'react';

export default class Pagination extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.pages !== this.props.pages) {
			return true;
		}
		return nextState.currentPage !== this.state.currentPage;
	}

	changePage(e, page) {
		e.preventDefault();
		this.setState({
			currentPage: page
		}, () => {
			this.props.changePage(page)
		});
	}

	render() {
		const pagesArray = [...Array(this.props.pages).keys()].map(p => p + 1);
		return (
			<Fragment>
				<nav className="pagination">
					<div className="wrapper">
						<ul className="pagnation__list">
							<li>
								<button className="far-icon circle fill white">
									<i className="far  fa-chevron-double-left"></i>
								</button>
							</li>
							{pagesArray.map((page, key) => {
								if (page >= this.state.currentPage - 2 && page <= this.state.currentPage + 2) {
									return (
										<li key={key}>
										<button onClick={e => {
											this.changePage(e, page)
										}}
												className={`${this.state.currentPage === (page) ? 'is-current' : ''}`}>{page}
										</button>
									</li>
									)
								}}
							)}
							<li>
								<button className="far-icon circle fill white">
									<i className="far  fa-chevron-double-right"></i>
								</button>
							</li>
							<li className="pagination-select__container">
								<span>Jump to page</span>
								<select className="default_rounded_select">
									{pagesArray.map((page, key) => <option key={key} >{page}</option>)}
								</select>
							</li>
						</ul>
					</div>
				</nav>
			</Fragment>
		)
	}
}
