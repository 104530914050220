import React, {Component} from 'react';
import moment from "moment";
import {isEmpty} from 'lodash';
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import Avatar from "../../../components/Avatar";
import {connect} from "react-redux";
import {PermissibleRender} from '@brainhubeu/react-permissible';
import TaxonomiesService from "../../../services/api/taxonomies";
import {setFilters} from "../../../helpers/filters";
import EditSkillsModal from "../../Modals/EditSkillsModal";
import Modal from "react-modal";
const _ = require('lodash');

class ProfileInfo extends Component {

	constructor(props) {
		super(props);
		this.state = {
			user: {
				meta: {},
				user_profile: {
					company: {
						payment_info: {}
					}
				},
				taxonomies: [],
				...props.profile
			},
			editSkillsModal: false,
			skills: []
		};
	}

	toggleSkillsModal (boolean) {
		this.setState({editSkillsModal: boolean})
	}

	getSkills() {
		TaxonomiesService.get('skills')
			.then(res => {
				this.setState({
					skills: res,
					skillGroups: this.getSkillGroups(res)
				})
			})
			.catch(err => {
				console.error(err);
			});
	}

	getSkillGroups(list) {
		const groups = list.filter(item => _.isNil(item.parent_id));
		return _.sortBy(
			_.uniqBy(groups, 'id'), 'order'
		)
	}
	componentDidMount(){
		this.getSkills();
	}
	


	render() {
		if (!this.state.user) return null;
		let location = this.state.user?.meta?.location ?? {};
		let social_handles = this.state.user.social_handles || {};
		return (
			<section className="profile-page__container">
				<div className="user-profile_container custom-modal__overflow">
					<div className="user-profile">
						<div className="user_avatar">
							<Avatar
								className="profile-preview-avatar"
								width={112}
								height={112}
								altText={`${this.state.user.first_name} ${this.state.user.last_name}`}
								userId={this.state.user.id}
								hasAvatar={this.state.user.has_avatar}
							/>
						</div>
						<h2 className="user_name">
							{this.state.user.first_name} {this.state.user.last_name}
						</h2>
						<ul className="user_position">
							<li></li>
							<li>{this.state.user?.user_profile?.company?.name}</li>
						</ul>
						<div
							className="scrollable-tabs__container is-hidden-on-tablet">
							<ul className="scrollable-tabs">
								<li><NavLink to=""
											 className="active">Info</NavLink>
								</li>
								<li><NavLink to="">Activity</NavLink></li>
								<li><NavLink to="">Tickets</NavLink></li>
								<li><NavLink to="">Projects</NavLink></li>
							</ul>
						</div>
						<ul className="user_statistics">
							<li>
								<h3>{this.props.active_user_tickets}</h3>
								<span>TICKETS</span>
							</li>
							<li>
								<h3>{this.props.active_user_projects}</h3>
								<span>PROJECTS</span>
							</li>
							<li>
								<h3>0</h3>
								<span>FILES</span>
							</li>
						</ul>
						<ul className="user_information">
							<li className="t-order-1">
								<i className="user-information-icon  fa-envelope"/>
								<a href={`mailto:${this.state.user.email}`}>{this.state.user.email || '-'}</a>
							</li>
							<li className="t-order-3">
								<i className="user-information-icon fab fa-skype"/>
								<a className={`${social_handles.skype ? '' : 'is_empty'}`}
								   href={`skype:${social_handles.skype || ''}?call`}>{social_handles.skype || '+ add skype'}</a>
							</li>
							<li className="t-order-5">
								<i className=" user-information-icon material-icons">local_phone</i>
								<a className={`${this.state.user.phone_number ? '' : 'is_empty'}`}
								   href={`tel:${this.state.user.phone_number || ''}?call`}>{this.state.user.phone_number || '+ add phone number'}</a>
							</li>
							<li className="t-order-2">
								<i className=" user-information-icon  fa-external-link-square-alt"/>
								<a href={`${this.state.user.website || ''}`}>{this.state.user.website || '-'}</a>
							</li>
							<li className="t-order-4">
								<i className="user-information-icon  fa-map-marker-alt"/>
								{isEmpty(location) ? '-' : (`${location['address']}, ${location['city']}, ${location['country']}`)}
							</li>
							<li className="t-order-6">
								<i className="user-information-icon  fa-birthday-cake"/>
								{this.state.user.birth ? moment(this.state.user.birth).format("MM/DD/YYYY") : '-'}
							</li>
						</ul>
						<div className="user-section">
							<h3 className="user-section__title">Skills</h3>
							{(this.state.user.user_profile?.skill_ids?.length === 0 || !this.state.user.user_profile.skill_ids) &&
							<div className={'is_empty'}>
								<span>No skills added yet</span>
								<img alt="no skills"
									 src={require('./../../../assets/images/no_skills_image.png')}/>
								<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_change_settings']}
								oneperm
								>
									<button className={'btn link'} onClick={e => {
										this.toggleSkillsModal(true);
									}}>+ add skills
									</button>
								</PermissibleRender>
							</div>
							}
							{this.state.user?.user_profile?.skill_ids?.length > 0 && this.state.skills?.length > 0 &&
							<div>
								<ul className="user_skills">
									{this.state.skills.map((skill,index) => {
										if(this.state.user?.user_profile?.skill_ids?.find(s => s === skill.id)) {
											console.log("skills" , skill);
											return (
												<li key={index}>
													<span>
														{skill.name}
													</span>
												</li>
											);
										}
									})}
								</ul>
								<div className={'is_empty'}>
								<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_change_settings']}
								oneperm
								>
									<button
										className={'btn link'}
										onClick={(e) => {this.toggleSkillsModal(true);}}>
										+ add skills
									</button>
								</PermissibleRender>
								</div>
							</div>
							}
						</div>
						{/* <PermissibleRender
							userPermissions={this.props.userPermissions}
							requiredPermissions={['can_view_user_payment_info']}
							oneperm
						> */}
							{/* <div className={'user-section'}>
								<h3 className={'user-section__title'}>Payment
									Info</h3>
								<ul className={'user_payment'}>
									<li>
										<span>Account</span> */}
										{/* <strong>{this.state.user?.user_profile?.company?.payment_info?.account_number}</strong> */}
										{/* <strong>{this.state.user?.meta?.payment_info?.account_number}</strong> */}
									{/* </li> */}
									{/* <li> */}
										{/* <span>Bank Name</span> */}
										{/* <strong>{this.state.user?.user_profile?.company?.payment_info?.bank_name}</strong> */}
										{/* <strong>{this.state.user?.meta?.payment_info?.bank_name}</strong> */}
									{/* </li>
									<li> */}
										{/* <span>Currency</span> */}
										{/* <strong>{this.state.user?.user_profile?.company?.payment_info?.currency}</strong> */}
										{/* <strong>{this.state.user?.meta?.payment_info?.currency}</strong>
									</li>
								</ul>
							</div>
						</PermissibleRender> */}
					</div>
				</div>
				<Modal
					isOpen={this.state.editSkillsModal}
					onRequestClose={()=>{this.setState({editSkillsModal: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}>
					<EditSkillsModal closeModal={(data)=>{ this.setState({editSkillsModal: false}) }}
									 user={this.state.user}
									   onFilterChange={(data)=>{setFilters("skill_ids",data.value); this.onFilter('skill_ids',data)}}
									   value={this.state.user?.user_profile?.skill_ids || []}
									   skillGroups={this.state.skillGroups}
									   skills={this.state.skills}/>
				</Modal>
			</section>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(ProfileInfo));
