import React, {Component, Fragment} from 'react';
import CustomSelect from '../../components/CustomSelect';
import UsersService from '../../services/api/users';
import TaxonomiesService from '../../services/api/taxonomies';
import PermissionsService from '../../services/api/permissions';
import {NavLink} from 'react-router-dom';
import SubNavigation from './SubNavigation';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changePeoplesViewType} from '../shared/actions';
import Modal from 'react-modal';
import SkillsFilterModal from '../Modals/SkillsFilterModal';
import _, { filter } from 'lodash';
import Avatar from '../../components/Avatar';
import {getFilters, setFilters} from '../../helpers/filters';
import {PermissibleRender} from '@brainhubeu/react-permissible';
import {ViewMenu} from '../../new-components';

class PeopleList extends Component {

	constructor() {
		super();
		this.state = {
			viewType: 'list-view',
			filters: getFilters({
				is_archived: false,
				role_id: {label: 'All roles', value: null},
				position_id: {label: 'All positions', value: null},
				skill_ids: {
					value: ''
				}
			}),
			currentSearchLocation: window.location.search,
			peoples: null,
			peopleFiltered: null,
			roles: null,
			positions: null,
			skillGroups: [],
			skills: [],
			skillsFilterModalIsOpen: false,
			loading: true,
		};
	}

	componentDidMount() {
		this.getPeople();
		this.getTaxonomies();
		Modal.setAppElement('body');
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters
		}, () => this.getPeople());

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log();
		if (window.location.search !== this.state.currentSearchLocation) {
			this.setState({
				filters: getFilters({
					role_id: {label: 'All roles', value: null},
					position_id: {label: 'All positions', value: null},
					skill_ids: {
						value: []
					}
				}),
				currentSearchLocation: window.location.search,
				projects: []
			}, () => this.getPeople());

		}

	}

	getTaxonomies() {
		PermissionsService.getRoles()
			.then(({data}) => {
				data.unshift({name: 'All roles', value: null});
				this.setState({
					roles: data
				});
			})
			.catch(err => {
				console.error(err);
			});

		TaxonomiesService.get('positions')
			.then(res => {
				res.unshift({name: 'All positions', value: null});
				this.setState({
					positions: res
				});
			})
			.catch(err => {
				console.error(err);
			});

		TaxonomiesService.get('skills')
			.then(res => {
				this.setState({
					skills: res,
					skillGroups: this.getSkillGroups(res)
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	getPeople() {
		this.setState({loading: true});
		const filters = {...this.state.filters};
		if (filters.skill_ids?.value?.length === 0) {
			filters.skill_ids.value = '';
		}
		const roleId = filters.role_id.value;
		// const statusTab = filters.status;
		filters.role_id = {label: 'All roles', value: null};

		delete filters.status;

		// filters.user_profile.is_archived = true;

		UsersService.getAllUsers({
			limit: '-1',
			related: 'user_profile.position',
			filters
		})
			.then(res => {
				// console.log("Z", this.state.filters.status);
				// console.log("U" , res.data);
				// let rand = Math.floor(Math.random() * 32) + 1;
				// res.data = res.data.slice(0,rand);
				// res.total = rand;
				// if(statusTab === 'archived'){
				// 	res.data = res.data.filter(i => i.user_profile.is_archived !== false);
				// }
				console.log(filters)

				if (roleId) {
					if (this.state.roles.find(role => role.id === roleId).name === 'employee') {
						const administrator = this.state.roles.find(role => role.name === 'administrator');
						const owner = this.state.roles.find(role => role.name === 'owner');
						res.data = res.data.filter(({user_profile}) => user_profile.role_id === roleId || user_profile.role_id === administrator.id || user_profile.role_id === owner.id);

					} else {
						res.data = res.data.filter(({user_profile}) => user_profile.role_id === roleId);
					}

				}
				this.setState({
					peopleFiltered: res.total,
					loading: false
				});
				this.setState({
					peoples: res.data.map(people => {
						return {
							...people,
							...{
								label:
									people.first_name +
									' ' +
									people.last_name,
								// hardcode api response with dummy data:
								avatar: '/default-avatar.png',
								active_projects:
								people.active_projects,
								active_tickets:
									people.active_tickets +
									people.owner_tickets +
									people.following_tickets
							}
						};
					}),
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	renderPeople(person) {
		return (
			<div key={person.id} className="morph-grid__item user-item">
				<div className="user-item__group alpha-beta">
					<div className="user-item__cell alpha">
						<div className="user-item__profile-preview">
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_user_profile']}
								renderOtherwise={
									<a style={{pointerEvents: 'none'}}>
										<Avatar
											className="profile-preview-avatar"
											width={64}
											height={64}
											altText={`${person.label}`}
											userId={person.id}
											hasAvatar={person.has_avatar}
										/>
										<span
											className="profile-preview-name">{person.label}</span>
									</a>
								}
								oneperm
							>
								<NavLink to={`/people/${person.id}`}>
									<Avatar className="profile-preview-avatar"
											width={64} height={64}
											altText={`${person.label}`}
											userId={person.id}
											hasAvatar={person.has_avatar}/>
									<span
										className="profile-preview-name">{person.label}</span>
								</NavLink>
							</PermissibleRender>
						</div>
					</div>
					<div className="user-item__cell beta">
						<div
							className="user-item__profile-position">{person.user_profile.position ? person.user_profile.position.name : '-'}</div>
					</div>
				</div>
				<div className="user-item__group gamma-delta">
					<div className="user-item__cell gamma">
						<div className="user-item__relevant-data">
							<div
								className="relevant-data-value">{person.active_projects}</div>
							<div
								className="relevant-data-label">Active <br/> Projects
							</div>
						</div>
					</div>
					<div className="user-item__cell delta">
						<div className="user-item__relevant-data">
							<div
								className="relevant-data-value">{person.active_tickets}</div>
							<div
								className="relevant-data-label">Active <br/> Tickets
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getSkillGroups(list) {
		const groups = list.filter(item => _.isNil(item.parent_id));
		return _.sortBy(
			_.uniqBy(groups, 'id'), 'order'
		);
	}
	handleChangePeoplesViewType = (type) =>
		() => {
			this.props.changePeoplesViewType(type);
		};
	render() {
		const roles = this.state.roles && this.state.roles.filter(d => d.name !== 'administrator' && d.name !== 'owner');
		return (
			<Fragment>
				<SubNavigation 
					title="People"
					refreshPeople={() => this.getPeople()} 
					changeStatus={this.onFilter.bind(this)}
					status={this.state.filters.is_archived}
				/>
				<div className="secondary-bottom">
					<div className="wrapper">
						<ul className="filter-container">
							{roles &&
							<CustomSelect
								name="role_id"
								className="filter-list-item filter"
								value={this.state.filters.role_id}
								onChange={this.onFilter.bind(this, 'role_id')}
								options={roles.map((role) => {
									return {
										label: role.name,
										value: role.id
									};
								})}
							/>}
							{this.state.positions &&
							<CustomSelect
								name="position_id"
								className="filter-list-item filter"
								value={this.state.filters.position_id}
								onChange={this.onFilter.bind(this, 'position_id')}
								options={this.state.positions.map((position) => {
									return {
										label: position.name,
										value: position.id
									};
								})}
							/>}
							<div className="filter-list-item filter">
								<button className="skill-filter-modal"
										onClick={() => this.setState({skillsFilterModalIsOpen: true})}>By
									skills
								</button>
							</div>
						</ul>
					</div>
				</div>
				<section className="page">
					<div className="wrapper">
						<ViewMenu
							view={this.props.preferences.peoplesView}
							onChangeProjectViewType={this.handleChangePeoplesViewType}
						/>
						<div className="list-container">
							<div className="inner-container">
								<div
									className={`morph-grid__container ctrl ${this.props.preferences.peoplesView}`}>
									{this.state.peoples && this.state.peoples.map(people => this.renderPeople(people))}
								</div>
							</div>
						</div>
					</div>
				</section>

				<Modal
					isOpen={this.state.skillsFilterModalIsOpen}
					onRequestClose={() => {
						this.setState({skillsFilterModalIsOpen: false});
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<SkillsFilterModal closeModal={() => {
						this.setState({skillsFilterModalIsOpen: false});
					}}
									   onFilterChange={(data) => {
										   setFilters('skill_ids', data.value);
										   this.onFilter('skill_ids', data);
									   }}
									   skillFilters={this.state.filters.skill_ids}
									   loading={this.state.loading}
									   skillGroups={this.state.skillGroups}
									   skills={this.state.skills}
									   peopleFiltered={this.state.peopleFiltered}/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		preferences: state.preferences,
		userPermissions: state.user.permissions
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({changePeoplesViewType}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleList);
