import React, {Component, Fragment, useState, useEffect} from 'react';
import UsersService from "../../services/api/users";
import InfiniteScroll from 'react-infinite-scroll-component';
import {get} from "immutable";


export default function Notifications(props) {

	const [notifications, setNotifications] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [notificationDropdownIsOpen, setNotificationDropdownIsOpen] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);


	const fetchNotifications = (reset) => {
		return UsersService
			.getCurrentUserNotifications({
				limit: 10,
				offset: reset ? 0 : notifications.length
			})
			.then((response) => {
				if (response.length < 10) {
					setHasMore(false);
				}
				if (reset) {
					setNotifications(
						response
					)
				} else {
					setNotifications(
						[
							...notifications,
							...response
						]
					)
				}
			})
	};

	const getUnreadCount = () => {
		UsersService
			.getUserNotificationsCount()
			.then((data) => setUnreadCount(parseInt(data.count)));
	};


	const refresh = () => {
		setNotifications([]);
		setHasMore(true);
	};

	useEffect(() => {
		fetchNotifications();
		getUnreadCount();
		// const interval = setInterval(() => {
		// 	getUnreadCount();
		// }, 5000);

		//In useEffect hook, you can specify a componentWillUnmount method by returning a function from the first argument.
		return () => {
				//clearInterval(interval);
		};
	}, [1]);

	useEffect(() => {
		if (notificationDropdownIsOpen) {
			getUnreadCount();
			fetchNotifications(true);
		}
	}, [notificationDropdownIsOpen]);

	const clearNotifications = () => {
		setNotifications([]);
		setUnreadCount(0);
		UsersService
			.markNotificationsAsRead();
	};

	return (
		<div className="nav-notifications__container">
			<div className="dropdown">
				{notificationDropdownIsOpen &&
				<div className="dropdown-close" onClick={() => {
					setNotificationDropdownIsOpen(false)
				}}/>}

				<span className="nav-notifications-trigger far-icon circle outline gray hover pointer"
					  onClick={() => {
						  setNotificationDropdownIsOpen(!notificationDropdownIsOpen)
					  }}>
							<i className="far  fa-bell"></i>
							<span className="notifications__count">{unreadCount > 0 ? unreadCount : null}</span>
				</span>

				{notificationDropdownIsOpen && <div className="dropdown__content active top-right">
					<div className="not-container">

						<div className="not-head">
							<span className="deco size36 round icon notifications"/>
							<h2 className="not-head__title">
								Notifications
							</h2>
						</div>


						<div className="not-body" id={'notification-scrollable'}>
							<div className="not-timeline__head"><span
								className="not-timeline__timestamp">Today</span>
								<button className="link" onClick={() => clearNotifications()}>Clear All</button>
							</div>
							<InfiniteScroll
								scrollableTarget={'notification-scrollable'}
								dataLength={notifications.length} //This is important field to render the next data
								next={() => fetchNotifications()}
								hasMore={hasMore}
								hasChildren={notifications.length > 0}
								scrollThreshold={0.6}
								loader={null}
								endMessage={
									null
								}
								// below props only if you need pull down functionality
								refreshFunction={() => refresh()}
								pullDownToRefresh={false}
								pullDownToRefreshContent={
									<h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
								}
								releaseToRefreshContent={
									<h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>
								}>
								<ul className="not-timeline__body">
									{notifications.map((notification) => {
										return (
											<li key={notification.id}><p
												dangerouslySetInnerHTML={{__html: notification.message}}></p></li>
										)
									})}
								</ul>
							</InfiniteScroll>
							{/*<li><p>Your notification list it's empty.</p></li>*/}


						</div>

					</div>
				</div>
				}
			</div>
		</div>
	)
}
