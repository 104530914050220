import React, {useEffect, useState} from 'react';
import useForm from 'react-hook-form'
import {SelectInput} from '../inputs/SelectInput';
import {Input} from '../inputs/Input';
import {isEmailAddress, isWebAddress,required} from "../inputs/validations";
import ProjectsService from '../services/api/projects';
import {HtmlInput} from "../inputs/HtmlInput";
import UploadFile from "../components/UploadFile";
import UsersService from "../services/api/users";
import SelectUsers from "../components/SelectUsers";

export default function NewProjectForm(props) {
	const { register, handleSubmit, errors, setError, setValue, formState } =  useForm({
		mode: "onChange"
	});

	const [inputs, setInputs] = useState({});
	const [companyType, setCompanyType] = useState('existing');
	const [attachments, setAttachments] = useState([]);
	const [data, setData] = useState({users: [], isFetching: true});

	const onSubmit = values => {

		values.attachments = attachments;
		values.followers = values.followers.length > 0 ? values.followers.split(",") : [];
		ProjectsService.createProject(values)
			.then(res => {
				props.history.push(`./projects/${res.readable_id}`)
			})
			.catch(err => {
				setError('title','submission', "Project title already exists");
				console.log(err);
			})
	};

	const handleInputChange = (event, name) => {
		setInputs(inputs => ({...inputs, [name]: event}));
		setValue(name, event, true);
	};

	const removeFromAttachments = (resource) => {
		setAttachments(attachments.filter(r => (r.file_file_name !== resource.file_file_name)));
	};
	useEffect(() => {
		UsersService.getAllUsers({limit: -1})
			.then(res => {
				setData({
					users: res.data.map(user=>{
						return {
							...user,
							...{
								label:user.first_name + ' ' + user.last_name,
								value: user.id,
								avatar: 'https://media.mnn.com/assets/images/2018/10/husky.jpg.653x0_q80_crop-smart.jpg',
							}
						}}), isFetching: false});
			})
			.catch (err => {
				console.log(err);
				setData({isFetching: false});
			});
	}, [setData]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={'f-row'}>
				<div className={'f-element'}>
					<Input type="text"
						   register={register({validate: required})}
						   placeholder={'Project title'}
						   value={inputs.title}
						   error={errors.title}
						   name="title"/>
				</div>
			</div>

			<div className={'f-row'}>
				<div className={'f-element'}>
					<Input type="text"
						   register={register({validate: required})}
						   placeholder={'Project brief'}
						   value={inputs.brief}
						   error={errors.brief}
						   name="brief" />
				</div>
			</div>
			<div className={'f-row'}>
				<div className={'f-element'}>
					<HtmlInput register={register}
							   placeholder={"Project description ..."}
							   name={"description"}
							   error={errors.description}
							   value={inputs.description}
							   onChange={(event)=> handleInputChange(event, 'description')}/>
				</div>
			</div>
			<div className={'f-row'}>
				<div className={'f-element'}>
					<UploadFile
						onFileAdded={(resource) => {
							setAttachments(attachments.concat([resource]))
						}}
						onFileRemoved={(resource) =>  removeFromAttachments(resource)}
					/>
				</div>
			</div>
			<div className="tabs-component">
				<ul className="custom-tab-links is-full bottom-margin">
					<li className={`custom-tab-link ${companyType === "existing" ? 'is-active' : ''}`}>
						<button type={"button"} className={`btn link ${companyType === "existing" ? 'is-active' : ''}`} onClick={() => { setCompanyType('existing')}}>Assign to Existing Company</button>
					</li>
					<li className={`custom-tab-link ${companyType === "new" ? 'is-active' : ''}`}>
						<button type={"button"} className={`btn link ${companyType === "new" ? 'is-active' : ''}`} onClick={() => { setCompanyType('new')}}>Create New Company</button>
					</li>
				</ul>
				<div className="custom-tab-container">
				{companyType === "existing" ?
					<div className="custom-tab-content is-active">
						<div className={'f-row'}>
							<div className={'f-element'}>
								<SelectInput value={inputs.company_id}
											 register={
												 register({
													 validate: companyType === "existing" && required,
												 })
											 }
											 placeholder={"Select company"}
											 name={"company_id"}
											 error={errors.company_id}
											 options={props.companies}
											 onChange={(event)=> handleInputChange(event, 'company_id')}/>
							</div>
						</div>
					</div>
					:
					<div className="custom-tab-content">
						<div className={'f-row'}>
							<div className={'f-element'}>
								<Input type="text"
									   register={
										   register({
											   validate: companyType === "new" && required,
										   })
									   }
									   value={inputs['company.name']}
									   placeholder={'Company Name *'}
									   error={errors['company.name']}
									   name="company.name"/>
							</div>
						</div>

						<div className={'f-row'}>
							<div className={'f-element'}>
								<Input type="text"
									   register={
										   register({
											   validate: companyType === "new" && required,
										   })
									   }
									   value={inputs["company.description"]}
									   placeholder={'Brief company description *'}
									   error={errors['company.description']}
									   name="company.description" />
							</div>
						</div>

						<div className={'f-row'}>
							<div className={'f-element'}>
								<Input type="text"
									   register={register}
									   value={inputs["company.contact_info.first_name"]}
									   placeholder={'Person first name'}
									   error={errors['company.contact_info.first_name']}
									   name="company.contact_info.first_name" />
							</div>
						</div>

						<div className={'f-row'}>
							<div className={'f-element'}>
								<Input type="text"
									   register={register}
									   placeholder={'Person last name'}
									   value={inputs["company.contact_info.last_name"]}
									   error={errors['company.contact_info.last_name']}
									   name="company.contact_info.last_name" />
							</div>
						</div>

						<div className={'f-row'}>
							<div className="f-element is-third">
								<Input type="text"
									   register={
										   register({
											   validate: companyType === "new" && {required, isEmailAddress},
										   })
									   }
									   placeholder={'Email'}
									   error={errors['company.contact_info.email']}
									   value={inputs['company.contact_info.email']}
									   name="company.contact_info.email"  />
							</div>
							<div className="f-element is-third">
								<Input type="text"
									   register={
										   register({
											   validate: companyType === "new" && {required, isWebAddress},
										   })
									   }
									   placeholder={'Website'}
									   error={errors['company.website']}
									   value={inputs['company.website']}
									   name="company.website"  />
							</div>
							<div className="f-element is-third">
								<Input type="text"
									   register={register}
									   placeholder={'Skype'}
									   error={errors['company.contact_info.skype']}
									   value={inputs['company.contact_info.skype']}
									   name="company.contact_info.skype"  />
							</div>
						</div>
					</div>
				}
				</div>
			</div>


			<div className="f-element is-full">
				<div className="ticket-meta">
					<div className="ticket-meta-cell ticket-meta-name">Add people to project</div>
					<div className="ticket-meta-cell ticket-meta-element">
						<input name={"followers"} type={'hidden'} ref={register} />
						{!data.isFetching && <SelectUsers users={data.users}
														  isMultiSelect={true}
														  selected={inputs.followers}
														  onChange={(data) => { handleInputChange(data, 'followers')}}/>}
					</div>
				</div>
			</div>

			<div className="f-row btn-container">
				<button className="btn outline gainsboro" type={'button'} onClick={() => {props.closeModal()}}>Close</button>
				<button disabled={formState.isSubmitting} className="btn fill blue" type={'submit'}>Create Project</button>
			</div>

		</form>
	)
}
