import React, {Component, Fragment} from 'react';

import {Switch, Route, Redirect} from 'react-router-dom';

import Login from './Login';
import Recovery from './Recovery';
import {connect} from "react-redux";
import CreateProfile from "./CreateProfile";
import ResetPassword from "./ResetPassword";
import {withRouter} from "react-router";

class Auth extends Component {


	render() {
		return (
			<Fragment>
				<Switch>
					<Route path={`${this.props.match.url}/login`} component={Login}/>
					<Route path={`${this.props.match.url}/confirm/:token/:membership`} component={CreateProfile}/>
					<Route path={`${this.props.match.url}/recovery`} component={Recovery}/>
					<Route path={`${this.props.match.url}/reset/:token`} component={ResetPassword}/>
					<Redirect exact from={this.props.match.url} to={`${this.props.match.url}/login`} />
				</Switch>
			</Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		memberships: state.user.memberships
	}
}

export default connect(mapStateToProps, null)(withRouter(Auth));
