import React, {useEffect, useState} from 'react';
import {history} from '../../store';
import CommentsService from '../../services/api/comments';
import Avatar from '../../components/Avatar';
import moment from 'moment';
import {
	canRenderPreview,
	getFontAwesomeIconFromMIME
} from '../../components/helpers/preview';

export default function SearchComments({query}) {
	const [comments, setComments] = useState([]);

	useEffect(() => {
		CommentsService.searchComments({search: query}).then(res => setComments(res.events));
	}, [query]);
	return (
		<section className="page">
			<div className="wrapper">
				<div className="act__container box-pad-white">
					<div className="act__group">
						<div className="act__timeframe">
							{comments.length ?
								<>
									{comments.map(comment => {
										const {
											owner:
												{id: ownerId, last_name, first_name, has_avatar},
											tickets:
												{id: ticketId, title, project: {readable_id}},
											created_at,
											meta
										} = comment;
										return (
											<div
												className="act__item comment">
												<div className="item__head">
													<div
														className="act__type far-icon circle fill gray">
														<i className="far fa-comment-alt"/>
													</div>
													<div className="act__owner">
														<a
															className="profile__link"
															onClick={() => {
																history.push(`/people/${ownerId}`);
															}}>
															<Avatar
																width={36}
																height={36}
																altText={`${first_name} ${last_name}`}
																userId={ownerId}
																hasAvatar={has_avatar}
															/>
															{`${first_name} ${last_name}`}
														</a>
													</div>
													<div
														className="act__action"> posted
														a comment in
														<a
															onClick={() => {
																history.push(`/projects/${readable_id}/tickets/${ticketId}`);
															}}
														> {title} </a>

													</div>
													<div
														className="act__timestamp">
														{moment(created_at).format('h:mm a')}
													</div>
												</div>
												<div className="item__body">
													<div
														className="comment__content"
														dangerouslySetInnerHTML={{__html: meta?.content ?? comment.meta}}/>
													{(meta?.attachmentsURLs?.length > 0 ?? false) &&
													<div
														className="file-upload">
														<div
															className="uploader-files">
															{comment?.meta?.attachmentsURLs?.map(attachment =>
																<div
																	className="uploader-file">
																	<div
																		className="file-header">
																		<div
																			className="file-avatar">
																			{canRenderPreview(attachment?.filename ?? '') ?
																				<img
																					src={attachment.url}
																					className={'act-add-attach__item-preview'}
																				/> :
																				<i
																					className={`fa ${getFontAwesomeIconFromMIME(attachment.filename ?? '')} fa-4`}
																					aria-hidden="true"
																				/>
																			}
																		</div>
																	</div>
																	<div
																		className="file-details">
																		<a className="file-name"
																		   target="_blank"
																		   href={attachment.url}>
																			{attachment.filename ?? ''}
																		</a>
																	</div>
																</div>
															)}
														</div>
													</div>}
												</div>
											</div>);
									})}
								</>
								:
								<div>There are no comments in this search
									criteria.</div>
							}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
