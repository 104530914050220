export function getFilters (defaultFilters) {
	let filters = defaultFilters || {};
	if (window.location.search !== "") {
		window.location.search.substr(1).split('&').map(el => {
			if (el.match(/\.(.*?)\=/)) {
				let value = el.split('=')[1] === "" ? null :  el.split('=')[1]
				filters[el.match(/\.(.*?)\=/)[1]] = {value: value}
			}
		})
	} else {
		//set default filters as query params
		let params = [];
		if (defaultFilters) {
			Object.keys(defaultFilters).map((label) => {
			if (defaultFilters[label].value) {
				params.push(`filter.${label}=${defaultFilters[label].value}`);
			}
		});
			params = '?' + params.join('&');
		} else {
			params = '';
		}

		window.history.replaceState({},"",window.location.origin + window.location.pathname + params);
	}

	return filters;
}

export function checkQueryValue (name) {
	let filters = getFilters();
	return filters[name];
}

export function setFilters(name, value) {
	let filters = getFilters();
	filters[name] = {value: value};
	let params = [];
	Object.keys(filters).map((label) => {
		if (filters[label].value) {
			params.push(`filter.${label}=${filters[label].value}`);
		}
	});
	params = '?' + params.join('&');
	window.history.replaceState({},"",window.location.origin + window.location.pathname + params);
}
