import request from '../shared/request';

export default class TaxonomiesService {

	static get(type = 'statuses') {
		return request({
			url: `/taxonomies/${type}`,
			method: 'GET',
		});
	}

	static getStatusById(status_id){
		return request({
			url:`/taxonomies/statuses/${status_id}`,
			method:'GET',
		})
	}
	static create(type = 'statuses', data) {
		return request({
			url: `/taxonomies/${type}`,
			method: 'POST',
			data
		});
	}

	static updateOrder(type = 'statuses', data) {
		return request({
			url: `/taxonomies/${type}/order`,
			method: 'POST',
			data
		});
	}

	static updateTaxonomy(type= 'statuses',id, data){
		return request({
			url: `/taxonomies/${type}/${id}`,
			method: 'PUT',
			data
		});
	}

	static delete(type = 'statuses', id) {
		return request({
			url: `/taxonomies/${type}/${id}`,
			method: 'DELETE'
		});
	}

	static confirmDelete(type = 'statuses', id) {
		return request({
			url: `/taxonomies/${type}/${id}/confirm`,
			method: 'DELETE'
		});
	}

}
