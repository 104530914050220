import request from '../shared/request';

export default class AccountService {
	static register(data) {
		return request({
			url: '/accounts',
			method: 'POST',
			data
		})
	}

	static checkReady(hostedpage_id) {
		return request({
			url: '/accounts/check',
			method: 'GET',
			params: { hostedpage_id }
		})
	}
}
