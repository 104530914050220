import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ReportsService from '../../services/api/reports'

export const ActivityReports = () => {
	const [reports, setReports] = useState([]);
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		const fetchReports = async () => {
			try {
				const {data} = await ReportsService.getReports()
				setReports(data)
				// setReports(Object.keys(data).map((x) => ({ key : x , value: data[x]})))
			} catch (e) {
				console.log(e)
			} finally {
				setIsLoading(false)
			}
		}
		fetchReports()

	}, [])
	const {
		numberOfProjects,
		numberOfTickets,
		averageNumberOfTicketsPerProject,
		newestProject,
		oldestProject,
		newestOpenTicket,
		oldestOpenTicket,
		teamSize,
		averageProjectLifespan,
		longestProject,
		shortestProject,
		topLoadPerPerson
	} = reports

	console.log(reports);

	return (
		!isLoading && 
		<div className="wrapper">
			<div className="list-container">
				<div className="inner-container">
					<div className="morph-grid__container ctrl card-view" style={{marginTop:'20px' , color:'#3C4061'}}>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{numberOfProjects}</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Number of projects</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{numberOfTickets}</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Number of tickets</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{averageNumberOfTicketsPerProject}</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Average number of tickets per project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${newestProject?.readable_id}`}>{newestProject?.title}</a>
									<p className="opacity-50 text-center text-xs">Created at : {new Date(newestProject.created_at).toLocaleDateString()}</p>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Newest project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${oldestProject?.readable_id}`}>{oldestProject?.title}</a>
									<p className="opacity-50 text-center text-xs">Created at : {new Date(oldestProject.created_at).toLocaleDateString()}</p>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Oldest project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${newestOpenTicket?.readable_id}`}>{newestOpenTicket?.title}</a>
									<p className="opacity-50 text-center text-xs">Created at : {new Date(newestOpenTicket.created_at).toLocaleDateString()}</p>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Newest open ticket</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${oldestOpenTicket?.readable_id}`}>{oldestOpenTicket?.title}</a>
									<p className="opacity-50 text-center text-xs">Created at : {new Date(oldestOpenTicket.created_at).toLocaleDateString()}</p>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Oldest open ticket</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{teamSize}</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Team Size</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{averageProjectLifespan} days</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Average lifespan of a project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${longestProject.readable_id}`}>{longestProject.title}</a>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Longest project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<div className="h-20 flex justify-center items-center flex-col mb-5 border-b border-gray-100">
									<a className="text-center font-normal mb-5 text-xl line-clamp-3 w-full" href={`/projects/${shortestProject.readable_id}`}>{shortestProject.title}</a>
								</div>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Shortest project</p>
							</div>
						</div>

						<div className="morph-grid__item user-item">
							<div className="flex flex-col h-40">
								<h1 className="text-center font-normal mb-5 border-b border-gray-100" style={{fontSize:'50px'}}>{topLoadPerPerson}</h1>
								<p className="text-base font-semibold text-center max-h-5 leading-5">Top load per person</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		// <Wrapper>
		// 	{isLoading ?
		// 		<div>Loading...</div>
		// 		:
		// 		<>
		// 			<div>
		// 				Number of projects: {numberOfProjects}
		// 			</div>
		// 			<div>
		// 				Number of tickets: {numberOfTickets}
		// 			</div>
		// 			<div>
		// 				Average number of tickets per
		// 				project: {averageNumberOfTicketsPerProject}
		// 			</div>
		// 			<div>
		// 				Newest project: {' '}
		// 				<a href={`/projects/${newestProject.readable_id}`}>{newestProject.title}</a>
		// 				{' '} {new Date(newestProject.created_at).toLocaleDateString()}

		// 			</div>
		// 			<div>
		// 				Oldest project: {' '}
		// 				<a href={`/projects/${oldestProject.readable_id}`}>{oldestProject.title}</a>
		// 				{' '} {new Date(oldestProject.created_at).toLocaleDateString()}

		// 			</div>
		// 			<div>
		// 				Newest open ticket: {' '}
		// 				<a href={`/projects/${newestOpenTicket.project.readable_id}/tickets/${newestOpenTicket.id}`}>{newestOpenTicket.title}</a>
		// 				{' '} {new Date(newestOpenTicket.created_at).toLocaleDateString()}
		// 			</div>
		// 			<div>
		// 				Oldest open ticket: {' '}
		// 				<a href={`/projects/${oldestOpenTicket.project.readable_id}/tickets/${oldestOpenTicket.id}`}>{oldestOpenTicket.title}</a>
		// 				{' '} {new Date(oldestOpenTicket.created_at).toLocaleDateString()}
		// 			</div>
		// 			<div>
		// 				Team Size: {teamSize}
		// 			</div>
		// 			<div>
		// 				Average lifespan of a
		// 				project: {averageProjectLifespan} days
		// 			</div>
		// 			{longestProject &&
		// 			<div>
		// 				Longest project: {' '}
		// 				<a href={`/projects/${longestProject.readable_id}`}>{longestProject.title}</a>
		// 			</div>
		// 			}
		// 			{shortestProject &&
		// 			<div>
		// 				Shortest project: {' '}
		// 				<a href={`/projects/${shortestProject.readable_id}`}>{shortestProject.title}</a>
		// 			</div>
		// 			}
		// 			<div>
		// 				Top load per person: {topLoadPerPerson}
		// 			</div>
		// 		</>
		// 	}
		// </Wrapper>
	)
}

// const Wrapper = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-evenly;
// 	align-self: flex-start;
// 	background-color: #FFF;
// 	padding-left: 5rem;
// 	height: 50vh;
// `
