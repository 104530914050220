import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const loggerMiddleWare = store => next => action => {
    console.log("[LOG] Action triggered", action);
    next(action);
};


const initialState = {
		auth: {
			isAuthenticated: false,
			memberships: []
		},
		user: {
		},
		preferences: {
			projectsView: 'list-view',
			companiesView: 'list-view',
			peoplesView: 'list-view'
		}
	},
    enhancers = [],
    middleware = [
        thunk,
        routerMiddleware(history),
        loggerMiddleWare
    ];


const composedEnhancers = composeWithDevTools(
    applyMiddleware(...middleware),
    ...enhancers
);

const config = {
    key: 'huskytracker',
    storage,
    blacklist: ['router', 'form'],
};
const reducer = persistCombineReducers(config, rootReducer(history));

function configureStore() {
    let store = createStore(
        reducer,
        initialState,
        composedEnhancers
    );
    let persistor = persistStore(store);

    return {persistor, store};
}

export const {persistor, store} = configureStore();
