import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import Modal from "react-modal";
import NewTicketModal from "../../Modals/NewTicketModal";
import EditProjectModal from "../../Modals/EditProjectModal";
import ProjectFollowersModal from "../../Modals/ProjectFollowersModal";
import ArchiveProjectModal from "../../Modals/ArchiveProjectModal";
import ProjectsService from "../../../services/api/projects";
import AfterArchiveOrUnarchiveProjectModal from "../../Modals/AfterArchiveOrUnarchiveProjectModal";
import UnarchiveProjectModal from "../../Modals/UnarchiveProjectModal";
import Avatar from "../../../components/Avatar";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {connect} from "react-redux";

class SubNavigation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			projectOptionsDropdownIsOpen: false,
			newTicketModalIsOpen: false,
			editProjectModalIsOpen: false,
			projectFollowersModalIsOpen: false,
			archiveProjectModalIsOpen: false,
			unarchiveProjectModalIsOpne: false,
			afterArchiveOrUnarchiveProjectModalIsOpen: false
		}
	}

	componentDidMount() {
		Modal.setAppElement('body');
	}

	renderFollower(follower) {
		return(
			<li className="follower" key={follower.id}>
				<PermissibleRender
					userPermissions={this.props.userPermissions}
					requiredPermissions={['can_view_user_profile']}
					renderOtherwise={
						<div className="profile-label">
							<div className="row-avatar">
								<Avatar width={30} height={30} altText={follower.label} userId={follower.id} hasAvatar={follower.has_avatar}/>
							</div>
						</div>
					}
					oneperm
				>
					<NavLink to={`/people/${follower.id}`} alt={follower.label} target="_blank">
						<div className="profile-label">
							<div className="row-avatar">
								<Avatar width={30} height={30} altText={follower.label} userId={follower.id} hasAvatar={follower.has_avatar}/>
							</div>
						</div>
					</NavLink>
				</PermissibleRender>

			</li>
		)
	}

	archiveProject() {
		ProjectsService.archiveProject(this.props.project.id).then(()=> {
			this.setState({
				archiveProjectModalIsOpen: false,
				afterArchiveOrUnarchiveProjectModalIsOpen: true
			});
			this.props.project.archived = true;
		})
	};

	unarchiveProject() {
		ProjectsService.unarchiveProject(this.props.project.id).then(()=> {
			this.setState({
				unarchiveProjectModalIsOpen: false,
				afterArchiveOrUnarchiveProjectModalIsOpen: true
			})
			this.props.project.archived = false;

		})
	};

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head project-head">
							<h1 className="page-title">
								{this.props.project.title}
							</h1>

							<div className="project-owner">
								<NavLink to={`/companies/${this.props.project.company.id}`}>{this.props.project.company.name}</NavLink>
							</div>

							<div className="btn-wrap page-controls">
								<div className="page-controls__in-wrapper">
									<div className="page-control__single followers project">
										<ul className="follower-list">
											{/*ramane de gandit ce facem aici cu followers...*/}
											{this.props.projectFollowers.slice(0, 5).map(follower => this.renderFollower(follower))}
											{this.props.projectFollowers.length > 5 && <button className="btn circle outline fill white gainsboro and-more" onClick={()=>{this.setState({projectFollowersModalIsOpen: true})}}>And More</button>}
											<li className="follower__add">
												<button className="far-icon circle outline gray hover " onClick={()=>{this.setState({projectFollowersModalIsOpen: true})}}>
													<i className="far  fa-user-plus"></i>
												</button>
											</li>
										</ul>
									</div>

									<div className="page-control__single push-left">
										<PermissibleRender
											userPermissions={this.props.userPermissions}
											requiredPermissions={['can_archive_project', 'can_edit_project', 'can_reopen_projects']}
											oneperm
										>
										<div className="dropdown">
											{this.state.projectOptionsDropdownIsOpen &&
											<div className="dropdown-close" onClick={() => {this.setState({projectOptionsDropdownIsOpen: false})}} />
											}
											<div role="button" className="dropdown__trigger far-icon circle outline gray hover" onClick={() => {this.setState({projectOptionsDropdownIsOpen: !this.state.projectOptionsDropdownIsOpen})}}>
												<i className="far  fa-ellipsis-h"/>
											</div>

											{this.state.projectOptionsDropdownIsOpen &&
												<div className="dropdown__content small active top-right">
													<div className="dropdown-content"><span className="dropdown-content-close"/>
														<div className="settings-dropdown">
															<div className="selectbox selectbox-active is-menu top-right-arrow">
																<div className="selectbox__elements-container">
																	<PermissibleRender
																		userPermissions={this.props.userPermissions}
																		requiredPermissions={['can_edit_project']}
																		oneperm
																	>
																		<div className="selectbox__element dropdown-item" onClick={() => this.setState({editProjectModalIsOpen: true, projectOptionsDropdownIsOpen:false})}>
																			<div className="select__element-icon">
																				<i className="far  fa-pen"></i>
																			</div>
																			<div className="select__element-designation">
																				<div className="select__element-designation-content">
																					<span className="select__element-name">Edit project</span>
																				</div>
																			</div>
																		</div>
																	</PermissibleRender>
																	{console.log(this.props.project)}
																	{this.props.project.archived_at ?
																		<PermissibleRender
																			userPermissions={this.props.userPermissions}
																			requiredPermissions={['can_reopen_projects']}
																			oneperm
																		>
																			<div className="selectbox__element dropdown-item" onClick={() => this.setState({unarchiveProjectModalIsOpen: true, projectOptionsDropdownIsOpen: false})}>
																				<div className="select__element-icon">
																					<i className="far  fa-inbox-out"></i>
																				</div>
																				<div className="select__element-designation">
																					<div className="select__element-designation-content">
																						<span className="select__element-name">Unarchive project</span>
																					</div>
																				</div>
																			</div>
																		</PermissibleRender>
																		:
																		<PermissibleRender
																			userPermissions={this.props.userPermissions}
																			requiredPermissions={['can_archive_project']}
																			oneperm
																		>
																			<div className="selectbox__element dropdown-item" onClick={() => this.setState({archiveProjectModalIsOpen: true, projectOptionsDropdownIsOpen: false})}>
																				<div className="select__element-icon">
																					<i className="far  fa-inbox-in"></i>
																				</div>
																				<div className="select__element-designation">
																					<div className="select__element-designation-content">
																						<span className="select__element-name">Archive project</span>
																					</div>
																				</div>
																			</div>
																		</PermissibleRender>

																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											}
										</div>
										</PermissibleRender>
									</div>
									<PermissibleRender
										userPermissions={this.props.userPermissions}
										requiredPermissions={['can_create_ticket']}
										oneperm
									>
										<button className="btn fill blue round" onClick={() => this.setState({newTicketModalIsOpen: true})}>
											New ticket
										</button>
									</PermissibleRender>
								</div>
							</div>
						</div>



					</div>

					<div className="wrapper wide">
						<ul className="navigation-tab-links is-inline is-scrollable">
							<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/info`}>Info</NavLink></li>
							<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/tickets`}>Tickets</NavLink></li>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_project_activity']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/activity`}>Activity</NavLink></li>
							</PermissibleRender>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_project_kanban']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/kanban`}>Kanban</NavLink></li>
							</PermissibleRender>
							<PermissibleRender
								userPermissions={this.props.userPermissions}
								requiredPermissions={['can_view_project_files']}
								oneperm
							>
								<li><NavLink activeClassName="is-active" to={`${this.props.match.url}/files`}>Files</NavLink></li>
							</PermissibleRender>
						</ul>
					</div>

				</div>

				<Modal
					isOpen={this.state.projectFollowersModalIsOpen}
					onRequestClose={()=>{this.setState({projectFollowersModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<ProjectFollowersModal projectFollowers={this.props.projectFollowers} project={this.props.project} closeModal={()=>{this.setState({projectFollowersModalIsOpen: false})}} refreshFollowers={this.props.refreshProjectFollowers}/>
				</Modal>
				<Modal
					isOpen={this.state.newTicketModalIsOpen}
					onRequestClose={()=>{this.setState({newTicketModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<NewTicketModal project={this.props.project} closeModal={(data)=>{this.setState({newTicketModalIsOpen: false}); if(data){this.props.history.push(data)}}}/>
				</Modal>
				<Modal
					isOpen={this.state.editProjectModalIsOpen}
					onRequestClose={()=>{this.setState({editProjectModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<EditProjectModal project={this.props.project} closeModal={(data) => {this.setState({editProjectModalIsOpen: false}); if(data) {this.props.refreshProject(data)}}}/>
				</Modal>

				<Modal
					isOpen={this.state.archiveProjectModalIsOpen}
					onRequestClose={()=>{this.setState({archiveProjectModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
				<ArchiveProjectModal
					confirm={()=>this.archiveProject()}
					close={()=>this.setState({archiveProjectModalIsOpen: false})}/>
			</Modal>

				<Modal
					isOpen={this.state.afterArchiveOrUnarchiveProjectModalIsOpen}
					onRequestClose={()=>{this.setState({afterArchiveOrUnarchiveProjectModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<AfterArchiveOrUnarchiveProjectModal
						project={this.props.project}
						closeAfterRequest={()=>this.setState({afterArchiveOrUnarchiveProjectModalIsOpen: false})}/>

				</Modal>

				<Modal
					isOpen={this.state.unarchiveProjectModalIsOpen}
					onRequestClose={()=>{this.setState({unarchiveProjectModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={true}
				>
					<UnarchiveProjectModal
						confirm={()=>this.unarchiveProject()}
						close={()=>this.setState({unarchiveProjectModalIsOpen: false})}/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
