import React from 'react';

export default (props) => {
	const {onConfirm} = props;
	const handleConfirmClick = () => onConfirm();
	return (
		<span wrapper-name="default">
			<div
				className="message-box dialog-mask">
				<div
					className="dialog-content">
					<div className="dialog-body">
						<p>You will lose your data if you click on ESC key</p>
					</div>
					<footer>
						<button
							onClick={handleConfirmClick}
							className="btn outline blue">
							OK
						</button>
					</footer>
				</div>
			</div>
		</span>
	);
};
