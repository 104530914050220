import React from 'react';
import {withRouter} from 'react-router-dom';
import {useHistory} from 'react-router';

const NotAssignedModal = ({forDelete, affectedPeople}) => {
	const history = useHistory();

	const handleGoBack = () => history.goBack();

	return (
		<div className="e-modal centered">
			<div className="e-modal__container">
				<div>
					<div className="e-modal__head">
						<h2 className="e-modal__title centered">
							You are not assigned yet on this Project or
							Ticket, please ask the Project Manager.
						</h2>
					</div>
					<div className="e-modal__body">
						<div className="e-modal__body-overflow">
							<div className="f-row btn-container">
								<button
									className="btn fill blue round"
									onClick={handleGoBack}
								>
									Go Back
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(NotAssignedModal);
