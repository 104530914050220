import React, { Component, Fragment } from 'react'
import svgCompany from '../../../assets/images/defaultcompany.svg'
import getCompanyImage from '../../../components/helpers/getCompanyImage'
import getCompanyImageUrl from '../../../components/helpers/getCompanyImageUrl'
import defaultAvatar from '../../../assets/images/default-avatar.png'

export default class SingleCompany extends Component {

	constructor(props) {
		super(props)
		this.state = {
			avatarSrc: svgCompany
		}
	}

	async componentDidMount() {
		const { avatar_id } = this.props.company || {}
		if (avatar_id) {
			const avatarUrl = await getCompanyImage(avatar_id)
			this.setState({
				avatarSrc: avatarUrl
			})
		}
	}

	render() {
		const { showInfo, company } = this.props
		const { avatarSrc } = this.state
		return (
			<Fragment>
				<section className='profile-page__container'>
					<div className='user-profile_container custom-modal__overflow'>
						<div className='user-profile'>
							<div className='user_avatar'>
								<img alt='' width='100' src={avatarSrc} onError={(ev) => ev.target.src = svgCompany} />
							</div>
							<h2 className='user_name'>{showInfo ? company.name : ''}</h2>
							<ul className='user_position'>
								<li>{showInfo ? company.description || '' : ''}</li>
							</ul>
							<ul className='user_statistics'>
								<li><h3>{showInfo ? company.active_tickets : ''}</h3>
									<span>TICKETS</span></li>
								<li><h3>{showInfo ? company.active_projects : ''}</h3>
									<span>PROJECTS</span></li>
								<li><h3>0</h3> <span>FILES</span></li>
							</ul>
							<ul className='user_information'>
								<li className='t-order-1'>
									<i className='user-information-icon far  fa-user' />
									{showInfo ? `${company.contact_info.first_name || ''} ${company.contact_info.last_name || ''}` : ''}
								</li>
								<li className='t-order-1'>
									<i className='user-information-icon far  fa-envelope' />
									<a
										href={showInfo ? `mailto:${company.contact_info.email || ''}` : ''}>{showInfo ? company.contact_info.email || '' : ''}</a>
								</li>
								<li className='t-order-3'>
									<i className='user-information-icon fab fa-skype' />
									<a
										href={`skype:${showInfo ? company.contact_info.skype || '' : ''}?call`}>{showInfo ? company.contact_info.skype || '' : ''}</a>
								</li>
								<li className='t-order-5'>
									<i className='user-information-icon far  fa-phone-alt' />
									<a
										href={`tel:${showInfo ? company.contact_info.phone_number || '' : ''}?call`}>{showInfo ? company.contact_info.phone_number || '' : ''}</a>
								</li>
								<li className='t-order-2'>
									<i
										className='user-information-icon far  fa-external-link-alt' />
									<a
										href={showInfo ? `https://${company.contact_info.website || ''}` : ''}>{showInfo ? company.contact_info.website || '' : ''}</a>
								</li>
								<li className='t-order-4'>
									<i className='user-information-icon far  fa-map-marker-alt' />
									{showInfo ? company.location.address || '' : ''}
								</li>
							</ul>
							<div className='user-section'>
								<h3 className='user-section__title'>Company Info</h3>
								<ul className='user_payment'>
									<li>
										<span>Legal Info</span>
										<strong>{showInfo ? company.legal_info.name || '' : ''}</strong>
									</li>
									<li>
										<span>EIN/J/REGISTRATION NUMBER</span>
										<strong>{showInfo ? company.legal_info.registration_number || '' : ''}</strong>
									</li>
									<li>
										<span>VAT NUMBER</span>
										<strong>{showInfo ? company.legal_info.vat_number || '' : ''}</strong>
									</li>
								</ul>
							</div>
							<div className='user-section'>
								<h3 className='user-section__title'>Payment Info</h3>
								<ul className='user_payment'>
									<li>
										<span>BANK ACCOUNT NUMBER/ IBAN/ABA</span>
										<strong>{showInfo ? company.payment_info.account_number || '' : ''}</strong>
									</li>
									<li>
										<span>BANK NAME</span>
										<strong>{showInfo ? company.payment_info.bank_name || '' : ''}</strong>
									</li>
									<li>
										<span>SWIFT CODE</span>
										<strong>{showInfo ? company.payment_info.swift_code || '' : ''}</strong>
									</li>
									<li>
										<span>Currency</span>
										<strong>{showInfo ? company.payment_info.currency || '' : ''}</strong>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}
