import React, {Fragment, useState} from 'react';
import useForm from 'react-hook-form'
import {Input} from '../inputs/Input';
import {NavLink} from 'react-router-dom';
import {required, isEmailAddress} from "../inputs/validations";
import AuthService from "../services/api/auth";
import {history} from "../store";

export default function RecoveryForm() {
	const {register, handleSubmit, errors, formState, setError} = useForm({
		mode: "onSubmit"
	});

	const [success, setSuccess] = useState(false);

	const onSubmit = data => {
		AuthService.issueResetPassword(data)
			.then(() => {
				setSuccess(true);
			}).catch(err => {
				setError("email", true, "This email does not belong to any user")
				console.log(err)
			})
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="wrapper credentials">
				<div className="f-section">

					<h2 className="f-section__title no-border">Recover your password</h2>

					{!success ? <Fragment>
						<p className="f-info">
							Enter your email below and we'll send you password reset instructions.
						</p>
						<div className={'f-row'}>
							<div className={'f-element'}>
								<Input placeholder={"Email"} type="text"
									   register={
										   register({
											   validate: {isEmailAddress, required},
										   })
									   }
									   error={errors.email}
									   name="email"/>
							</div>
						</div>

						<div className="f-row is-regular">
							<div className="f-element is-half">
								<NavLink className="f-link" to={"/auth/login"}>&lt; Sign in</NavLink>
							</div>
						</div>

						<div className="f-row btn-container">
							<button 
								// disabled={!formState.isValid || formState.isSubmitting || formState.isSubmitted} 
								type="submit" 
								className="btn fill blue"
							>
								Recover your password
							</button>
						</div>
					</Fragment>
						:
					<Fragment>
						<p className="f-info">We have sent you an email with the password reset instructions.</p>
						<div className="f-row is-regular">
							<div className="f-element is-half">
								<NavLink className="f-link" to={"/auth/login"}>&lt; Back to Login</NavLink>
							</div>
						</div>
					</Fragment>}

				</div>
			</div>
		</form>

	)
}
