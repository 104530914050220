import React, {useEffect, useState} from 'react';
import UsersService from '../../services/api/users';
import Avatar from '../../components/Avatar';
import {NavLink} from 'react-router-dom';
import {PermissibleRender} from '@brainhubeu/react-permissible';
import {connect} from 'react-redux';

const SearchUsers = ({query, userPermissions}) => {
	const [users, setUsers] = useState([]);
	useEffect(() => {
		UsersService.searchUsers({
			search: query,
			related: 'user_profile.position'
		}).then(res => {
			const {users} = res;
			users.map((user) => {
				const {first_name, last_name} = user;
				user.label = `${first_name} ${last_name}`;
				return user;
			});
			setUsers(res.users);
		});
	}, [query]);
	return (
		<section className="page">
			<div className="wrapper">
				<div className="list-container">
					<div className="inner-container">
						<div className="morph-grid__container ctrl list-view">
							{users.length ? <>
									{users.map(user =>
										<div key={user.id}
											 className="morph-grid__item user-item">
											<div
												className="user-item__group alpha-beta">
												<div
													className="user-item__cell alpha">
													<div
														className="user-item__profile-preview">
														<PermissibleRender
															userPermissions={userPermissions}
															requiredPermissions={['can_view_user_profile']}
															oneperm
															renderOtherwise={
																<a style={{pointerEvents: 'none'}}>
																	<Avatar
																		className="profile-preview-avatar"
																		width={64}
																		height={64}
																		altText={`${user.label}`}
																		userId={user.id}
																		hasAvatar={user.has_avatar}
																	/>
																	<span
																		className="profile-preview-name">{user.labe}</span>
																</a>
															}
														>
															<NavLink
																to={`/people/${user.id}`}>
																<Avatar
																	className="profile-preview-avatar"
																	width={64}
																	height={64}
																	altText={`${user.label}`}
																	userId={user.id}
																	hasAvatar={user.has_avatar}
																/>
																<span
																	className="profile-preview-name">{user.label}</span>
															</NavLink>
														</PermissibleRender>
													</div>
												</div>
												<div
													className="user-item__cell beta">
													<div
														className="user-item__profile-position">{user.user_profile.position ? user.user_profile.position.name : '-'}</div>
												</div>
											</div>
											<div
												className="user-item__group gamma-delta">
												<div
													className="user-item__cell gamma">
													<div
														className="user-item__relevant-data">
														<div
															className="relevant-data-value">{user.active_projects}</div>
														<div
															className="relevant-data-label">Active <br/> Projects
														</div>
													</div>
												</div>
												<div
													className="user-item__cell delta">
													<div
														className="user-item__relevant-data">
														<div
															className="relevant-data-value">{user.active_tickets}</div>
														<div
															className="relevant-data-label">Active <br/> Tickets
														</div>
													</div>
												</div>
											</div>
										</div>
									)}</> :
								<h3>
									There are no users in this search criteria
								</h3>
							}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	};
}

export default connect(mapStateToProps)(SearchUsers);
