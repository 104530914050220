import React, {Component, Fragment} from 'react';
import UploadService from "../../services/api/upload";

export default class Files extends Component {

	constructor(props) {
		super(props);
		this.state = {
			attachments: []
		}
	}

	componentDidMount() {
		UploadService.getResources(this.props.resource, this.props.resource_id)
			.then(attachments => {
				this.setState({
					attachments
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	formatFileSize(bytes, decimalPoint) {
		if (bytes == 0) return '0 Bytes';
		var k = 1000,
			dm = decimalPoint || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	render() {
		return (
			<Fragment>
				<div className="custom-tab-container">
					<section id="files" role="tabpanel" className="custom-tab-content is-active">
						<div className="morph-grid__container ctrl card-view files-view">
							{this.state.attachments.map(attachment => {
								return (
									<div className="morph-grid__item files-item">
										<div className="alpha attachment">
											<img src={attachment.url} alt={attachment.file_file_name}/>
										</div>
										<div className="beta attachment-name">
											{attachment.file_file_name}
										</div>
										<div className="gamma attachment-size">
											{this.formatFileSize(attachment.file_file_size)}
										</div>
									</div>
								)
							})
							}
						</div>
					</section>
				</div>
			</Fragment>
		);
	}
}
