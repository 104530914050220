import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import SingleCompany from './CompanyExpanded'
import SubNavigation from './SubNavigation'
import { Redirect } from 'react-router'
import CompaniesService from '../../../services/api/companies'
import ListProjects from './ListProjects'


export default class Companies extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showInfo: false,
			company: null
		}
	}

	componentDidMount() {
		this.getCompany(this.props.match.params.slug)
	}

	getCompany(slug) {
		this.setState({ company: null })
		CompaniesService.getBySlug(slug)
			.then(res => {
				this.setState({
					showInfo: true,
					company: res
				})
			})
	}

	refreshCompany() {
		this.setState({
			showInfo: false
		}, () => this.getCompany(this.props.match.params.slug))

	}

	render() {
		let { showInfo, company } = this.state
		return (
			<Fragment>
				<SubNavigation
					title={showInfo ? company.name : ''}
					company={company}
					onUpdate={this.refreshCompany.bind(this)}
				/>
				<Fragment>
					<Fragment>
						<Switch>
							<Redirect
								exact from={this.props.match.url}
								to={`${this.props.match.url}/company`}
							/>
							<Route
								path={`${this.props.match.url}/company`}
								component={(props) =>
									<SingleCompany
										{...this.props}
										company={company}
										showInfo={showInfo}
									/>
								}
							/>
							<Route
								path={`${this.props.match.url}/projects`}
								component={(props) =>
									<ListProjects
										{...this.props}
										projects={{}}
									/>
								}
							/>
						</Switch>
					</Fragment>
				</Fragment>
			</Fragment>
		)
	}
}
