import request from "../shared/request";
import axios from "axios";
const mime = require('mime-types');

export default class UploadService {
	static removeResource(resource_type, resource_id, file_name) {
		return request({
			url: `attachments/${resource_type}/${resource_id}/${file_name}`,
			method: 'DELETE'
		})
	}

	static getResources(resource_type, resource_id) {
		return request({
			url: '/attachments',
			method: 'GET',
			params: {
				resource_id,
				resource_type
			}
		})
	}

	static signResourceAndUpload(resource_type, resource_id, file) {
		return UploadService
			.signResource(resource_type, resource_id, file.name)
			.then(async (response) =>  {
				await axios.put(response.signedRequest, file, {
					headers: {
						'Content-Type': mime.lookup(file.name)
					}
				});
				return response;
			})
	}

	static signResource(resource_type, resource_id, file_name) {
		return request({
			url: '/attachments',
			method: 'POST',
			data: {
				resource_type,
				resource_id,
				file_name
			}
		})
	}

	static uploadFile(data) {
		return request({
			url: '/attachments',
			method: 'POST',
			data: data
		})
	}

	static getFiles(params) {
		return request({
			url: `/attachments`,
			method: 'GET',
			params
		})

	}

	static removeFile(id) {
		return request({
			url: `attachments/${id}`,
			method: 'DELETE'
		})
	}

	static getById(id) {
		return request({
			url: `attachments/${id}`,
			method: 'GET'
		})
	}
}
