import React, {Fragment} from 'react';
import Select from 'react-select';

export function SelectInput ({ register,value, options, name, onChange, error, placeholder}) {
	return (
		<Fragment>
			<input className={'input'}
				   type="hidden"
				   ref={register}
				   name={name}
				   value={value}
				   id={name} />
			<Select placeholder={placeholder} options={options} value={value && options.filter(option => option.value === value)[0]} classNamePrefix="react-select" className={`standard-select ${error ? 'is-error' : ''}`} onChange={(event) => { onChange(event.value)}}/>
			{error && <p className={'is-error'}>{error.message}</p>}
		</Fragment>
	);
}
