import request from '../shared/request';

export default class ProjectsService {
	static listProjects(params) {
		return request({
			url: '/projects/',
			method: 'GET',
			params
		})
	}
	static getBySlug(params, slug) {
		return request({
			url: `/projects/${slug}`,
			method: 'GET',
			params
		})
	}

	static createProject (data) {
		return request({
			url: `/projects`,
			method: 'POST',
			data: data
		})
	}
	static editProject(slug, data){
		return request({
			url: `/projects/${slug}`,
			method: 'PUT',
			data: data,
		})
	}
	static getTickets(params, slug) {
		return request({
			url: `/projects/${slug}/tickets`,
			method: 'GET',
			params
		})
	}

	static getActivity(params) {
		return request({
			url: '/projects/activity',
			method: 'GET',
			params
		})
	}
	static archiveProject(id) {
		return request({
			url: `/projects/${id}/archive`,
			method: 'PUT'
		})
	}
	static unarchiveProject(id) {
		return request({
			url: `/projects/${id}/unarchive`,
			method: 'PUT'
		})
	}

	static searchTickets(params) {
		return request({
			url: '/projects/search',
			method: 'GET',
			params
		})
	}
	static getAttachments(id){
		return request({
			url:`/projects/${id}/attachments`,
			method: 'GET',
		})
	}
	static downloadAllAttachments(id){
		return request({
			url:`/projects/${id}/attachments/`,

		})
	}
}
