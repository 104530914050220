import React, {Component, Fragment} from 'react';
import moment from "moment";
import HtmlEditor from "../HtmlEditor";
import UploadFile from "../UploadFile";
import Avatar from "../Avatar";
import {canRenderPreview, getFontAwesomeIconFromMIME} from "../helpers/preview";
import {connect} from "react-redux";
const _ = require('lodash');

class Comment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isEdit: false,
			content: null,
			attachments: [],
			editMinutesRemaining: 0
		}
		this.interval = null;
	}

	componentDidMount() {
		this.getTimeLeftForEdit(this.props.comment.created_at);
		this.interval = setInterval(() => {
			this.getTimeLeftForEdit(this.props.comment.created_at);
		}, 60 * 1000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}


	editState() {
		if (this.state.isEdit) {
			this.props.editComment(this.props.comment, this.state.content, this.state.attachments);
		}
		this.setState({isEdit: !this.state.isEdit})
	}

	onContentChange(input) {
		this.setState({content: input})
	}

	attachmentsList(attachment, noConcat) {
		if (noConcat) {
			this.setState({attachments: attachment})
		} else {
			this.setState({attachments: this.state.attachments.concat(attachment)})
		}
	}

	removeFromAttachments(id) {
		this.setState({
			attachments: this.state.attachments.filter(e => e.id !== id)
		});
	}


	onDoubleClickEditComment = (commentId, commentCreatedAt, ownerId) => {
		let interval = 1000 * 60 * 15;

		if (commentId !== ownerId) {
			return;
		}

		if (((new Date) - new Date(commentCreatedAt)) < interval) {
			this.setState({isEdit: true});
		}

	};

	isEditable(commentId, commentCreatedAt, ownerId) {
		let interval = 1000 * 60 * 15;


		let canEditComments = _.includes(this.props.userPermissions, 'can_edit_comments');

		if (!canEditComments) {
			return false;
		}
		if (commentId !== ownerId) {
			return false;
		}
		if (((new Date) - new Date(commentCreatedAt)) < interval) {
			return true;
		}
		return false;
	}

	getTimeLeftForEdit(commentCreatedAt) {
		let interval = 1000 * 60 * 15;
		this.setState({
			editMinutesRemaining: 15 - moment.duration(moment().diff(moment(commentCreatedAt))).minutes()
		})
	}

	render() {
		let comment = this.props.comment;
		return (
			<div className="act__item comment">
				<div className="item__head">
					{/*<div className="act__type far-icon circle fill gray">*/}
					{/*	<i className="far  fa-comment-alt" aria-hidden="true"></i>*/}
					{/*</div>*/}
					<div className="act__owner" style={{margin:0}}>
						<a className="profile__link">
							<Avatar
								className={'custom-single-user-select__avatar'}
								width={30}
								height={30}
								altText={`${comment.owner.first_name} ${comment.owner.first_name}`}
								userId={comment.owner.id}
								hasAvatar={comment.owner.has_avatar}
							/>

							{comment.owner.first_name + " " + comment.owner.last_name}
						</a>
					</div>
					<div className="act__timestamp">
						{this.isEditable(comment.owner.id, comment.created_at, this.props.user_id) && this.state.editMinutesRemaining > 0 ? <span style={{cursor: "pointer"}} onClick={(e) => {
							e.preventDefault();
							if (this.isEditable(comment.owner.id, comment.created_at, this.props.user_id)) {
								this.setState({isEdit: true});
							}
						}}>Edit for {this.state.editMinutesRemaining} minutes</span> : moment(comment.created_at).format('h:mm a')}
					</div>

					{/* {(comment.owner.id === this.props.user_id) ?
						<DropdownEditComment
							editState={this.editState.bind(this)}
							comment={comment}
							removeComment={this.props.removeCommentAfterDelete}
							deleteComment={this.props.deleteComment}
						/> : null
					} */}

				</div>
				<div className="item__body">
					{!this.state.isEdit ?
						<Fragment>
							<div
								className="comment__content" dangerouslySetInnerHTML={{__html: comment.meta?.content ?? comment.meta}}>
							</div>
							{
								(comment.attachments.length > 0) ?
									<div className="file-upload">
										<div className="uploader-files">
											{comment.attachments.map((attachment,index) => {
												return (
													<div className="uploader-file" key={index}>
														<div className="file-header">
															<div className="file-avatar">
																{canRenderPreview(attachment.file_file_name) ? <img src={attachment.url}/> : <i className={`fa ${getFontAwesomeIconFromMIME(attachment.file_file_name)} fa-4`} aria-hidden="true"></i>}
															</div>
														</div>
														<div className="file-details">
															<div className="file-name">
																<a target="_blank" href={attachment.url}>
																	{attachment.file_file_name}
																</a>
															</div>
														</div>
													</div>
												)
											})}
										</div>
									</div>
									: null

							}
						</Fragment>
						:
						<Fragment>
							<HtmlEditor
								html={comment.meta?.content ?? comment.meta}
								onChange={(e) => this.onContentChange(e)}/>

								<UploadFile
									ref="child"
									resource={'Comment'}
									resource_id={comment.id}
									files={comment.attachments}
								/>

							<div className="f-row btn-container f-cta-container">
								<button className="btn outline gainsboro" onClick={() => this.setState({isEdit: !this.state.isEdit})}>
									Close
								</button>
								<button className="btn fill blue is-animated" onClick={() => {this.editState()}}>
									Save
								</button>
							</div>
						</Fragment>
					}

				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(Comment)
