import request from "../shared/request";


export default class CompaniesService {
	static listCompanies(params = {}) {
		// to implement pagination for companies list
		params.limit = -1;
		return request({
			url: '/companies',
			method: 'GET',
			params
		})
	}
	static getBySlug(slug) {
		return request({
			url: `/companies/${slug}`,
			method: 'GET'
		})
	}
	static getProjectCompanies(id, params) {
		return request({
			url: `/companies/${id}/projects`,
			method: 'GET',
			params
		})
	}

	static postCompany(data) {
		return request({
			url: '/companies',
			method: 'POST',
			data
		})
	}
	static editCompany(id, data) {
		return request({
			url: `/companies/${id}`,
			method: 'PUT',
			data
		})
	}
}
