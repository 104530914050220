import React, {useMemo} from 'react';
import Select from 'react-select';
import * as PropTypes from 'prop-types';

export const ChangeCompanySelect = (props) => {
	const {value, onChange, companies} = props;

	const options = useMemo(() => companies.map((company) => ({
		value: company.id,
		label: company.name
	})), [companies]);

	const selectedValue = useMemo(() => ({
		value,
		label: companies.find((company) => company.id === value)?.name || ''
	}), [companies, value]);

	return <Select
		options={options}
		value={selectedValue}
		onChange={onChange}
	/>;
};

ChangeCompanySelect.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	companies: PropTypes.array
};
