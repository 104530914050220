import React, {Component, Fragment} from 'react';
import TicketsService from "../../services/api/tickets";
import moment from "moment";
import ActivityEntry from "./ActivityEntry";
import UsersService from "../../services/api/users";

const getCategoryFromDate = (date) => {
	return moment(date).format("MMMM D, YYYY")
};

export default class Activity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activities: {events: [], project: {}},
			events: {},
			user: {}
		}
	}
	componentDidMount() {
		this.getActivities()
		if (!this.props.isAuthenticated) {
			this.getCurrentUser();
		}
	}

	getCurrentUser() {
		UsersService.getCurrentUser()
			.then(user => {
				this.setState({
					user: {
						id: user.id,
						first_name: user.first_name,
						last_name: user.last_name
					}
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	getActivities() {
		let activitiesCategories = {};
		TicketsService.getActivities({id: this.props.ticket_id}).then(res => {
			res.events.forEach((activity) => {
				const category = getCategoryFromDate(activity.created_at);
				if(!activitiesCategories[category]) {
					activitiesCategories[category] = [];
				}
				activitiesCategories[category].push(activity)
			})
			this.setState({
				activities: res,
				events: activitiesCategories,
			})
		})
	}

	render() {

		let DateNow = getCategoryFromDate(new Date());
		return (
			<Fragment>
				<div className="custom-tab-container">
				<section id="activity" role="tabpanel" className="custom-tab-content is-active">
					<div className="act__container">
						<div className="act__group">
							{Object
								.keys(this.state.events)
								.map((category) => {
									return <div className="act__timeframe" key={category}>
											<div className="act__date">
												{category === DateNow ? 'Today' : `${category}`}
											</div>
											{this.state.events[category].map(activity => {
												let tickets = {};
												tickets.title = this.state.activities.title;
												tickets.id = this.state.activities.id;
												let project = this.state.activities.project;
												return(
													<ActivityEntry
														key={activity.id}
														user={this.state.user}
														activity={activity}
														tickets={tickets}
														project={project}
													/>
												)
											})}
									</div>
								})}
							</div>
					</div>
				</section>
				</div>
			</Fragment>
		);
	}
}
