import React, {Component, Fragment} from 'react';
import {Provider} from 'react-redux';
import {Switch, Redirect, Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/es/integration/react';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import SetupTracker from './screens/SetupTracker';
import Auth from './screens/Auth';
import Dashboard from './screens/Dashboard';
import Projects from './screens/Projects';
import Tickets from './screens/Tickets';
import People from './screens/People';
import Companies from './screens/Companies';
import Settings from './screens/Settings';
import Profile from './screens/Profile';
import Search from "./screens/Search";
import  {store,persistor,history} from './store';
import Header from "./screens/shared/Header";
import Cookies from 'universal-cookie';
import Landing from './screens/Landing';
import ClearCache from "react-clear-cache";
import {Reports} from './screens/Reports'
import {Populate} from './screens/Populate';


export default class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			persisted: false
		};

		this.dispatchAuthenticationStatus = this.dispatchAuthenticationStatus.bind(this);
	}

	dispatchAuthenticationStatus() {
		const cookies = new Cookies();
		store.dispatch({
			type: "@@huskytracker/USER_AUTHENTICATED",
			payload: !!cookies.get("token")
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.persisted !== prevState.persisted){
			this.dispatchAuthenticationStatus()
		}
	}

	render() {
		return (
			<div id="app">
				<ClearCache duration={60000} auto={true}>
					{({ isLatestVersion, emptyCacheStorage }) => (
					<Provider store={store}>
						<PersistGate onBeforeLift={() => this.setState({persisted: true})} persistor={persistor}>
							<ConnectedRouter history={history}>
								<Header/>
								<Switch>
									<Redirect exact from={'/'} to={'/auth/login'} />
									{/*<PublicRoute path="/" exact component={Landing} />*/}
									<PublicRoute path="/account" component={SetupTracker} />
									<PublicRoute path="/auth" component={Auth}/>
									<PrivateRoute path="/dashboard" component={Dashboard}/>
									{/*<PrivateRoute path="/populate" component={Populate}/>*/}
									<PrivateRoute path="/projects" component={Projects}/>
									<PrivateRoute path="/tickets" component={Tickets}/>
									<PrivateRoute path="/people" component={People}/>
									<PrivateRoute path="/companies" component={Companies}/>
									<PrivateRoute path="/settings" component={Settings} requiredPermissions={['can_change_settings']}/>
									<PrivateRoute path="/reports" component={Reports} requiredPermissions={['can_view_reports_page']}  />
									<PrivateRoute path="/my/info" component={Profile}/>
									<PrivateRoute path="/search" component={Search}/>
									<Route render={() => <Redirect to="/" />} />
								</Switch>
							</ConnectedRouter>
						</PersistGate>
					</Provider>
					)}
				</ClearCache>
			</div>
		);
	}
}
