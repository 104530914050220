import React, {Component, Fragment} from 'react';
import TicketsService from '../../services/api/tickets';
import Pagination from '../../components/Pagination';
import TicketSummaryPlaceholder
	from '../../placeholders/TicketSummaryPlaceholder';
import TicketItemList from '../../components/Ticket/TicketItemList';
import CustomSelect from '../../components/CustomSelect';
import TaxonomiesService from '../../services/api/taxonomies';
import UsersService from '../../services/api/users';
import {connect} from 'react-redux';
import ProjectsService from '../../services/api/projects';
import {getFilters} from '../../helpers/filters';
import TicketItemList2 from '../../components/Ticket/TicketItemList2';

const _ = require('lodash');

class Tickets extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			limit: 100,
			pages: 1,
			offset: 0,
			hasMore: false,
			tickets: [],
			status_options: [
				{
					label: 'All Tickets',
					value: 'active,archived'
				},
				{
					label: 'Active Tickets',
					value: 'active'
				},
				{
					label: 'Closed Tickets',
					value: 'archived'
				}
			],
			filters: getFilters({
				status: this.props.user?.meta?.preferences?.default_ticket_filtering === 'active,archived' ? {
					label: 'All Tickets',
					value: 'active,archived'
				} : {label: 'Active Tickets', value: 'active'},
				// filter: {label: 'No filter', value: null},
				filter: {
					label: this.props.isTicketsPage ? 'Assigned to me' : 'No Filter',
					value: this.props.isTicketsPage ? 'assigned' : 'assigned,following,owned',
				},
				order_by: {label: 'Order by importance', value: null},
				order_type: {label: 'Direction', value: null},
				// group_by: {label: 'Group by', value: null}
				group_by: {
					label: this.props.isTicketsPage ? 'Project' : 'Group by',
					value: this.props.isTicketsPage ? 'project' : null,
				},
			}),
			currentSearchLocation: window.location.search,
			statuses: [],
			ticketsGroup: {},
			users: []
		};
		this.getTickets = this.getTickets.bind(this);
		this.changePage = this.changePage.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.refreshTickets !== this.props.refreshTickets) {
			if (this.props.refreshTickets) {
				this.refreshTickets();
			}
		}

		if (window.location.search !== this.state.currentSearchLocation) {
			this.setState({
				filters: getFilters({
					status: this.props.user?.meta?.preferences?.default_ticket_filtering === 'active,archived' ? {
						label: 'All Tickets',
						value: 'active,archived'
					} : {label: 'Active Tickets', value: 'active'},
					filter: {label: 'No filter', value: 'assigned,following,owned'},
					order_by: {label: 'Order by importance', value: null},
					order_type: {label: 'Direction', value: null},
					group_by: {label: 'Group by', value: null}
				}),
				currentSearchLocation: window.location.search,
				tickets: []
			}, () => this.refreshTickets());

		}

		console.log("fills" , this.state.filters);
	}

	fetchTickets() {
		return this.props.isProjectList ?
			ProjectsService.getTickets(
				{
					limit: this.state.limit,
					offset: this.state.offset,
					related: this.state.related,
					filters: this.state.filters
				},
				this.props.project.readable_id
			)
			:
			TicketsService.getAll({
				limit: this.state.limit,
				offset: this.state.offset,
				related: this.state.related,
				filters: this.state.filters
			});
	}

	getTickets() {
		this.setState({
			loading: true
		}, () => {
			this.fetchTickets()
				.then(response => {
					if (this.state.filters.group_by.value) {
						let object = this.groupTickets(response.data);
						this.setState({ticketsGroup: object});
					}
					let tickets = response.data;
					//tickets = _.sortBy(tickets, [function(o) {return o.status.name === 'Closed'}])
					this.setState({
						tickets,
						pages: response.pages,
						offset: response.offset,
						loading: false
					});
				})
				.catch(err => {
					console.error(err);
				});
		});
	}

	getStatuses() {
		TaxonomiesService.get('statuses')
			.then(res => {
				let statuses = res.map(status => {
					return {
						value: status.id,
						label: status.name,
						order: status.order,
						bgColor: status.color
					};
				}).sort((a, b) => a.order - b.order);
				this.setState({statuses});
			})
			.catch(err => {
				console.log(err);
			});
	}

	refreshTickets() {
		this.setState({
			loading: true,
			limit: 100,
			pages: 1,
			offset: 0
		}, () => this.getTickets());
	}

	getPeople() {
		UsersService.getAllUsers({
			limit: '-1',
			related: 'user_profile.position'
		})
			.then(res => {
				this.setState({
					users: res.data.map(user => {
						return {
							label: user.first_name + ' ' + user.last_name,
							value: user.id,
							id: user.id,
							has_avatar: user.has_avatar
						};
					})
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	componentDidMount() {
		console.log("filterss" , this.state.filters);
		this.getStatuses();
		this.getPeople();
		this.filterBasedOnPreferences();
	}

	filterBasedOnPreferences() {
		let status = this.state.status_options.filter(opt => opt.value === _.get(this.props.user, 'meta.preferences.default_ticket_filtering', 'active,archived'))[0];
		this.setState({
			filters: {
				...this.state.filters,
				status
			}
		}, () => {
			this.getTickets();
		});
	}

	changePage(page) {
		if (page >= 1 && page <= this.state.pages) {
			this.setState({
				offset: (page - 1) * this.state.limit
			}, () => {
				this.getTickets();
			});
		}
	}

	onFilter(name, value) {
		let filters = {
			...this.state.filters,
			[name]: value
		};
		this.setState({
			filters,
			loading: true,
			limit: 100,
			pages: 1,
			offset: 0
		}, () => this.getTickets());
	}

	groupTickets(data) {
		let ticketsCategories = {};
		let tickets = [...data];
		tickets.forEach((ticket) => {
			let category;
			const groupByValue = this.state.filters.group_by.value;

			switch (groupByValue) {
				case 'status':
					category = [ticket.status.name];
					break;
				case 'project':
					category = [ticket.project.title];
					break;
				case 'people':
					category = [];
					if (ticket.owner_id) category.push(ticket.owner_id);
					if (ticket.assignee_id && ticket.owner_id !== ticket.assignee_id) category.push(ticket.assignee_id);
					break;
				default:
					category = ['All'];
			}

			category.map(key => {
				let keyName = key;
				if (groupByValue === 'people') {
					keyName = this.userName(key);
				}

				if (!ticketsCategories[keyName]) {
					ticketsCategories[keyName] = [];
				}

				ticketsCategories[keyName].push(ticket);
			});

		});


		return ticketsCategories;
	}

	userName(user_id) {
		const user = this.state.users.find(user => user.id == user_id);
		return user.label;
	}

	render() {
		let project = this.props.project || {};
		project.followers = [];


		return (
			<Fragment>
				<div className="secondary-bottom">
					<div className="wrapper">
						<ul className="filter-container">

							<CustomSelect name="status"
										  className="filter"
										  value={this.state.filters.status}
										  onChange={this.onFilter.bind(this, 'status')}
										  options={this.state.status_options}
							/>

							<CustomSelect name="filter"
										  className="filter"
										  value={this.state.filters.filter}
										  onChange={this.onFilter.bind(this, 'filter')}
										  options={[
											  {
												  label: 'No filter',
												  value: 'assigned,following,owned'
											  },
											  {
												  label: 'Assigned to me',
												  value: 'assigned'
											  },
											  {
												  label: 'Followed by me',
												  value: 'following'
											  },
											  {
												  label: 'Created by me',
												  value: 'owned'
											  }
										  ]}
							/>

							<CustomSelect name="order_by"
										  className="filter"
										  value={this.state.filters.order_by}
										  onChange={this.onFilter.bind(this, 'order_by')}
										  options={[
											  {
												  label: 'Order by importance',
												  value: null
											  },
											  {
												  label: 'By activity',
												  value: 'activity'
											  },
											  {
												  label: 'By title',
												  value: 'title'
											  },
											  {
												  label: 'By due date',
												  value: 'due_date'
											  },
											  {
												  label: 'By creation',
												  value: 'created_at'
											  },
											  {
												  label: 'By Project',
												  value: 'project'
											  }
										  ]}
							/>

							<CustomSelect name="order_type"
										  className="filter"
										  value={this.state.filters.order_type}
										  onChange={this.onFilter.bind(this, 'order_type')}
										  options={[
											  {
												  label: 'Direction',
												  value: null
											  },
											  {
												  label: 'First to last',
												  value: 'asc'
											  },
											  {
												  label: 'Last to first',
												  value: 'desc'
											  }
										  ]}
							/>

							<CustomSelect name="group_by"
										  className="filter"
										  value={this.state.filters.group_by}
										  onChange={this.onFilter.bind(this, 'group_by')}
										  options={[
											  {
												  label: 'Group by',
												  value: null
											  },
											  {
												  label: this.props.isProjectList ? 'People' : 'Project',
												  value: this.props.isProjectList ? 'people' : 'project'
											  },
											  {
												  label: 'By activity',
												  value: 'activity'
											  },
											  {
												  label: 'Newest',
												  value: 'created'
											  },
											  {
												  label: 'Due date',
												  value: 'due_date'
											  },
											  {
												  label: 'Status',
												  value: 'status'
											  }

										  ]}
							/>

						</ul>
					</div>
				</div>
				{this.state.filters.group_by.value ?
					Object.keys(this.state.ticketsGroup).map((category) => {
						return (
							<section className="page">
								<div className="wrapper">
									<div className="list-container">
										{this.state.loading && !this.state.tickets.length &&
										<div className="inner-container">
											<div
												className={`morph-grid__container list-view mod-margin`}>
												<TicketSummaryPlaceholder
													items={10}/>
											</div>
										</div>
										}
										{this.state.tickets.length ?
											<div
												className="inner-container">
												<div
													className={`morph-grid__container list-view mod-margin`} style={{paddingTop: '20px' , paddingBottom:'20px' , paddingLeft:'0px' , paddingRight:'0px'}}>
													<div
														className="activity__date border-b py-3.5" style={{borderColor:'#C3C7D980'}}>
														<strong className="text-base leading-6 text-deep_koamaru" style={{letterSpacing:'-0.32px' , paddingLeft:'30px' , paddingRight:'30px' , fontFamily:'SFUITextSemibold'}}> {category} </strong>
													</div>
													{this.state.ticketsGroup[category].map(ticket =>
														<TicketItemList2
															isProjectList={this.props.isProjectList}
															key={this.state.filters.group_by.value === 'people' ? ticket.id + category : ticket.id}
															statuses={this.state.statuses}
															project={project}
															onUpdate={() => this.refreshTickets()}
															ticket={ticket}
															screen={'Tickets'}
															people={this.state.users}
														/>)}
												</div>
											</div>
											:
											<div
												className="activity-container">
												<center><h4>There is no
													ticket within this
													search criteria.</h4>
												</center>
											</div>
										}

									</div>
								</div>
							</section>
						);
					})
					:
					<section className="page">
						<div className="wrapper">
							<div className="list-container">
								{this.state.loading && !this.state.tickets.length &&
								<div className="inner-container">
									<div
										className={`morph-grid__container list-view mod-margin`}>
										<TicketSummaryPlaceholder items={10}/>
									</div>
								</div>
								}
								{this.state.tickets.length ?
									<div className="inner-container">
										<div
											className={`morph-grid__container list-view mod-margin`}>
											{this.state.tickets.map(ticket =>
												<TicketItemList2
													key={ticket.id}
													statuses={this.state.statuses}
													project={project}
													isProjectList={this.props.isProjectList}
													onUpdate={() => this.refreshTickets()}
													ticket={ticket}
													screen={'Tickets'}
													people={this.state.users}
												/>)}
										</div>
									</div>
									:
									<div className="activity-container">
										<center><h4>There is no ticket within
											this search criteria.</h4></center>
									</div>
								}

							</div>
						</div>
					</section>
				}
				<Pagination pages={this.state.pages}
							changePage={(page) => this.changePage(page)}/>
			</Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, null)(Tickets);
