import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom'
import Modal from "react-modal";
import NewTicketModal from "../Modals/NewTicketModal";
import ProjectsService from "../../services/api/projects";
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';

class SubNavigation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			newTicketModalIsOpen: false,
			projects: [],
			followers: []
		}
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.getAllProjects();
	}

	getAllProjects() {
		ProjectsService.listProjects().then((res => {
			this.setState({
				projects: res.map(project => {
					return {
						label: project.title,
						value: project.id,
						readable_id: project.readable_id
					}
				})
			})
		}))
	}

	getFollowersByProject(slug) {
		ProjectsService.getBySlug({},slug).then(res => {
			this.setState({
				followers: res.followers
			})

		})
	}

	render() {
		return (
			<Fragment>
				<div className="sub-navigation">
					<div className="wrapper wide">
						<div className="page__head">
							<h1 className="page-title">{this.props.title}</h1>
							<div className="btn-wrap page-controls">
								<PermissibleRender
									userPermissions={this.props.userPermissions}
									requiredPermissions={['can_create_ticket']}
									oneperm
								>
									{/* <button className="btn fill blue new-ticket-modal__open"
											onClick={() => {
												this.setState({
													newTicketModalIsOpen: true
												})
											}}>
										Add Ticket
									</button> */}
								</PermissibleRender>
							</div>
						</div>

					</div>
					<Modal
						isOpen={this.state.newTicketModalIsOpen}
						onRequestClose={()=>{this.setState({newTicketModalIsOpen: false})}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={true}
					>
						<NewTicketModal
							closeModal={() => {this.setState({newTicketModalIsOpen: false}); this.props.onRefresh()}}
							project={{followers: this.state.followers}}
							showSelectProject={true}
							getProjectFollowers={this.getFollowersByProject.bind(this)}
							selectProjects={this.state.projects}
						/>
					</Modal>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(withRouter(SubNavigation));
