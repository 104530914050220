import React, {useEffect, useState} from 'react';
import SubNavigation from '../shared/SubNavigation/SubNavigation';
import SearchUsers from './SearchUsers';
import SearchTickets from './SearchTickets';
import SearchProjects from './SearchProjects';
import SearchComments from './SearchComments';

export default function Search(props) {
	const [query, setQuery] = useState('');

	useEffect(() => {
		let search = new URLSearchParams(props.location.search).get('query');
		setQuery(search);
	}, [props.location.search]);

	return (
		<>
			<SubNavigation title={`Results for "${query}"`}/>
			<section className={'page search'}>
				<div className={'wrapper'}>
					<div className={'w-container'}>
						<div className={'table-container with-grid people'}>
							<h3>Users</h3>
							<SearchUsers query={query}/>
							<h3>Tickets</h3>
							<SearchTickets query={query}/>
							<h3>Projects</h3>
							<SearchProjects query={query}/>
							<h3>Comments</h3>
							<SearchComments query={query}/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
