import React, {Component} from 'react';
import {connect} from "react-redux";
import PermissionsService from "../../../services/api/permissions";
const _ = require('lodash')

class Permissions extends Component {

	constructor(props) {
		super(props);
		this.state = {
			permissions: [],
			selectedUserPermissions: [],
			userRoles: []
		}
	}


	getUserPermissions() {
		PermissionsService.getPermissionsForUser(this.props.match.params.id)
			.then(response => {
				let userPermissions = response.data.userPermissions;
				this.setState({
					selectedUserPermissions: userPermissions,
					userRoles: [response.data.user.role]
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	getAllPermissions() {
		PermissionsService.getPermissions()
			.then(response => {
				console.log(response.data);
				this.setState({
					permissions: response.data
				})
			})
			.catch(err => {

			})
	}

	togglePermissionForRole(permissionName, is_active) {
		PermissionsService.togglePermissionForUser(this.props.match.params.id, {
			permission_name: permissionName,
			is_active
		}).then(response => {
				console.log(response);
			})
	}

	componentDidMount() {
		this.getAllPermissions();
		this.getUserPermissions();
		console.log(this.props.match.params.id)
	}

	render() {
		return (
			<section className="page">
				<div className="wrapper">
					<div className="permissions_container desktop">
						<div className="permissions_head">
							<div className="permissions_row">
								<div className="permissions_col row-label"></div>
								<div className={`permissions_col col-employee`} style={{textTransform: 'capitalize'}}>Permissions</div>
							</div>
						</div>
						<div className="permissions_body">
							<div className="permissions_section">
								<div className="section-label">Name</div>
								{this.state.permissions.map(permission => {
									return (
										<div className="permissions_row">
											<div className="permissions_col row-label">{permission.description}</div>
											{this.state.userRoles.map(role => {
												let is_active = _(this.state.selectedUserPermissions)
													.filter((permission_name) => _.every([
														_.includes([permission.name], permission_name)
													]))
													.reduce((acc, cur) => cur, false);
												return (
													<div className={`permissions_col col-${role.name}`}>
														<input defaultChecked={is_active} onChange={() => this.togglePermissionForRole(permission.name, is_active)} id={`${role.id}-${permission.id}`} className={'checkbox'} type="checkbox" style={{display: 'initial'}}/>
													</div>
												)
											})}
										</div>
									)
								})}

							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

function mapStateToProps(state) {
	return {
		userPermissions: state.user.permissions
	}
}

export default connect(mapStateToProps, null)(Permissions);
