import React, {useEffect, useState} from 'react';
import TicketsService from '../../services/api/tickets';
import CustomStatusSelect from '../../components/CustomStatusSelect';
import DueDate from '../../components/DueDate';
import {NavLink} from 'react-router-dom';
import TaxonomiesService from '../../services/api/taxonomies';
import Avatar from '../../components/Avatar';

export default function SearchTickets({query}) {
	const [tickets, setTickets] = useState([]);
	const [statuses, setStatuses] = useState([]);
	useEffect(() => {
		TicketsService.searchTickets({search: query}).then((res) => {
			setTickets(res.tickets);
		});
		TaxonomiesService.get('statuses').then((res) => {
			let statuses = res.map(status => {
				return {
					value: status.id,
					label: status.name,
					order: status.order,
					bgColor: status.color
				};
			});
			setStatuses(statuses);
		});
	}, [query]);
	return <section className="page">
		<div className="wrapper">
			<div className="list-container">
				<div className="inner-container">
					<div className="morph-grid__container ctrl list-view">
						{tickets.length ?
							<>
								{tickets.map((ticket) => <div
										key={ticket.id}
										className="morph-grid__item morph-ticket-item"
										style={{alignItems: 'center'}}>
										<div
											className="morph-ticket-item__group alpha-beta"
											style={{
												width: 'auto',
												maxWidth: '50%'
											}}>
											<div
												className="morph-ticket-item__cell alpha">
												<div
													className="morph-ticket-item__status custom-bullet-status-select">
													<CustomStatusSelect
														options={statuses}
														value={{
															label: ticket.status.name,
															value: ticket.status.id,
															bgColor: ticket.status.color
														}}
													/>
												</div>
											</div>
											<div
												className="morph-ticket-item__cell beta">
												<NavLink
													to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}/tickets/${ticket.id}`}
													className="ticket-tiem__title"
													style={{textAlign: 'initial'}}>{ticket.title}</NavLink>
											</div>
										</div>
										<div
											className="morph-ticket-item__group gamma-delta-epsilon">
											<div
												className="morph-ticket-item__cell gamma">
												<NavLink
													to="/projects/call-card"
													className="morph-ticket-item__project">{}</NavLink>
											</div>
										</div>
										<div
											className="morph-ticket-item__group gamma-delta-epsilon">
											<div
												className="morph-ticket-item__cell gamma">
												<NavLink
													to={`/projects/${ticket?.project?.readable_id ?? this.props.project.readable_id}`}
													className="morph-ticket-item__project">{ticket?.project?.title ?? this.props.project.title}</NavLink>
											</div>
											<div
												className="morph-ticket-item__cell delta">
												<div
													className="morph-ticket-item__due-date">
													<div className="dropdown">
														<DueDate
															value={ticket.due_date && new Date(ticket.due_date)}
															minDate={new Date()}
														/>
													</div>
												</div>
											</div>
											<div
												className="morph-ticket-item__cell epsilon">
												<div
													className="morph-ticket-item__asignee">
													<div
														className="morph-ticket-item__asignee">
														<Avatar
															className={'custom-single-user-select__avatar'}
															width={30}
															height={30}
															altText={`${ticket.assigneed?.first_name} ${ticket.assigneed?.last_name}`}
															userId={ticket.assigneed?.id}
															hasAvatar={ticket.assigneed?.has_avatar}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</>
							:
							<h3>
								There are no tickets in this search criteria
							</h3>
						}
					</div>
				</div>
			</div>
		</div>
	</section>;
};
